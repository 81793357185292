import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth-service/auth.service';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';


@Component({
  selector: 'app-recover',
  templateUrl: './recover.component.html',
  styleUrls: ['./recover.component.scss']
})
export class RecoverComponent implements OnInit {
  email = '';
  error = '';
  message = '';
  constructor( private authService: AuthService, private router: Router ) { }

  ngOnInit() {
    if (this.authService.logIn) {
      this.router.navigate(['/']);
    }
  }

  recoverPass() {
    console.log( 'recovering password for ' + this.email );
    this.authService.recoverPw(this.email)
      .pipe(first())
      .subscribe(
        data => {
          this.message = "Demande reçue, un email a été envoyé. Cliquez sur le lien de l'e-mail pour réinitialiser votre mot de passe.";
        },
        err => {
          const error = err.error;
          const errorS = error.message;
          this.error = error['hydra:description'];
        }
      );
  }

}
