import { Component, OnInit, ViewChild , ElementRef, Inject} from '@angular/core';
import { AuthService } from 'src/app/services/auth-service/auth.service';
import { FormBuilder, FormGroup, Validators, FormControl, FormGroupDirective, NgForm} from '@angular/forms';
import { MessageService } from 'src/app/services/message/message.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  
  /*	@ViewChild('theForm') theForm: FormGroupDirective;
 	sendMessageForm: FormGroup;
	submitted = false;*/

	public lang:string = 'fr';
  sendMessageForm: FormGroup;
  sendFormPartenaire: FormGroup;
  homeContent = '';
  submitted = false;
  submittedPartenaire = false;
  indexPage = 0;
  @ViewChild('cursorLight') cursorLightElement: ElementRef;


  docsUrl = "";
  seletedMedia: any;
  currentIndex = 0;
  fileList: any;
  currentMedia: any;



  	constructor( private authService: AuthService,
    private formBuilder: FormBuilder,  private messageService: MessageService) {

  		/*this.sendMessageForm = this.formBuilder.group({
		  givenName: ['', Validators.required],
		  lastName: ['', Validators.required],
		  email: ['', Validators.required],
		  messageToSend: ['', [Validators.required, Validators.minLength(1)]],
		});*/

	this.sendMessageForm = this.formBuilder.group({
        Nom: ['', Validators.required],
        Prenom: ['', Validators.required],
        Mail: ['', Validators.required],
        NomEntreprise: ['', Validators.required],
        telephone: [''],
        Fonction: [''],
        objet: ['', Validators.required],
        Message: ['']
      });

      this.sendFormPartenaire = this.formBuilder.group({
        Nom: ['', Validators.required],
        Prenom: ['', Validators.required],
        Mail: ['', Validators.required],
        NomEntreprise: ['', Validators.required],
        telephone: [''],
        Fonction: [''],
        objet: ['', Validators.required],
        Message: ['']
      });

   	}

  	ngOnInit() {

  	this.messageService.getTranslate()
	   .subscribe(
	     (data) => {
	       this.lang= data;
	     }
	   )

  	}	
  	

  get f() { return this.sendMessageForm.controls; }

  get fbis() { return this.sendFormPartenaire.controls; }

  onSubmitMessage(){

    //console.log('dans le submitted');

    this.submitted = true;
     
    // stop here if form is invalid
    if (this.sendMessageForm.invalid) {
      return;
    }

    this.authService.contactMessage(
        this.sendMessageForm.value.Prenom,
        this.sendMessageForm.value.Nom,
        this.sendMessageForm.value.Mail,
        this.sendMessageForm.value.NomEntreprise,
        this.sendMessageForm.value.Fonction,
        this.sendMessageForm.value.Message,
        this.sendMessageForm.value.telephone,
        this.sendMessageForm.value.objet
      )
      .subscribe(res => {
      //console.log('reponse envoi message: ',res);
      this.sendMessageForm.reset();
      this.submitted = false;  
      alert("Message envoyé");
    },
    error => {
      alert("oups une erreur s'est produite!");
      this.submitted = false;  
    });

  }

  onSubmitMessagePartenaire(){

    //console.log('dans le submitted');

    this.submittedPartenaire = true;
     
    // stop here if form is invalid
    if (this.sendFormPartenaire.invalid) {
      return;
    }

    this.authService.contactMessagePartenaire(
        this.sendFormPartenaire.value.Prenom,
        this.sendFormPartenaire.value.Nom,
        this.sendFormPartenaire.value.Mail,
        this.sendFormPartenaire.value.NomEntreprise,
        this.sendFormPartenaire.value.Fonction,
        this.sendFormPartenaire.value.Message,
        this.sendFormPartenaire.value.telephone,
        this.sendFormPartenaire.value.objet
      )
      .subscribe(res => {
      //console.log('reponse envoi message: ',res);
      this.sendFormPartenaire.reset();
      this.submittedPartenaire = false;  
      alert("Message envoyé");
    },
    error => {
      alert("oups une erreur s'est produite!");
      this.submittedPartenaire = false;  
    });

  }
  
  	/*onSubmitForm(customerData) {

	     // Process checkout data here
	    // console.log(JSON.stringify(this.files));
	    
	    console.log('Your order has been submitted', customerData);

	    this.submitted = true;
	     // stop here if form is invalid
	    if (this.sendMessageForm.invalid) {
	      return;
	    }
	    
	    //this.sendQuestion(customerData);

	    this.authService.sendQuestionFormContact(
	      customerData.givenName,
	      customerData.lastName,
	      customerData.email,
	      customerData.messageToSend,
	    )
	    .subscribe(res => {
	       //console.log('reponse envoi message: ',res);
			alert("Message envoyé");
	    },
 		error => {
	       alert("oups une erreur s'est produite!");
	    });

    	this.submitted = false;
    	this.theForm.resetForm();
  	  	 
  	}	

  	// convenience getter for easy access to form fields
 	get f() { return this.sendMessageForm.controls; }*/

}
