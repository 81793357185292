import { Component, OnInit, Inject, ViewChild, AfterViewInit, OnDestroy, HostListener} from '@angular/core';
import { AuthService } from 'src/app/services/auth-service/auth.service';
import { MessageService } from 'src/app/services/message/message.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig } from '@angular/material';
import {MatPaginator} from '@angular/material/paginator';
import {MatButton} from '@angular/material/button';
import {merge, Subject, Observable, of as observableOf, Subscription} from 'rxjs';
import {catchError, map, startWith, switchMap, takeUntil} from 'rxjs/operators';
import {MatTableDataSource} from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../environments/environment';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { Location } from "@angular/common";
import { CoreServiceService } from 'src/app/services/core/core-service.service';
import * as introJs from 'intro.js';

export interface ContactDialogData {
  name: string;
  profile: string;
  commonRef: string;
}

export interface DialogDataNotif {
  copyNotifCount: number;
  receiveNotifCount: number;
  sentNotifCount: number;
  answerNotifCount: number;
  idprojet: string;
  name: string;
}

export interface Contacts {
  '@id': string;
  '@type': string;
  familyName: string;
  fullName: string;
  gender: string;
  givenName: string;
  id: string;
  image: string;
  imageUrl: string;
  jobTitle: string;
  position: number;
}

@Component({
  selector: 'app-dashboard-menu',
  templateUrl: './dashboard-menu.component.html',
  styleUrls: ['./dashboard-menu.component.scss']
})
export class DashboardMenuComponent implements OnInit, OnDestroy {
  name: string;
  profile: string;
  commonRef: string;
  currentUser: any;
  currentUserName: string;
  companyName: string;
  subscription: Subscription;
  searchContactIRP = null;

  //notificationload = [];
  notification = [];
  arrayTempParity: any;
  maleNb: any;
  femaleNb: any;
  contactsTemp: Contacts[];
  contacts: Contacts[];
  inviteBadge = 0;
  notifBadge = 0;
  sentNotifCount    = 0;
  receiveNotifCount = 0;
  copyNotifCount    = 0;
  answerNotifCount  = 0;
  userEmail = '';
  dashbordbtnBackground = 'white';
  nbToolNotif = 0;
  showTool = false;
  route: string;
  profilImg: any;
   /* ------ VARIABLE PARTIE GUIDE------ */
   // introJS = introJs();
   protected _onDestroy = new Subject<void>();

  constructor(
    private translate: TranslateService,
    public authService: AuthService,
    public dialog: MatDialog,
    private router: Router,
    location: Location,
    private messageService: MessageService,
    private coreService: CoreServiceService) {


    this.router.events.subscribe(val => {
      if (location.path() != "") {
        this.route = location.path();
      } else {
        this.route = "Home";
      }
      if (this.route == '/dashboard') {
        this.dashbordbtnBackground = '#1c7f7d';
      }
      else
      {
        this.dashbordbtnBackground = 'white';
      }
    });


    this.messageService.getShowProjectTutoIsFalse().subscribe(() => {
        if (this.messageService.getShowCreateProjectTuto() == false) {
          this.showTool = this.messageService.getShowNotifTuto();
        }
    });

  }

  startDemo() {
    this.coreService.startDemo.emit();
  }


  guide(){
    this.messageService.setGuide(1);
  }

  inFactory(){
    alert('En-cours');
  }

  openDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.panelClass = 'contactpanel';
    dialogConfig.id = 'balchal717';
    dialogConfig.minWidth = '500px';
    dialogConfig.autoFocus = false;
    dialogConfig.position = { top: '50px' };
    dialogConfig.data = { name: this.name, profile: this.profile, commonRef: this.commonRef };
    const dialogRef = this.dialog.open(DashboardDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((result: any) => {
      if(result){
        this.profile = result;
        this.router.navigate(['/profile/'+result]);
      }
    });

  }


  openInfo(){
    const dialogRef = this.dialog.open(tutoStepdialog, {
      minWidth: '850px',
      position:{ top: '50px' },
      data:{}
    });
    dialogRef.afterClosed().subscribe(result => {
    })
  }

  getAvatarlUrl(avatarLink){
    return environment.server_url+avatarLink;
  }

  ngOnInit() {

    this.maleNb = 0;
    this.femaleNb = 0;

    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.userEmail = this.currentUser['email'];

    if (this.currentUser.imageUrl) {
        this.profilImg =  this.getAvatarlUrl(this.currentUser.imageUrl)
    }
    else
    {
      if ( this.currentUser.gender == 'male') {
        this.profilImg = '../../../assets/img/Homme_vert.png';
      }
      else
      {
        this.profilImg =  '../../../assets/img/Homme_vert.png';
      }
    }

    //console.log('this.userEmail => '+ this.userEmail);

    this.currentUserName = this.currentUser['givenName'];
    this.companyName = this.currentUser['companyName'];
    this.authService.obNotifBadge.subscribe((res) =>{
      this.notification = <any>res;
      this.notifBadge = 0;
      for (var i = 0; i < this.notification.length; i++) {
        //console.log('reoutr get notif => '+ JSON.stringify(this.notification));
       this.notifBadge += this.notification[i].total;
      }
    });
    this.authService.obInviteBadge.subscribe((res) =>{
      this.inviteBadge = res['hydra:totalItems'];
    });
    this.getNotification();
    this.updateNotifications();

    this.subscription = this.messageService.getMessage().subscribe(() => {
      this.updateNotifications();
    });


    this.messageService.obsreloadUserInfo().subscribe(() => {
       this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this.currentUserName = this.currentUser['givenName'];
    });

    this.authService.companyGender().subscribe((res) =>{
      //console.log('parité homme femme =>'+JSON.stringify(res));
      this.arrayTempParity = res;
      var nbHomme = 0;
      var nbfemme = 0;
       for (var i = 0; i < this.arrayTempParity.length; i++) {
          if (this.arrayTempParity[i].gender && this.arrayTempParity[i].gender == 'male'){
            nbHomme = this.arrayTempParity[i].total;
          }
          else if (this.arrayTempParity[i].gender && this.arrayTempParity[i].gender == 'female'){
            nbfemme = this.arrayTempParity[i].total;
          }
       }

       var totalParite = nbHomme+nbfemme;
       this.maleNb = Math.round(((nbHomme/totalParite)*100));
       this.femaleNb = Math.round(((nbfemme/totalParite)*100));

    });


  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  useLanguage(language: string) {
    this.translate.use(language);
  }

  updateNotifications(){
    this.authService.getProjectNotification(0, 1, 10).subscribe((res) =>{
      this.sentNotifCount = res['hydra:totalItems'];
    });

    this.authService.getProjectNotification(1, 1, 10).subscribe((res) =>{
      this.receiveNotifCount = res['hydra:totalItems'];
    });

    this.authService.getProjectNotification(2, 1, 10).subscribe((res) =>{
      this.copyNotifCount = res['hydra:totalItems'];
    });

    this.authService.getProjectNotification(3, 1, 10).subscribe((res) =>{
      this.answerNotifCount = res['hydra:totalItems'];
    });

  }


  getNotification() {
    this.authService.getNotification().subscribe(res => {
      this.authService.obNotifBadge.next(res);
    });
    this.authService.getUserInvitation(0, 10).subscribe(res => {
      this.authService.obInviteBadge.next(res);
    });
  }

  openDialogNotif(): void {

    if(this.showTool == true) {
      this.showTool = false;
      this.messageService.setShowNotifTuto(false);
    }

    /*const dialogRef = this.dialog.open(DashboardDialogNotif, {
      width: '1200px',
      data: {sentNotifCount:this.sentNotifCount, receiveNotifCount:this.receiveNotifCount, copyNotifCount:this.copyNotifCount, answerNotifCount:this.answerNotifCount}
    });*/

    const dialogRef = this.dialog.open(DashboardDialogNotif, {
      minWidth: '1200px',
      position:{ top: '50px' },
      data: {sentNotifCount:this.sentNotifCount, receiveNotifCount:this.receiveNotifCount, copyNotifCount:this.copyNotifCount, answerNotifCount:this.answerNotifCount}
    });

    dialogRef.afterClosed().subscribe(result => {
      //console.log('The dialog was closed notif '+result);
      if(result)
      {
        if (result.type == 'COMMENT')
        {
          this.router.navigate(['/project/'+result.id+'/'+result.number+'/'+result.type]);
        }
        else
        {
          this.router.navigate(['/project/'+result.id+'/'+result.consult+'/'+result.type]);
        }
      }
      //this.animal = result;
    });
  }

  private newMethod() {
    return '0';
  }


  logout() {
    //console.log('logging out');
    this.authService.logout();
  }

  /*getNotification() {
    this.authService.getNotification()
      .subscribe(res => {
        this.notificationload = res;
      });

    this.notificationload.forEach(dataN => {
      this.authService.getTheProject(dataN.id)
        .subscribe(response => {
          dataN.name = response['libelle'];
          this.notification.push(dataN);
        });
    });
  }*/

  showInvitation() {
    const dialogRef = this.dialog.open(InvitationDialogComponent, {
      minWidth: '650px',
      position:{ top: '150px' },
      data: {},
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(result => {
      //console.log('The dialog was closed');
      if(result)
      {
        //console.log('/single/'+result.project.id);
        this.router.navigate(['/project/'+result.project.id]);
        this.authService.obRefreshProjet.next(true);
        this.authService.getUserInvitation(0, 10).subscribe(res => {
          this.authService.obInviteBadge.next(res);
        });
        this.messageService.messageUpdated();
      }

    });
  }

}


/////////////////// contact view dialog ///////////////////

@Component({
  selector: 'app-dashboard-dialog',
  templateUrl: 'dashboard-dialog.html',
  styleUrls: ['dashboard-dialog.scss']
})
export class DashboardDialogComponent {

  contacts: Array<Contacts> = [];
  searchContact: any;
  currentUser: any;
  searchContactIRP = null;


  constructor(
    public dialogRef: MatDialogRef<ContactDialogData>,
    @Inject(MAT_DIALOG_DATA) public data: ContactDialogData,
    private authService: AuthService,
    public dialog: MatDialog,
    private router: Router
    ) {
      this.loadContact();
      this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    }


  loadContact(url?: string){
    url = url ? url : undefined;
    this.authService.getContacts(url).subscribe(res => {
      this.contacts = this.contacts.concat(res['hydra:member']);
      //this.contacts = res['hydra:member'];
      for (var i = 0; i < this.contacts.length; i++) {
        this.contacts[i]['EditMode'] = false;
      }

      if(res['hydra:view']['hydra:next']){
        this.loadContact(res['hydra:view']['hydra:next'].replace("/api", ""));
      }
    });
  }

  onClickEditMode(contact){
    if (contact.EditMode === true) {
      contact.EditMode = false;
    }
    else{
      contact.EditMode = true;
    }
  }

  changeType(contact, type)
  {
    var skillArray = contact.skills;
    if(skillArray.indexOf(type) != -1)
    {
      var index = skillArray.indexOf(type);
      skillArray.splice(index, 1);
      contact.skills = skillArray;
    }
    else
    {
      skillArray.push(type);
      contact.skills = skillArray;
    }
  }

  validate(contact){
    console.log('skill du contact => '+contact.skills);
    this.authService.udapteContactSkill(contact.id, contact.skills)
    .subscribe( data => {
      contact.EditMode = false;
    }, (error) => {
      alert('une erreur est survenue');
    });
  }

  getStatus(key){
    switch (key) {
      case 'HRD':
        return "DRH";
        break;
      case 'HR':
        return "RH";
        break;
      case 'EMPLOYEE':
        return "Salarié";
        break;
      default:
        return key;
        break;
    }
  }


  goToProfile(profilId){
     this.dialogRef.close(profilId);
  }

  goToCommonGroupe(profilId){

    const commondialogRef = this.dialog.open(commonGroupeComponent, {
      minWidth: '500px',
      position:{ top: '50px' },
      data: {id:profilId}
    });

    commondialogRef.afterClosed().subscribe(result => {
      //console.log('The dialog was closed');
      if (result) {
        this.onNoClick();
        this.authService.obCommonProjectChosen.next(result);
        this.router.navigate(['/project/'+result]);
      }

    });

  }

  getAvatarlUrl(avatarLink){
    return environment.server_url+avatarLink;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}


/////////////////// common groupe view dialog ///////////////////

export interface Project {
  '@id': string;
  '@type': string;
  imageUrl: string;
  libelle: string;
  id: string;
  dateCreated: string;
  lastmsgextrait: string;
}

@Component({
  selector: 'app-commongroupe-dialog',
  templateUrl: 'commongroupe-dialog.html',
  styleUrls: ['commongroupe-dialog.scss']
})
export class commonGroupeComponent implements AfterViewInit{

  perPage = 10;
  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  projectArray: Project[];
  displayedColumns: string[] = ['Project'];
  dataSource: any;
  searchContact: any;

  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private authService: AuthService,
    private router: Router
    ) {

  }

  ngAfterViewInit() {

    // If the user changes the sort order, reset back to the first page.
    //this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);

    merge(this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          return this.authService.getCommonProjects(this.paginator.pageIndex, this.perPage, this.data.id);
        }),
        map(data => {
          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
          this.isRateLimitReached = false;
          this.resultsLength = data['hydra:totalItems'];;
          return data['hydra:member'];;
        }),
        catchError(() => {
          this.isLoadingResults = false;
          // Catch if the GitHub API has reached its rate limit. Return empty data.
          this.isRateLimitReached = true;
          return observableOf([]);
        })
      ).subscribe(data =>{
        this.projectArray = data;
        this.dataSource = new MatTableDataSource(this.projectArray);
      });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  fctprojectChosen(projectId): void {
    this.dialogRef.close(projectId);
  }

}

/////////////////// notification view dialog ///////////////////

@Component({
  selector: 'dashboard-dialogNotif',
  templateUrl: 'dashboard-dialogNotif.html',
  styleUrls: ['dashboard-dialogNotif.scss']
})
export class DashboardDialogNotif implements OnInit{

  /*displayedColumns: string[] = ['receptiondate','sender','receiver','project', 'suject',
    'Statut','consult'];*/

  displayedColumns: string[] = ['receptiondate','sender','receiver', 'project', 'suject', 'type', 'statut','consult'];
  displayedColumnsbis: string[] = ['receptiondate','sender','receiver', 'project', 'suject', 'type','statut','consult'];

  dataSource : any[];
  currentUser : any;
  resultsLength = 0;
  perPage = 30;
  notifMode = 0;
  isLoadingResults = true;
  isRateLimitReached = false;
  projectArray: any[];
  lastDate = new Date('01/01/4970');
  // sendbtnColor = 'white';
  // sendbtnBackground = '#1c7f7d'
  // receivebtnColor = '#1c7f7d';
  // receivebtnBackground = 'white'
  // copybtnColor = '#1c7f7d';
  // copybtnBackground = 'white'
  // answerbtnColor = '#1c7f7d';
  // answerbtnBackground = 'white'
  sentNotifCount    = 0;
  receiveNotifCount = 0;
  copyNotifCount    = 0;
  answerNotifCount  = 0;

  @ViewChild(MatPaginator) paginator: MatPaginator;



 public sendObs = new Subject();
 public sendObs$ = this.sendObs.asObservable();

 public receiveObs = new Subject();
 public receiveObs$ = this.receiveObs.asObservable();

 public copyObs = new Subject();
 public copyObs$ = this.copyObs.asObservable();

 public answerObs = new Subject();
 public answerObs$ = this.answerObs.asObservable();
 //public innerWidth: any;

  constructor(private authService: AuthService,
    public dialogRef: MatDialogRef<DashboardDialogNotif>,
    @Inject(MAT_DIALOG_DATA) public data: DialogDataNotif) {

      this.sentNotifCount    = data.sentNotifCount;
      this.receiveNotifCount = data.receiveNotifCount;
      this.copyNotifCount    = data.copyNotifCount;
      this.answerNotifCount  = data.answerNotifCount;
      this.dataSource = [];

  }

  /*@HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
    console.log('écran resize => '+this.innerWidth);
  }*/

  ngOnInit() {

   // this.innerWidth = window.innerWidth;

    if (localStorage.getItem('notifMode')) {
      this.notifMode = parseInt(localStorage.getItem('notifMode'), 10);
    }

    this.dataSource = [];
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));

    // If the user changes mode, reset back to the first page.
    this.sendObs.subscribe((btn) =>{
      this.paginator.pageIndex = 0;
      this.notifMode = 0;//notif send
      this.getNotification();
      this.updateNotifications();
    });

    this.receiveObs.subscribe((btn) =>{
      this.paginator.pageIndex = 0;
      this.notifMode = 1;//notif receive
      this.getNotification();
      this.updateNotifications();
    });

    this.copyObs.subscribe((btn) =>{
      this.paginator.pageIndex = 0;
      this.notifMode = 2;//notif copy
      this.getNotification();
      this.updateNotifications();
    });

    this.answerObs.subscribe((btn) =>{
      this.paginator.pageIndex = 0;
      this.notifMode = 3;//notif copy
      this.getNotification();
      this.updateNotifications();
    });

    merge(this.sendObs, this.receiveObs, this.copyObs, this.answerObs, this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          localStorage.setItem('notifMode', this.notifMode.toString());
          /*if (this.notifMode == 0) {
            this.sendbtnColor = 'white';
            this.sendbtnBackground = '#1c7f7d';
            this.receivebtnColor = '#1c7f7d';
            this.receivebtnBackground = 'white';
            this.copybtnColor = '#1c7f7d';
            this.copybtnBackground = 'white';
            this.answerbtnColor = '#1c7f7d';
            this.answerbtnBackground = 'white';
          }
          else if (this.notifMode == 1) {
            this.sendbtnColor = '#1c7f7d';
            this.sendbtnBackground = 'white';
            this.receivebtnColor = 'white';
            this.receivebtnBackground = '#1c7f7d';
            this.copybtnColor = '#1c7f7d';
            this.copybtnBackground = 'white';
            this.answerbtnColor = '#1c7f7d';
            this.answerbtnBackground = 'white';
          }
          else if (this.notifMode == 2)
          {
            this.sendbtnColor = '#1c7f7d';
            this.sendbtnBackground = 'white';
            this.receivebtnColor = '#1c7f7d';
            this.receivebtnBackground = 'white';
            this.copybtnColor = 'white';
            this.copybtnBackground = '#1c7f7d';
            this.answerbtnColor = '#1c7f7d';
            this.answerbtnBackground = 'white';
          }
          else if (this.notifMode == 3)
          {
            this.sendbtnColor = '#1c7f7d';
            this.sendbtnBackground = 'white';
            this.receivebtnColor = '#1c7f7d';
            this.receivebtnBackground = 'white';
            this.copybtnColor = '#1c7f7d';
            this.copybtnBackground = 'white';
            this.answerbtnColor = 'white';
            this.answerbtnBackground = '#1c7f7d';
          }*/
          return this.authService.getProjectNotification(this.notifMode, this.paginator.pageIndex, this.perPage);
        }),
        map(data => {

          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
          this.isRateLimitReached = false;
          this.resultsLength = data['hydra:totalItems'];

          return data['hydra:member'];

        }),
        catchError(() => {
          this.isLoadingResults = false;
          // Catch if the  API has reached its rate limit. Return empty data.
          this.isRateLimitReached = true;
          return observableOf([]);
        })
      ).subscribe(data => {
          this.projectArray = [];
          this.lastDate = new Date('01/01/4970');
          for (let index = 0; index < data.length; index++) {
              let currentProjet = data[index];
              this.projectArray.push(this.dataParse(currentProjet));
          };

          let afterIsNewDay = false;
          this.projectArray.reverse().forEach((element, index) => {
            if(afterIsNewDay || index == 0){
              this.projectArray[index]["afterIsNewDay"] = true;
              afterIsNewDay = false;
              //console.log("afterIsNewDay")
            }
            if(element.isNewDay == true){
              afterIsNewDay = true;
            }
          });

          this.dataSource = this.projectArray.reverse();
      });

  }


  dataParse(data: any) {

    var strId = data['project']['@id'];
    var splittedID = strId.split("/");
    var idProject = splittedID[splittedID.length-1];
    var listTag : any[] = data['tags'];
    var date = data['dateCreated'];
    var isNewDay = this.isNewDay(date);

    var typeMessage = 'QUESTION';

    if (data['type'] == 'QUESTION') {
      typeMessage = 'Question';
    }
    else if (data['type'] == 'COMMENT'){
      typeMessage = 'Commentaire';
    }
    else if (data['type'] == 'ANSWER'){
      typeMessage = 'Réponse';
    }

    const rowData: any = {
      'id': idProject,
      'receptiondate': data['dateModified'],
      'deadline': (data['deadline'] ? data['deadline']  : ''),
      'type': data['type'],
      'typeParse': (data['isMessageGlobal'] === true ? 'Info' : typeMessage),
      'project': data['project']['libelle'],
      'suject': (listTag[0] ? listTag[0].libelle  : '' ),
      'sender':   data['author']['fullName'],
      'senderPic': data['author'] ? data['author']['imageUrl'] : null,
      'receiver': (data['recipient'] ? data['recipient']['fullName']: null),
      'receiverId': (data['recipient'] ? data['recipient']['id']: null),
      'receiverPic': (data['recipient'] ? data['recipient']['imageUrl']: null),
      'consult': data['smartNumber'],
      'number': data['number'],
      'filegeneric': data['filegeneric'],
      'isMessageAnswered': (data['isMessageAnswered'] === true ? 'Répondu' : 'Non répondu'),
      'isNewDay': isNewDay,
      'isMessageGlobal': data['isMessageGlobal']
    };
    return rowData;

  }

  getAvatarlUrl(avatarLink){
    return  environment.server_url+avatarLink;
  }

  onNoClick(): void {
    //console.log('data dialog close');
    this.dialogRef.close();
  }

  isNewDay(messageDate){
    let currentDate = new Date(messageDate.substring(0,10));
    //console.log('this.lastDate ==> '+this.lastDate);
    //console.log('currentDate ==> '+currentDate);
    if(currentDate < this.lastDate)
    {
      //console.log('Une section ==> '+ currentDate);
      this.lastDate = currentDate;
      return true;
    }
    else
    {
      this.lastDate = currentDate;
      return false;
    }
  }

  isRoundFirst(afterIsNewDay, isNewDay){
    if(afterIsNewDay && isNewDay){
      return "divcontaine afterIsNewDayBL afterIsNewDayTL";
    }else if(afterIsNewDay){
      return "divcontaine afterIsNewDayBL";
    }else if(isNewDay){
      return "divcontaine afterIsNewDayTL";
    }
    return "divcontaine";
  }

  isRoundLast(afterIsNewDay, isNewDay){
    if(afterIsNewDay && isNewDay){
      return "divcontaine afterIsNewDayBR afterIsNewDayTR";
    }else if(afterIsNewDay){
      return "divcontaine afterIsNewDayBR";
    }else if(isNewDay){
      return "divcontaine afterIsNewDayTR";
    }
    return "divcontaine";
  }

  updateNotifications(){
    this.authService.getProjectNotification(0, 1, 10).subscribe((res) =>{
      this.sentNotifCount = res['hydra:totalItems'];
    });

    this.authService.getProjectNotification(1, 1, 10).subscribe((res) =>{
      this.receiveNotifCount = res['hydra:totalItems'];
    });

    this.authService.getProjectNotification(2, 1, 10).subscribe((res) =>{
      this.copyNotifCount = res['hydra:totalItems'];
    });

    this.authService.getProjectNotification(3, 1, 10).subscribe((res) =>{
      this.answerNotifCount = res['hydra:totalItems'];
    });

  }


  getNotification() {
    this.authService.getNotification().subscribe(res => {
      this.authService.obNotifBadge.next(res);
    });
    this.authService.getUserInvitation(0, 10).subscribe(res => {
      this.authService.obInviteBadge.next(res);
    });
  }

  deadLineDiffWithToday(deadline) {
    var today = new Date();
    var deadlineData = new Date(deadline);
    var diff = this.dateDiff(today, deadlineData);
    return  diff.day;
    //console.log('pour deadline de '+deadlineData+' il ya une diff de ==>'+ this.dateDiff(today, deadlineData).day);
  }

   dateDiff(date1, date2){
    var diff = {
      sec : 0,
      min : 0,
      hour : 0,
      day : 0
    }; // Initialisation du retour


    var tmp = date2 - date1;

    tmp = Math.floor(tmp/1000);             // Nombre de secondes entre les 2 dates
    diff.sec = tmp % 60;                    // Extraction du nombre de secondes

    tmp = Math.floor((tmp-diff.sec)/60);    // Nombre de minutes (partie entière)
    diff.min = tmp % 60;                    // Extraction du nombre de minutes

    tmp = Math.floor((tmp-diff.min)/60);    // Nombre d'heures (entières)
    diff.hour = tmp % 24;                   // Extraction du nombre d'heures

    tmp = Math.floor((tmp-diff.hour)/24);   // Nombre de jours restants
    diff.day = tmp;//alert("Entre le "+date1.toString()+" et "+date2.toString()+" il y a "+diff.day+" jours, "+diff.hour+" heures, "+diff.min+" minutes et "+diff.sec+" secondes");

    return  diff;
  }



}

/////////////////// View invitation ///////////////////

export interface invitationElement {
  projectName: string;
  givenName: string;
  familyName: string;
  projectImage: string;
  userhote: string;
  dateCreated: string;
  idProject: string;
  modeSupp: boolean;
}

@Component({
  selector: 'invitation-dialog',
  templateUrl: 'invitation-dialog.html',
  styleUrls: ['invitation-dialog.scss']
})
export class InvitationDialogComponent implements AfterViewInit {

  invitationList = null;
  isLoadingResults = true;
  isRateLimitReached = false;
  resultsLength = 0;
  perPage = 10;
  modeSupp = false;
  private refreshObs = new Subject();
  public refreshObs$ = this.refreshObs.asObservable();

  ELEMENT_DATA: invitationElement[] = [];

  displayedColumns: string[] = ['projectImage', 'projectName', 'idProject'];

  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(public dialogRef: MatDialogRef<InvitationDialogComponent>,
  @Inject(MAT_DIALOG_DATA) public dataParam: any,
  private authService:  AuthService
  ) {

    /*this.authService.getUserInvitation().subscribe( invitationData => {
        //console.log('retour liste invitation '+invitationData);
        this.invitationList = invitationData['hydra:member'];
    });*/

  }

  ngAfterViewInit() {

      merge(this.paginator.page, this.refreshObs)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          return this.authService.getUserInvitation(this.paginator.pageIndex, this.perPage);
        }),
        map(data => {
          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
          this.isRateLimitReached = false;
          this.resultsLength = data['hydra:totalItems'];
          return data['hydra:member'];
        }),
        catchError(() => {
          this.isLoadingResults = false;
          // Catch if the GitHub API has reached its rate limit. Return empty data.
          this.isRateLimitReached = true;
          return observableOf([]);
        })
      ).subscribe(data =>{
        //var ELEMENT_DATA: invitationElement[] = [];
        this.ELEMENT_DATA = [];
        for (var i = 0; i < data.length; i++) {
          this.ELEMENT_DATA.push(this.parseData(data[i]));
        }
        this.invitationList = new MatTableDataSource(this.ELEMENT_DATA);
      });

  }

  parseData(itemData){
    var item : invitationElement = {
      projectName:itemData.project.libelle,
      projectImage: itemData.userhote.imageUrl,
      userhote: itemData.userhote.fullName,
      givenName: itemData.userhote.givenName,
      familyName: itemData.userhote.familyName,
      dateCreated: itemData.createdAt,
      idProject: itemData.id,
      modeSupp: false
    };
    return item;
  }

  applyFilter(filterValue: string) {
    this.invitationList.filter = filterValue.trim().toLowerCase();
  }


  getAvatarlUrl(avatarLink){
    return  environment.server_url+avatarLink;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onClickAccepter(invitationID){
    this.authService.accepterProjet(invitationID).subscribe( dataAccepte => {
      //console.log('retour projet accepte '+JSON.stringify(dataAccepte));
      this.dialogRef.close(dataAccepte);
    }, (error) => {
        console.log('Uh-oh, an error occurred! : ' +JSON.stringify(error));
        alert('une erreur est survenue');
    });
  }

  accepteProjet(invitationID){
    this.authService.accepterProjet(invitationID).subscribe( dataAccepte => {
      //console.log('retour projet accepte '+JSON.stringify(dataAccepte));
      this.authService.getUserInvitation(0, 10).subscribe(res => {
        this.authService.obInviteBadge.next(res);
      });
    }, (error) => {
      alert('une erreur est survenue');
    });
  }


  onClickRefuser(invitationID){
    this.authService.declineProjet(invitationID).subscribe( dataDecline => {
      this.refreshObs.next();
      this.authService.getUserInvitation(0, 10).subscribe(res => {
        this.authService.obInviteBadge.next(res);
      });
    },(error) => {
        //console.log('Uh-oh, an error occurred! : ' + JSON.stringify(error));
        alert('une erreur est survenue');
    });
  }

  accepteAll(){
    //console.log(' accepteAll ==> '+this.ELEMENT_DATA.length);
    for (var i = 0; i < this.ELEMENT_DATA.length; i++)
    {
      if (i == (this.ELEMENT_DATA.length-1))
      {
        this.onClickAccepter(this.ELEMENT_DATA[i].idProject);
      }
      else
      {
        this.accepteProjet(this.ELEMENT_DATA[i].idProject);
      }
    }
  }


}



@Component({
  selector: 'tutoStepdialog',
  templateUrl: 'tutoStepdialog.html',
  styleUrls: ['tutoStepdialog.scss']
})
export class tutoStepdialog implements OnInit{
  lang='fr';

  constructor(
    public dialogRef: MatDialogRef<tutoStepdialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private authService: AuthService,
    private messageService: MessageService,
    private _formBuilder: FormBuilder) {
  }

  ngOnInit() {
    this.messageService.getTranslate().subscribe((data) => {
      this.lang= data;
    });
  }



  onNoClick(): void {
    this.dialogRef.close();
  }
}





