import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RegisterComponent } from '../../auth/register/register.component';
import { LoginComponent } from '../../auth/login/login.component';
import { RecoverComponent } from '../../auth/recover/recover.component';
import { SetnewComponent } from '../../auth/setnew/setnew.component';
import { PrivacyComponent } from '../../pages/privacy/privacy.component';
import { TermsOfServiceComponent } from '../../pages/terms-of-service/terms-of-service.component';
import { ContactComponent } from '../../pages/contact/contact.component';
import { LegalComponent } from '../../pages/legal/legal.component';
import { HomeComponent } from '../../pages/home/home.component';
import { AuthGuard } from '../../auth/guards/auth.guard';
import { ProfileComponent } from '../../pages/profile/profile.component';
import { StatistiqueComponent } from 'src/app/statistique/statistique.component';
import { DiscoverviewComponent } from 'src/app/discoverview/discoverview.component';
import { ConfirmComponent } from 'src/app/auth/confirm/confirm.component';
import { RenewConfirmationComponent } from 'src/app/auth/renew-confirmation/renew-confirmation.component';
import { DashboardComponent } from 'src/app/project/dashboard/dashboard/dashboard.component';
import { SingleProjectComponent } from 'src/app/project/dashboard/single-project/single-project.component';
import { ActivateAccountcmpComponent } from 'src/app/activate-accountcmp/activate-accountcmp.component';
import { FaqComponent } from 'src/app/faq/faq.component';
import { GeneralSettingComponent } from 'src/app/general-setting/general-setting.component';
import { SecurityComponent } from 'src/app/pages/security/security.component';
import { InscriptionDRHComponent } from 'src/app/inscription-drh/inscription-drh.component';
import { EmployeeJournalComponent } from 'src/app/employee-journal/employee-journal.component';
import { MenuGuideComponent } from 'src/app/menu-guide/menu-guide.component';
import { UnionNewspaperComponent } from 'src/app/union-newspaper/union-newspaper.component';
import { SubjectsConfigComponent } from 'src/app/subjects-config/subjects-config.component';
import { TutoViewComponent } from 'src/app/tuto-view/tuto-view.component';


const routes: Routes = [
  { path: '', component: HomeComponent },
  /*{ path: 'home/:fromEmail', component: HomeComponent },*/
  { path: 'home/:fromEmail', component: RegisterComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'acitivateAccount/:token', component: ActivateAccountcmpComponent },
  { path: 'login', component: LoginComponent },
  { path: 'tutoview', component: TutoViewComponent },
  { path: 'EmployeeJournal', component: EmployeeJournalComponent },
  { path: 'DrhComponent', component: InscriptionDRHComponent },
  { path: 'discoverview', component: DiscoverviewComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'legal', component: LegalComponent },
  { path: 'faq', component: FaqComponent },
  { path: 'guide', component: MenuGuideComponent },
  { path: 'privacy', component: PrivacyComponent },
  { path: 'recover', component: RecoverComponent },
  { path: 'subjectsConfig', component: SubjectsConfigComponent },
  { path: 'reset/token/:token', component: SetnewComponent },
  { path: 'terms-of-service', component: TermsOfServiceComponent },
  { path: 'security', component: SecurityComponent },
  { path: 'profile', component: ProfileComponent, canActivate: [AuthGuard] },
  { path: 'profile/:ID', component: ProfileComponent, canActivate: [AuthGuard] },
  { path: 'confirm/:token', component: ConfirmComponent },
  { path: 'unionNewspaper', component: UnionNewspaperComponent },
  { path: 'reconfirm', component: RenewConfirmationComponent },
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'statistique', component: StatistiqueComponent, canActivate: [AuthGuard] },
  { path: 'project/:ID', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'project/:ID/:specMsg/:type', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'generalSetting', component: GeneralSettingComponent, canActivate: [AuthGuard] }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

export const routingComponents = [
  HomeComponent,
  RegisterComponent,
  LoginComponent,
  ContactComponent,
  LegalComponent,
  PrivacyComponent,
  RecoverComponent,
  SetnewComponent,
  TermsOfServiceComponent,
  ProfileComponent,
  StatistiqueComponent,
  ConfirmComponent,
  RenewConfirmationComponent,
  DashboardComponent,
  SingleProjectComponent,
  ActivateAccountcmpComponent,
  DiscoverviewComponent,
  FaqComponent,
  EmployeeJournalComponent,
  GeneralSettingComponent,
  MenuGuideComponent,
  UnionNewspaperComponent,
  SubjectsConfigComponent
];
