import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-discoverview',
  templateUrl: './discoverview.component.html',
  styleUrls: ['./discoverview.component.scss']
})
export class DiscoverviewComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
