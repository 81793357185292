import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { registerLocaleData, HashLocationStrategy, LocationStrategy, DatePipe } from '@angular/common';
import {
  MatButtonModule,
  MatCardModule,
  MatGridListModule,
  MatMenuModule,
  MatTableModule,
  MatPaginatorModule,
  MatDialogModule,
  MatTabsModule,
  MatExpansionModule,
  MatProgressSpinnerModule,
  MatBadgeModule,
  MatStepperModule,
  MatInputModule,
  MAT_DATE_LOCALE
} from '@angular/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import localeFr from '@angular/common/locales/fr';
import localeFrExtra from '@angular/common/locales/extra/fr';

import localeEn from '@angular/common/locales/en';
import localeEnExtra from '@angular/common/locales/extra/en';

import { AppRoutingModule, routingComponents } from './services/routing-service/app-routing.module';

import { AppComponent } from './app.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MaterialModule } from './material';

import { FlexLayoutModule } from '@angular/flex-layout';
import {MatSelectModule} from '@angular/material/select';

// import ngx-translate and the http loader
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { RegisterComponent } from './auth/register/register.component';
import { LoginComponent } from './auth/login/login.component';
import { ContactComponent } from './pages/contact/contact.component';
import { LegalComponent } from './pages/legal/legal.component';
import { PrivacyComponent } from './pages/privacy/privacy.component';
import { RecoverComponent } from './auth/recover/recover.component';
import { SetnewComponent } from './auth/setnew/setnew.component';
import { TermsOfServiceComponent } from './pages/terms-of-service/terms-of-service.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { LoadingScreenComponent } from './components/loading-screen/loading-screen.component';
import { LoadingScreenInterceptor } from './helpers/loading.interceptor';
import { ConfirmComponent } from './auth/confirm/confirm.component';
import { RenewConfirmationComponent } from './auth/renew-confirmation/renew-confirmation.component';
import { DashboardComponent } from './project/dashboard/dashboard/dashboard.component';
import { DashboardnavComponent, dashboarddialogCompo, tutoCreateProjectdialog} from './project/dashboard/nav/dashboardnav.component';
import { DashboardcontentComponent } from './project/dashboard/content/dashboardcontent.component';
import { SearchComponent } from './project/dashboard/search/search.component';
import { DashboardMenuComponent, DashboardDialogComponent, DashboardDialogNotif, InvitationDialogComponent, commonGroupeComponent, tutoStepdialog} from './components/dashboard-menu/dashboard-menu.component';
import { UnRegMenuComponent } from './components/un-reg-menu/un-reg-menu.component';
import { SidebarMenuComponent } from './components/sidebar-menu/sidebar-menu.component';
import { FilterPipeProject } from './services/filterPipe/filterProject.pipe';
import { FilterPipeContact } from './services/filterPipe/filterContact.pipe';
import { SingleProjectComponent, DialogMemberListe, DialogAddMember, projectInfoDialog, transfertdialog, subjectInfodialog, infoWriteMessageDialog} from './project/dashboard/single-project/single-project.component';
import { AppFileUploadComponent } from './app-file-upload/app-file-upload.component';
import { DragDropDirective } from './directives/DragDropDirective';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import {MatChipsModule} from '@angular/material/chips';
import { ConfirmationDialogComponent } from './components/shared/confirmation-dialog/confirmation-dialog.component';
import { LightboxModule } from 'ngx-lightbox';
import { GedComponent, TruncatePipe } from './project/dashboard/ged/ged.component';
import { ActivateAccountcmpComponent } from './activate-accountcmp/activate-accountcmp.component';
import { CustomAccordionComponent } from './custom-accordion/custom-accordion.component';
import { ReadMoreComponent } from './project/dashboard/read-more/read-more.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatFormFieldModule } from '@angular/material';
import { StatistiqueComponent } from './statistique/statistique.component';
import { DiscoverviewComponent } from './discoverview/discoverview.component';
import { FaqComponent } from './faq/faq.component';
import { NgxMatDatetimePickerModule, NgxMatTimepickerModule, NgxMatNativeDateModule } from '@angular-material-components/datetime-picker';
import { NgxMatMomentModule } from '@angular-material-components/moment-adapter';
import { GeneralSettingComponent } from './general-setting/general-setting.component';
import { SecurityComponent } from './pages/security/security.component';
import { InscriptionDRHComponent } from './inscription-drh/inscription-drh.component';
import { DialogVideo } from './pages/home/home.component';
import { EmployeeJournalComponent } from './employee-journal/employee-journal.component';
import { MenuGuideComponent } from './menu-guide/menu-guide.component';
import { UnionNewspaperComponent } from './union-newspaper/union-newspaper.component';
import { SubjectsConfigComponent } from './subjects-config/subjects-config.component';
import { TutoViewComponent } from './tuto-view/tuto-view.component';


registerLocaleData(localeFr, 'fr-FR', localeFrExtra);
registerLocaleData(localeEn, 'en-GB', localeEnExtra);

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    routingComponents,
    RegisterComponent,
    LoginComponent,
    ContactComponent,
    LegalComponent,
    PrivacyComponent,
    RecoverComponent,
    SetnewComponent,
    TermsOfServiceComponent,
    SecurityComponent,
    ProfileComponent,
    LoadingScreenComponent,
    ConfirmComponent,
    RenewConfirmationComponent,
    DashboardComponent,
    DashboardnavComponent,
    DashboardcontentComponent,
    SearchComponent,
    DashboardMenuComponent,
    UnRegMenuComponent,
    SidebarMenuComponent,
    DashboardDialogComponent,
    tutoCreateProjectdialog,
    tutoStepdialog,
    DashboardDialogNotif,
    transfertdialog,
    InvitationDialogComponent,
    FilterPipeProject,
    FilterPipeContact,
    commonGroupeComponent,
    SingleProjectComponent,
    dashboarddialogCompo,
    AppFileUploadComponent,
    DialogMemberListe,
    DialogAddMember,
    projectInfoDialog,
    subjectInfodialog,
    infoWriteMessageDialog,
    DragDropDirective,
    ConfirmationDialogComponent,
    GedComponent,
    TruncatePipe,
    ActivateAccountcmpComponent,
    CustomAccordionComponent,
    ReadMoreComponent,
    StatistiqueComponent,
    DiscoverviewComponent,
    FaqComponent,
    GeneralSettingComponent,
    InscriptionDRHComponent,
    DialogVideo,
    EmployeeJournalComponent,
    MenuGuideComponent,
    UnionNewspaperComponent,
    SubjectsConfigComponent,
    TutoViewComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    FlexLayoutModule,
    MatSelectModule,
    MatButtonModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatBadgeModule,
    MatStepperModule,
    MatChipsModule,
    LightboxModule,
    MatFormFieldModule,
    NgxMatSelectSearchModule,
    MatInputModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatMomentModule, 
    // configure the imports
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  entryComponents: [
    DashboardDialogComponent,
    DashboardDialogNotif,
    transfertdialog,
    commonGroupeComponent,
    InvitationDialogComponent,
    dashboarddialogCompo,
    tutoCreateProjectdialog,
    tutoStepdialog,
    DialogMemberListe,
    DialogAddMember,
    projectInfoDialog,
    subjectInfodialog,
    infoWriteMessageDialog,
    ConfirmationDialogComponent,
    DialogVideo
  ],
  exports: [MatButtonModule, MatBadgeModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingScreenInterceptor,
      multi: true,
    },
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { showError: true }
    },
    { 
      provide: MAT_DATE_LOCALE, 
      useValue: 'fr' 
    },
    {
      provide: LocationStrategy, useClass: HashLocationStrategy
    },
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
