import { Component, OnInit, ViewChild, Input,ElementRef, Output, EventEmitter, OnDestroy } from '@angular/core';
import { MatPaginator, MatTableDataSource } from '@angular/material';
import { AuthService } from 'src/app/services/auth-service/auth.service';
import { merge, Observable, of as observableOf, Subject } from 'rxjs';
import { catchError, map, startWith, switchMap, takeUntil } from 'rxjs/operators';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { MessageService } from 'src/app/services/message/message.service';
import { FormBuilder, FormGroup, Validators, FormControl, FormGroupDirective, NgForm} from '@angular/forms';
import { environment } from 'src/environments/environment';
import { requiredFileType } from 'src/app/app-file-upload/upload-file-validators';
import { subjectInfodialog } from 'src/app/project/dashboard/single-project/single-project.component';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Lightbox } from 'ngx-lightbox';
import { GedComponent } from 'src/app/project/dashboard/ged/ged.component';

export interface searchFilter {
  transmitter: string;
  recipient: string;
  projectId: string;
  dateBefore: any;
  dateAfter: any;
  tag: string;
  statut: boolean;
  type: string;
  keyWord: string;
  smartNumber: string;
}

@Component({
  selector: 'app-employee-journal',
  templateUrl: './employee-journal.component.html',
  styleUrls: ['./employee-journal.component.scss']
})
export class EmployeeJournalComponent implements OnInit {

  currentDate = null;
  messages: any = [];
  dataSource: any;
  resultsLength = 0;
  perPage = 30;
  isLoadingResults = false;
  isRateLimitReached = false;
  lastDate = new Date('01/01/4970');
  filterChoosen = null;
  typeList = { QUESTION: "Question", ANSWER: "Réponse", COMMENT: "Commentaire"};


  displayedColumns: string[] = [
    'Date',
    'Projet',
    'Expéditeur',
    'Destinataire',
    'Sujet',
    /*'Type',*/
    'Statut',
    'consult'
  ];
  
  @ViewChild(MatPaginator) paginator: MatPaginator;

  @Output() EventLaunchSearch = new EventEmitter();
  @Output() EventLaunchSearchDoc = new EventEmitter();
  @Input() ged: boolean = false;

  transmitter = null;
  recipient = null;
  projectId = null;
  dateBefore = null;
  dateAfter = null;
  tag = null;
  statut = null;
  type = null;
  keyWord = null;
  smartNumber = null;
  modeDoc = false;
  contacts: Array<any> = [];
  projectLists: Array<any> = [];
  projectTags: any;
  chosenFilter: searchFilter;
  currentUser = null;
  saveCtc = null;
  saveProject = null;
  saveTheme   = null;
  public recipientCtrl: FormControl = new FormControl();
  public projectCtrl: FormControl = new FormControl();
  public themeCtrl: FormControl = new FormControl();
  protected _onDestroy = new Subject<void>();
  openWriteFace = false;
  submitted = false;
  sendMessageForm: FormGroup;
  projectFull: any;
  projectUsers = [];
  checked = false;
  files: any = [];
  isCommentActivated = true;

  @ViewChild('inputMedia') inputMedia: ElementRef;
  @ViewChild('appged') appged: GedComponent;

  constructor(private authService: AuthService, private router: Router, private route: ActivatedRoute, private formBuilder: FormBuilder, private messageService: MessageService,
    public dialog: MatDialog, private _lightbox: Lightbox) { 
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    delete currentUser.token;
    delete currentUser.refresh_token;
    delete currentUser.roles;
    this.currentUser = currentUser;
    //console.log('so big => '+JSON.stringify(this.currentUser));

    this.sendMessageForm = this.formBuilder.group({
      subProject: ['', Validators.required],
      newSubProject: '',
      recipient: ['', Validators.required],
      inCopy: [[]],
      private: false,
      deadLine: '',
      messageToSend: ['', [Validators.required, Validators.minLength(1)]],
      files: [null, [requiredFileType(['png','jpeg','jpg','mov','mp4'])]]
    });

    
  }


  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  ngOnInit() {  

    /*this.recipientCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterEmmetter();
      });


    this.projectCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterProject();
      });

    this.themeCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.themeProject();
      });


    this.getAllMessages(null);
  
    this.getContact();
    this.loadProject(); 

    this.authService.obprojectChosen.subscribe((idMessage) =>{ 
      this.smartNumber = idMessage;
      //console.log('dans le smarnumber idmessage ==> '+idMessage);
    });

     this.ged = false;
*/

    
   this.recipientCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterEmmetter();
      });


    this.projectCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterProject();
      });

    this.themeCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.themeProject();
    });


    this.getAllMessages(null);
    this.getContact();
    this.loadProject(); 
    this.ged = false;
    this.projectFull = [];
  }


  protected filterEmmetter() {
    if(this.saveCtc == null){
      this.saveCtc = this.contacts;
    }

    let search = this.recipientCtrl.value;
    if (!search) {
      this.contacts = this.saveCtc;
      return;
    } else {
      search = search.toLowerCase();
      this.contacts = this.saveCtc.filter(bank => bank.givenName.toLowerCase().indexOf(search) > -1);
    }
  }


  protected filterProject() {
    if(this.saveProject == null){
      this.saveProject = this.projectLists;
    }

    let search = this.projectCtrl.value;
    if (!search) {
      this.projectLists = this.saveProject;
      return;
    } else {
      search = search.toLowerCase();
      this.projectLists = this.saveProject.filter(bank => bank.libelle.toLowerCase().indexOf(search) > -1);
    }
  }

  protected themeProject() {
    // console.log("calling themeProject");
    if(this.saveTheme == null){
      this.saveTheme = this.projectTags;
      // console.log("in themeProject", this.saveTheme);
    }else{
      // console.log("else themeProject", this.projectTags);
    }

    let search = this.themeCtrl.value;
    if (!search) {
      this.projectTags = this.saveTheme;
      return;
    } else {
      search = search.toLowerCase();
      this.projectTags = this.saveTheme.filter(bank => bank.libelle.toLowerCase().indexOf(search) > -1);
    }
  }
  

  dataMap(data: any) {

    var strId = data['project']['@id']; 
    var splittedID = strId.split("/"); 
    var idProject = splittedID[splittedID.length-1];  
    var listTag : any[] = data['tags'];
    var date = data['dateCreated'];
    var isNewDay = this.isNewDay(date);

    var typeMessage = 'QUESTION';

    if (data['type'] == 'QUESTION') {
      typeMessage = 'Question';
    }
    else if (data['type'] == 'COMMENT'){
      typeMessage = 'Commentaire';
    }
    else if (data['type'] == 'ANSWER'){
      typeMessage = 'Réponse';
    }

    const message: any = {
      'id': idProject,
      'project': data['project'],
      'tag': (listTag[0] ? listTag[0].libelle  : '' ),
      'date': date,
      'typeParse': typeMessage,
      'type': data['type'],
      'private': data['isPrivate'],
      'sender': (data['author'] ? data['author']['fullName'] : ''),
      'recipient': (data['recipient'] ? data['recipient']['fullName'] : ''),
      'read': data['isMessageAnswered'],
      'deadline': data['deadline'],
      'number': data['number'],
      'smartNumber': data['smartNumber'],
      'filegeneric': data['filegeneric'],
      'isMessageDisabled': data['isMessageDisabled'],
      'isMessageAnswered': (data['isMessageAnswered'] === true ? 'Répondu' : 'Non répondu'),
      'isNewDay' : isNewDay,
      'isMessageGlobal' : data['isMessageGlobal']
    };

    return message;
  }

 public getAllMessages(searchChoose) {

    this.paginator.pageIndex = 0;
    this.filterChoosen = searchChoose;

    merge(this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          return this.authService.getAllMessagesEmployee(this.paginator.pageIndex, this.perPage, this.filterChoosen);
        }),
        map(data => {
          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
          this.isRateLimitReached = false;    

          this.resultsLength = data['hydra:totalItems']; 

          return data['hydra:member'];
        }),
        catchError(() => {
          this.isLoadingResults = false;
          // Catch if the GitHub API has reached its rate limit. Return empty data.
          this.isRateLimitReached = true;
          return observableOf([]);
        })
      ).subscribe(data => { 
         
        //console.log('contenu de data '+ JSON.stringify(data));
        this.messages = [];
        this.lastDate = new Date('01/01/4970');
        
        data.forEach(element => {
          if (element['isMessageDisabled'] == false) {
            this.messages.push(this.dataMap(element));
          }
        });

        let afterIsNewDay = false;
        this.messages.reverse().forEach((element, index) => {
          if(afterIsNewDay || index == 0){
            this.messages[index]["afterIsNewDay"] = true;
            afterIsNewDay = false;
            //console.log("afterIsNewDay")
          }
          if(element.isNewDay == true){
            afterIsNewDay = true;
          }
        });

        this.dataSource = this.messages.reverse(); 
         

      });

  }

  getStatusbis(userData){

    if(this.projectFull && this.projectFull.typeProject && (this.projectFull.typeProject == 'PARTENAIRE') && (userData.skills.includes(10) == true) ){
      return 'CSE';
    }
    else if(this.projectFull && this.projectFull.typeProject && (this.projectFull.typeProject == 'CHO') && (userData.skills.includes(20) == true) ){
      return 'CHO';
    }
    else if(this.projectFull && this.projectFull.typeProject && (this.projectFull.typeProject == 'CGT') && (userData.skills.includes(1) == true) ){
      return 'Syndiqué CGT';
    }
    else if(this.projectFull && this.projectFull.typeProject && (this.projectFull.typeProject == 'CFDT') && (userData.skills.includes(5) == true) ){
      return 'Syndiqué CFDT';
    }
    else if(this.projectFull && this.projectFull.typeProject && (this.projectFull.typeProject == 'CFE-CGC') && (userData.skills.includes(7) == true) ){
      return 'Syndiqué CFE-CGC';
    }
    else if(this.projectFull && this.projectFull.typeProject && (this.projectFull.typeProject == 'CFTC') && (userData.skills.includes(8) == true) ){
      return 'Syndiqué CFTC';
    }
    else if(this.projectFull && this.projectFull.typeProject && (this.projectFull.typeProject == 'FO') && (userData.skills.includes(6) == true) ){
      return 'Syndiqué FO';
    }
    else {
      switch (userData.profil) {
        case 'HRD':
          return "DRH";
          break;
        case 'HR':
          return "RRH";
          break;
        case 'EMPLOYEE':
          return "Salarié";
          break;
        case 'CS':
          return "Manager";
          break;
        default:
          return userData.profil;
          break;
      }
    }

  }
  
  getStatus(key){
    switch (key) {
      case 'HRD':
        return "DRH";
        break;
      case 'HR':
        return "RRH";
        break;
      case 'EMPLOYEE':
        return "Salarié";
        break;
      case 'CS':
        return "Manager";
        break;
      default:
        return key;
        break;
    }
  }

  getSkill(contact){
    var skillList = ''; 
    if (contact.skills.includes(1) == true){
      skillList += 'CGT ';
    } 
    if (contact.skills.includes(5) == true){
      skillList += 'CFDT ';
    }  
    if (contact.skills.includes(6) == true){
      skillList += 'FO ';
    }   
    if (contact.skills.includes(7) == true){
      skillList += 'CFE-CGC ';
    }   
    if (contact.skills.includes(8) == true){
      skillList += 'CFTC ';
    }   
    return skillList;
  }

  isRoundFirst(afterIsNewDay, isNewDay){
    if(afterIsNewDay && isNewDay){
      return "divcontaine afterIsNewDayBL afterIsNewDayTL";
    }else if(afterIsNewDay){
      return "divcontaine afterIsNewDayBL";
    }else if(isNewDay){
      return "divcontaine afterIsNewDayTL";
    }
    return "divcontaine";
  }

  isRoundLast(afterIsNewDay, isNewDay){
    if(afterIsNewDay && isNewDay){
      return "divcontaine afterIsNewDayBR afterIsNewDayTR";
    }else if(afterIsNewDay){
      return "divcontaine afterIsNewDayBR";
    }else if(isNewDay){
      return "divcontaine afterIsNewDayTR";
    }
    return "divcontaine";
  }

  isNewDay(messageDate){
    let currentDate = new Date(messageDate.substring(0,10)); 
    //console.log('this.lastDate ==> '+this.lastDate);
    //console.log('currentDate ==> '+currentDate);
    if(currentDate < this.lastDate)
    {
      // console.log('Une section ==> '+ currentDate);
      this.lastDate = currentDate;
      return true;
    } 
    else
    { 
      this.lastDate = currentDate; 
      return false;
    }
  }

  getContact(){
    this.loadContact();
  }

  loadContact(url?: string){
    url = url ? url : undefined;
    this.authService.getContacts(url).subscribe(res => {
      this.contacts = this.contacts.concat(res['hydra:member']);
      
      //this.contacts = res['hydra:member'];
      if(this.contacts.length && this.contacts[0].id != this.currentUser.id){
        this.contacts.unshift(this.currentUser);
      }
      this.contacts.sort(this.compare);

      if(res['hydra:view']['hydra:next']){
        this.loadContact(res['hydra:view']['hydra:next'].replace("/api", ""));
      }
    }); 
  }

  compare( a, b ) {
    if ( a.familyName < b.familyName ){
      return -1;
    }
    if ( a.familyName > b.familyName ){
      return 1;
    }
    return 0;
  }

  setetSalaries(list){
    list = list.map(item => {
      item.salary = item.userProject.filter(item => item.profil === 'EMPLOYEE');
      return item;
    })
    return list;
  }

  loadProject(url?: string){
    url = url ? url : undefined;
    this.authService.getProjects(url).subscribe(res => {
      // console.log(res)
      let list = this.setetSalaries(res['hydra:member']);

      if (list.length > 0) {
       this.projectLists = this.projectLists.concat(list);
      }

      if(res['hydra:view']['hydra:next']){
        this.loadProject(res['hydra:view']['hydra:next'].replace("/api", ""));
      }
      else if(res['hydra:totalItems'] == this.projectLists.length){
        if (this.projectLists.length > 0) {
       
            var tmpArray = [];

            for (var i = 0; i < this.projectLists.length; i++) { 
              if (!this.projectLists[i].typeProject) {
                tmpArray.push(this.projectLists[i]);
              }
            }
         
            this.projectLists = [];
            this.projectLists = this.projectLists.concat(tmpArray);

          }
        }
    }); 

  }

  getSujet(idProject){
    this.projectId = idProject;
    if(idProject != null)
    this.authService.getProjectSuject(idProject).subscribe(res => {
      this.projectTags = res['hydra:member'];
    }, (error) => {
       //console.log(' getTheProject subscribe error '+error.statusText);
    });
  }

  projectIsChosen(salaryId){
    
    this.projectFull = [];
    this.projectUsers = [];
    this.resetQuestionFrom();

    if (salaryId) {
      this.authService.getTheProject(salaryId)
        .subscribe(res => {  
          this.projectFull = res;
          var tmpArrayUser = res['userProject'];
          this.projectUsers = [];
          for (var i = 0; i < tmpArrayUser.length; i++) {
            if(tmpArrayUser[i].user){
              this.projectUsers.push(tmpArrayUser[i]);
            }
          }
        }, (error) => {
           //console.log(' getTheProject subscribe error '+error.statusText);
      });
    }
  }

  toggleAllSelection() {
    if(this.sendMessageForm.controls.inCopy.value.length==this.getUserListWithNoCurrentUser().length){ 
      this.sendMessageForm.controls.inCopy.patchValue([]);
      this.checked = false;
    }
    else
    {
      this.sendMessageForm.controls.inCopy.patchValue([...this.getUserListWithNoCurrentUser().map(item => item.user.id)]);
      this.checked = true;
    }
  }

  getUserListWithNoCurrentUser(){
    const recipient = this.sendMessageForm.controls.recipient.value
    if(!this.projectUsers) return [];
    var arrayUser = [];
    for (var i = 0; i < this.projectUsers.length; i++) {
      if(this.projectUsers[i].user.id != this.currentUser.id && this.projectUsers[i].user.id != recipient){
        arrayUser.push(this.projectUsers[i]);
      }
    }
    return arrayUser;
  }

  onSelectPrivate(){
    if (this.sendMessageForm.controls.private.value === false)
    {
       this.sendMessageForm.get('private').setValue(true); 
    }
    else
    {
      this.sendMessageForm.get('private').setValue(false); 
    } 
  }

  launchSearch(){

    this.modeDoc = false;

    this.chosenFilter = { 
      transmitter: this.transmitter,
      recipient: this.recipient,
      projectId: this.projectId,
      dateBefore: this.dateBefore,
      dateAfter: this.dateAfter,
      tag: this.tag,
      statut: this.statut, 
      type: this.type,
      keyWord: (this.keyWord ? this.keyWord.trim() : null),
      smartNumber: this.smartNumber
    };
    //console.log(' launchSearch '+ JSON.stringify(this.chosenFilter) );
    //this.EventLaunchSearch.emit(this.chosenFilter);

    this.getAllMessages(this.chosenFilter);
    this.appged.reset();
  }

  launchSearchDoc(){

    this.modeDoc = true;

    this.chosenFilter = { 
      transmitter: this.transmitter,
      recipient: this.recipient,
      projectId: this.projectId,
      dateBefore: this.dateBefore,
      dateAfter: this.dateAfter,
      tag: this.tag,
      statut: this.statut,
      type: this.type, 
      keyWord: (this.keyWord ? this.keyWord.trim() : null),
      smartNumber: this.smartNumber
    };
    //console.log(' launchSearchDoc '+ JSON.stringify(this.chosenFilter) );
    //this.EventLaunchSearchDoc.emit(this.chosenFilter);

   this.appged.filterFichierOnlySalary(this.chosenFilter, this.chosenFilter.projectId, true);  

  }

  public resetSearch(){

    this.transmitter = null;
    this.recipient = null;
    this.projectId = null;
    this.dateBefore  = null;
    this.dateAfter  = null;
    this.tag = null;
    this.statut = null;
    this.type = null;
    this.keyWord = null;
    this.smartNumber = null;

    if(this.modeDoc == false) 
    {
      this.launchSearch();
    }
    else
    {
      this.launchSearchDoc();
    }

  }


  public refreshInfoFilter(){
    this.getContact();
    this.loadProject();  
  }

  writeMessage(){
    if(this.openWriteFace == false)
    {
      this.openWriteFace = true;
    }
    else
    {
      this.openWriteFace = false;
      this.resetQuestionFrom();
    }
  }

  exiteWriteMode(){
    this.openWriteFace = false;
    this.resetQuestionFrom();
  }


  get f() { return this.sendMessageForm.controls; }

  uploadFile(event) {
    
    for (let index = 0; index < event.length; index++) {
      const element = event[index];
      this.files.push(element);
    } 
    this.inputMedia.nativeElement.value = "";
  }


  open(file){

    const reader = new FileReader();
    reader.onload = (e: any) => {
       //console.log('csv content', e.target.result);
       const album = {
          src: e.target.result
       };
       let albums = [];
       albums.push(album);
       this._lightbox.open(albums, 0);

       setTimeout(() => {
        document.querySelector( 'img.lb-image' ).removeAttribute("hidden");
        if(document.querySelector( '.lb-loader' )) document.querySelector( '.lb-loader' ).setAttribute("hidden", "true");
        if(document.querySelector( '.lb-nav' )) document.querySelector( '.lb-nav' ).setAttribute("hidden", "true");
       }, 1500);

    };
    reader.readAsDataURL(file);

  }

  playVideoOrAudio(file, source){
    source.src = URL.createObjectURL(file);
  }

  createFileUrl(file, source){
    source.href = URL.createObjectURL(file);
  }

  getFiletype(file){

    if (file.type.includes("image/") == true) {
      return 1;
    }
    else if (file.type.includes("video/") == true) {
      return 2; 
    }
    else if (file.type.includes("application/") == true) {
      return 3;
    }
    else if (file.type.includes("audio/") == true) {
      return 4;
    }
    else
    {
      return -1;
    }

  }

  close(): void {
    // close lightbox programmatically
    this._lightbox.close();
  }

  deleteAttachment(index) {
    this.files.splice(index, 1);
    //console.log(this.files)
  }

  onSubmitForm(customerData) {

    console.log('Your onSubmitForm order has been submitted', customerData);

    this.submitted = true;
     // stop here if form is invalid
    if (this.sendMessageForm.invalid) {
      return;
    }

    if (customerData.recipient == -1) {// si tous les salariés en destinataire
      this.sendMessageForm.get('recipient').setValidators(null);
      this.sendMessageForm.get('recipient').setValue('');
      this.sendMessageForm.get('recipient').updateValueAndValidity();
      this.sendMessageForm.get('private').setValue(false);
      this.sendMessageForm.controls.inCopy.patchValue([...this.getUserListWithNoCurrentUser().map(item => item.user.id)]);
      this.sendQuestion(this.sendMessageForm.value, true);
    }
    else{
      this.sendQuestion(customerData, false);
    }
 
  }

  resetQuestionFrom(){

    //set defaut form values
    this.files = [];
    this.checked = false;
    this.sendMessageForm.get('subProject').setValue('');
    this.sendMessageForm.get('subProject').setValidators([Validators.required]);
    this.sendMessageForm.get('subProject').updateValueAndValidity();
    this.sendMessageForm.get('newSubProject').setValue('');
    this.sendMessageForm.get('recipient').setValue('');
    this.sendMessageForm.get('recipient').setValidators([Validators.required]);
    this.sendMessageForm.get('recipient').updateValueAndValidity();
    this.sendMessageForm.get('inCopy').setValue([]);
    this.sendMessageForm.get('private').setValue(false);
    this.sendMessageForm.get('deadLine').setValue('');
    this.sendMessageForm.get('messageToSend').setValue('');
    this.sendMessageForm.get('messageToSend').setValidators([Validators.required, Validators.minLength(1)]);
    this.sendMessageForm.get('messageToSend').updateValueAndValidity();
    this.sendMessageForm.get('files').setValue(null);
    this.sendMessageForm.get('files').setValidators([requiredFileType(['png','jpeg','jpg','mov','mp4'])]); 
    this.sendMessageForm.get('files').updateValueAndValidity();
    
  }

  sendQuestion(messageTosendData, AllMessage) {
    this.authService.sendQuestion(
      messageTosendData.messageToSend,
      messageTosendData.subProject,
      '/api/projects/'+this.projectFull.id,
      messageTosendData.recipient,
      messageTosendData.inCopy,
      messageTosendData.private,
      this.isCommentActivated,
      messageTosendData.deadLine,
      this.files,
      AllMessage
    )
    .subscribe(res => {
      //console.log('reponse envoi message: ',res);
      this.submitted = false;
      this.resetQuestionFrom();
      this.openWriteFace = false;
      this.router.navigate(['/project/'+this.projectFull.id]);
    });
  }

  fctGetImageUser(userData){
    if (userData.imageUrl) 
    {
      return  this.getAvatarlUrl(userData.imageUrl);
    }
    else 
    {  
      return '../../../../assets/img/default-avatar.png'  
    }
  }

  getAvatarlUrl(avatarLink){
    return environment.server_url+avatarLink;
  }

  changeSubject(){
    
    const dialogRef = this.dialog.open(subjectInfodialog, {
      //minWidth: ((this.projectFull.isDefaultChanel == true) || (this.projectFull.typeProject && this.projectFull.typeProject == 'MANAGER')) ? '400px' : '900px', 
      minWidth: '400px', 
      position:{ top: '10px' },
      data: {
        projectData : this.projectFull,
        sendMessageForm : this.sendMessageForm
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      //console.log('The dialog was closed fctinfoProjet '+result); 
      if (result) {
       this.sendMessageForm.patchValue({
          subProject: result
        });
      } 
    });

  }

  getDeadline(){
    if(this.sendMessageForm.controls.deadLine.value){
      return this.sendMessageForm.controls.deadLine.value;
    }
    return '';
    //console.log('Your order has been submitted ---> ', this.sendMessageForm.value);
  }

}
