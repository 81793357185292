

export const environment = {
   production: false,
   api_url: 'https://apiapp.facerh.fr/api',
   server_url: 'https://apiapp.facerh.fr'
}


/*
export const environment = {
   production: false,
   api_url: 'http://localhost:8090/api',
   server_url: 'http://localhost:8090/'
};
*/

/*
export const environment = {
   production: false,
   api_url: 'https://devapi.facerh.fr/api',
   server_url: 'https://devapi.facerh.fr'
}*/
