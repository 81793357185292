import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tuto-view',
  templateUrl: './tuto-view.component.html',
  styleUrls: ['./tuto-view.component.scss']
})
export class TutoViewComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
