import { Component, OnInit, Pipe, PipeTransform, ViewChild, ElementRef } from '@angular/core';
import { AuthService } from 'src/app/services/auth-service/auth.service';
import { Lightbox } from 'ngx-lightbox';
import {MatPaginator} from '@angular/material/paginator';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-ged',
  templateUrl: './ged.component.html',
  styleUrls: ['./ged.component.scss']
})
export class GedComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('targetGED') targetGED: ElementRef;

  perPage = 12;
  resultsLength = 0;
  files = null;
  chosenFilter = null;
  projectId = null;
  expanded:boolean = false;
  isLoad = false;
  openPdf= false;
  currentUser = null;
  salaryOnly = false;
  selectedPdf = null;

  constructor(private authService: AuthService, private _lightbox: Lightbox, protected _sanitizer: DomSanitizer) {
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    delete currentUser.token;
    delete currentUser.refresh_token;
    delete currentUser.roles;
    this.currentUser = currentUser;
  }

  ngOnInit() {
    if (this.paginator) {
      this.paginator.pageIndex = 0;
    }
  }

  filterFichier(chosenFilter, ID, scroll){
    this.chosenFilter = chosenFilter;
    this.projectId = ID;
    this.resultsLength = 0;
    this.files = null;
    this.isLoad = false;
    this.salaryOnly = false;
    if (this.paginator) {
      this.paginator.pageIndex = 0;
    }
    this.fetchFiles(0, scroll);
  }

  filterFichierOnlySalary(chosenFilter, ID, scroll){
    this.chosenFilter = chosenFilter;
    this.projectId = ID;
    this.resultsLength = 0;
    this.files = null;
    this.isLoad = false;
    this.salaryOnly = true;
    if (this.paginator) {
      this.paginator.pageIndex = 0;
    }
    this.fetchFiles(0, scroll);
  }

  reset(){
    this.chosenFilter = null;
    this.projectId = null;
    this.resultsLength = 0;
    this.files = null;
    if (this.paginator) {
      this.paginator.pageIndex = 0;
    }
    this.expanded = false;
    this.isLoad = false;
  }

  fetchFiles(page, scroll){
    this.expanded = true;
    this.authService.getFiles(this.projectId, page, this.perPage, null, this.chosenFilter, this.salaryOnly).subscribe(res => {
      this.isLoad = true;
      this.resultsLength = res['hydra:totalItems'];
      this.files = res["hydra:member"];
      if(scroll)
        this.targetGED.nativeElement.scrollIntoView({behavior:"smooth"});
      //console.log(res);
    })
  }

  getMediaLink(mediaUrl){
    return this.authService.getMediaData(mediaUrl);
  }

  getPdfLink(mediaUrl){
    console.log(this.authService.getMediaData(mediaUrl));
    return this._sanitizer.bypassSecurityTrustResourceUrl(this.authService.getMediaData(mediaUrl));

  }

  getPdf(media: any){
    this.selectedPdf=media;
  }
   
  closeModal(){
    this.selectedPdf=null;
  }

  open(url){


    url = this.getMediaLink(url);
    // alert(url)
    const album = {
      src: url
   };
   let albums = [];
   albums.push(album);
   this._lightbox.open(albums, 0);

   setTimeout(() => {
    document.querySelector( 'img.lb-image' ).removeAttribute("hidden");
    if(document.querySelector( '.lb-loader' )) document.querySelector( '.lb-loader' ).setAttribute("hidden", "true");
    if(document.querySelector( '.lb-nav' )) document.querySelector( '.lb-nav' ).setAttribute("hidden", "true");
   }, 1500);

  }

  handlePage(e: any) {
    this.fetchFiles(e.pageIndex, true);
  }


  getIcon(extension){
    let filelink = "";
    if(extension.includes("spreadsheetml.sheet") || extension.includes("application/vnd.ms-excel")
    || extension.includes("application/octet-stream")){
      filelink = "../../assets/icons/excel.png";
    }else if(extension.includes("presentationml.presentation")){
      filelink = "../../assets/icons/ppt.png";
    }else if(extension.includes("application/msword") || extension.includes("wordprocessingml.document")){
      filelink = "../../assets/icons/word.png";
    }else if(extension.includes("application/zip")){
      filelink = "../../assets/icons/zip.png";
    }else if(extension.includes("video/quicktime")){
      filelink = "../../assets/icons/video.png";
    }

    return filelink ? filelink : false;
  }

}

@Pipe({
  name: 'truncate'
 })
 export class TruncatePipe implements PipeTransform {
 transform(value: string, args: number): string {
   //console.log("arg", args);
     const limit = args;
     const trail = '...';
     return value.length > limit ? value.substring(0, limit) + trail : value;
    }
 }


