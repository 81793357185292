import { Component, OnInit, Input} from '@angular/core';
import { AuthService } from 'src/app/services/auth-service/auth.service';
import { Lightbox } from 'ngx-lightbox';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-custom-accordion',
  templateUrl: './custom-accordion.component.html',
  styleUrls: ['./custom-accordion.component.scss']
})
export class CustomAccordionComponent implements OnInit {

  openPdf= false;
  selectedPdf = null;
  displayBlock = "none";
  @Input() media: any;

  constructor( private authService: AuthService, protected _sanitizer: DomSanitizer, private _lightbox: Lightbox) { }

  ngOnInit() {
  }

  hidingAndshowing(){
	if (this.displayBlock === "block") {
      this.displayBlock = "none";
    } else {
      this.displayBlock = "block";
    }
  }

  getMediaLink(mediaUrl){
    return this.authService.getMediaData(mediaUrl);
  }
  getPdfLink(mediaUrl){
    console.log(this.authService.getMediaData(mediaUrl));
    return this._sanitizer.bypassSecurityTrustResourceUrl(this.authService.getMediaData(mediaUrl));

  }

  getPdf(media: any){
    this.selectedPdf=media;
   }
   closeModal(){
    this.selectedPdf=null;
   }

  getMediaThumbail(mediaUrl){
    return this.authService.getMediaThumbail(mediaUrl);
  }

  openWithUrl(url){

    url = this.getMediaLink(url);
    // alert(url)
    const album = {
      src: url
   };
   let albums = [];
   albums.push(album);
   this._lightbox.open(albums, 0);

   setTimeout(() => {
    document.querySelector( 'img.lb-image' ).removeAttribute("hidden");
    if(document.querySelector( '.lb-loader' )) document.querySelector( '.lb-loader' ).setAttribute("hidden", "true");
    if(document.querySelector( '.lb-nav' )) document.querySelector( '.lb-nav' ).setAttribute("hidden", "true");
   }, 1500);

  }

  openWithData(file){

    const reader = new FileReader();
    reader.onload = (e: any) => {
       //console.log('csv content', e.target.result);
       const album = {
          src: e.target.result
       };
       let albums = [];
       albums.push(album);
       this._lightbox.open(albums, 0);

       setTimeout(() => {
        document.querySelector( 'img.lb-image' ).removeAttribute("hidden");
        if(document.querySelector( '.lb-loader' )) document.querySelector( '.lb-loader' ).setAttribute("hidden", "true");
        if(document.querySelector( '.lb-nav' )) document.querySelector( '.lb-nav' ).setAttribute("hidden", "true");
       }, 1500);

    };
    reader.readAsDataURL(file);

  }


  createFileUrl(url, source){
    source.href = URL.createObjectURL(this.getMediaLink(url));
  }


}
