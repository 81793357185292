import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import * as moment from 'moment';
import {merge, Observable, of as observableOf, Subject} from 'rxjs';
import { environment } from '../../../environments/environment';
import { MessageService } from 'src/app/services/message/message.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  //uriApi = environment.api_url+'https://theface.applize.fr/api';
  uriApi = environment.api_url;
  uriMediaThumbail = environment.server_url+'/media/cache/resolve/thumbnail/media/facedoc/';
  uriMediaFullSize = environment.server_url+'/media/facedoc/';
  resp: any;
  token: any;
  jwtToken: any;
  currentUser: any = JSON.parse(localStorage.getItem('currentUser'));
  httpOptions: any;
  
  public obRefreshProjet = new Subject();
  public obRefreshProjet$ = this.obRefreshProjet.asObservable();

  public obNotifBadge = new Subject();
  public obNotifBadge$ = this.obNotifBadge.asObservable();

  public obInviteBadge = new Subject();
  public obInviteBadge$ = this.obInviteBadge.asObservable();

  public obprojectChosen = new Subject();
  public obprojectChosen$ = this.obprojectChosen.asObservable();


  public obCommonProjectChosen = new Subject();
  public obCommonProjectChosen$ = this.obCommonProjectChosen.asObservable();

  constructor(private http: HttpClient, private router: Router, private messageService: MessageService) { 
    localStorage.setItem('popupCreateSalary', 'true');
  }

  public get logIn(): boolean {
    return (localStorage.getItem('currentUser') !== null);
  }

  userCompany() {
    return JSON.parse(localStorage.getItem('currentUser'))['companyName'];
  }

   userCompanyhrFullName() {
    return JSON.parse(localStorage.getItem('currentUser'))['hrFullName'];
  }

  httpOptionsR() {
    if (this.currentUser !== null) {
      this.httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('currentUser'))['token']
        })
      };
    }
    return this.httpOptions;
  }


  login(email: string, password: string) {
    return this.http.post(this.uriApi + '/pub/login', { email: email, plainPassword: password })
      .pipe(map(resp => {
        if (resp) {
          //console.log(' retour connexion => '+JSON.stringify(resp));
          localStorage.setItem('currentUser', JSON.stringify(resp));
          this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
          this.router.navigate(['/dashboard']);
          localStorage.setItem('popupCreateSalary', 'true');
          /*this.messageService.setShowCreateProjectTuto(true);
          this.messageService.setShowProjectTuto(true);
          this.messageService.setShowProjectTutobis(true);
          this.messageService.setShowNotifTuto(true);*/
        }
        return resp;
      }));
  }

  loginbis(email: string, password: string) {
    return this.http.post(this.uriApi + '/pub/login', { email: email, plainPassword: password })
      .pipe(map(resp => {
        if (resp) {
          //console.log(' retour connexion => '+JSON.stringify(resp));
          localStorage.setItem('currentUser', JSON.stringify(resp));
          this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
        }
        return resp;
      }));
  }

  logout() {
    localStorage.removeItem('currentUser');
    localStorage.removeItem('userIsManager');
    localStorage.setItem('popupCreateSalary', 'false');
    this.currentUser = null;
    this.router.navigate(['/']);
    /*this.messageService.setShowCreateProjectTuto(false);
    this.messageService.setShowProjectTuto(false);
    this.messageService.setShowProjectTutobis(false);
    this.messageService.setShowNotifTuto(false);*/
  }

  register(fname: string, lname: string, jobT: string, email: string, passwd: string, gende: string) {
    // tslint:disable-next-line:max-line-length
    /*return this.http.post(this.uriApi + '/pub/register', { email: email, givenName: fname, familyName: lname, jobTitle: jobT, plainPassword: passwd, gender: gende })
      .pipe(map(resp => {
        if (resp) {
          console.log(resp);
        }
        return resp;
      }));*/

      return this.http.post(this.uriApi + '/pub/register', { email: email, givenName: fname, familyName: lname, plainPassword: passwd})
      .pipe(map(resp => {
        if (resp) {
          console.log(resp);
        }
        return resp;
      }));
  }

  recoverPw(email: string) {
    return this.http.post(this.uriApi + '/pub/forgot-password-request', { email: email })
      .pipe(map(resp => {
        return resp;
      }));
  }

  setNew(password: string, token: string) {
    //console.log('{ passwordResetToken: ' + token + ', plainPassword: ' + password + ' }');
    return this.http.post(this.uriApi + '/pub/reset-password-confirmation', { passwordResetToken: token, plainPassword: password })
      .pipe(map(resp => {
        return resp;
      }));
  }

  confirm(token: string) {
    return this.http.post(this.uriApi + '/pub/user-confirmation', { confirmationToken: token })
      .pipe(map(resp => {
        return resp;
      }));
  }

  renewConf(email: string) {
    return this.http.post(this.uriApi + '/pub/renew-user-confirmation', { email: email })
      .pipe(map(resp => {
        return resp;
      }));
  }

  getProjects(uri?: string) {
    const httpOptionsG = this.httpOptionsR(); 
    uri = uri ? uri : '/projects?perPage=20&order[motionAt]=desc';
    return this.http.get(this.uriApi + uri, httpOptionsG)
      .pipe(map(resp => {
        return resp;
      })); 
  }

  getProjectsWithMessage(uri?: string) {
    const httpOptionsG = this.httpOptionsR(); 
    uri = uri ? uri : '/projects-used?perPage=20&order[motionAt]=desc';
    return this.http.get(this.uriApi + uri, httpOptionsG)
      .pipe(map(resp => {
        return resp;
      })); 
  }

  getCommonProjects(pageIndex: any, perPage: any, withuser_id: any) {
    const httpOptionsG = this.httpOptionsR();
    return this.http.get<any>(this.uriApi + '/projects?withuser_id='+withuser_id+'&page='+(pageIndex+1)+'&perPage='+perPage+'&order[motionAt]=desc', httpOptionsG);  
  }

  getTheProject(ID: any) {
    const httpOptionsG = this.httpOptionsR();
    return this.http.get(this.uriApi + '/projects/' + ID, httpOptionsG)
      .pipe(map(resp => {
        return resp;
      }));
  }

  getProjectSuject(ID: any) {
    const httpOptionsG = this.httpOptionsR();
    return this.http.get(this.uriApi + '/subjects?project=' + ID, httpOptionsG)
      .pipe(map(resp => {
        return resp;
      }));
  } 

  getAllMessages(pageIndex: any, perPage: any, searchValues: any) {
    
    const httpOptionsG = this.httpOptionsR();
    if(searchValues)
    {

      var param = '/messages?page='+(pageIndex+1)+'&perPage='+perPage+'&order[dateCreated]=desc';

      if (searchValues.transmitter) {
        param+='&author='+searchValues.transmitter;
      }
      
      /*if (searchValues.recipient) {
        param+='&recipient='+searchValues.recipient;
      }*/

      if (searchValues.recipient ) {
        if (searchValues.recipient != -1) {
          param+='&recipient='+searchValues.recipient;
        }
        else
        {
          param+='&isMessageGlobal=true';
        }
      }

      if (searchValues.projectId) {
        param+='&project='+searchValues.projectId;
      }
      if (searchValues.tag) {
        param+='&tags.id='+searchValues.tag;
      }
      if (searchValues.keyWord) {
        param+='&orSearch_text='+searchValues.keyWord;
      }
      if (searchValues.smartNumber) {
        param+='&smartNumber='+searchValues.smartNumber;
      }

      if ((searchValues.type == 'pdf') || (searchValues.type == 'video') || (searchValues.type == 'image') || (searchValues.type == 'excel') || (searchValues.type == 'presentation')){
        param+='&filegeneric.extension='+searchValues.type;
      }
      
      if (searchValues.statut) {
        if (searchValues.statut == 'true') {
          param+='&isMessageAnswered='+searchValues.statut+'&type=QUESTION';
        }
        else
        {
          param+='&isMessageAnswered='+searchValues.statut+'&type=QUESTION&isMessageGlobal=false'; 
        }
      }

      if (searchValues.dateBefore) {
        var strDate = moment(searchValues.dateBefore).format('DD-MM-YYYY');    
        param+='&dateCreated[before]='+ strDate.toLocaleString( );
      }
      if (searchValues.dateAfter) {
        var strDate = moment(searchValues.dateAfter).format('DD-MM-YYYY');    
        param+='&dateCreated[after]='+ strDate.toLocaleString( );
      }

      return this.http.get<any>(this.uriApi + param, httpOptionsG);
    }
    else
    {
      return this.http.get<any>(this.uriApi + '/messages?page='+(pageIndex+1)+'&perPage='+perPage+'&order[dateCreated]=desc', httpOptionsG);
    }
  }


  getAllMessagesEmployee(pageIndex: any, perPage: any, searchValues: any) {
    
    const httpOptionsG = this.httpOptionsR();
    if(searchValues)
    {

      var param = '/messages?exists[project.typeProject]=false&page='+(pageIndex+1)+'&perPage='+perPage+'&order[dateCreated]=desc';

      if (searchValues.transmitter) {
        param+='&author='+searchValues.transmitter;
      }

      if (searchValues.recipient ) {
        if (searchValues.recipient != -1) {
          param+='&recipient='+searchValues.recipient;
        }
        else
        {
          param+='&isMessageGlobal=true';
        }
      }

      if (searchValues.projectId) {
        param+='&project='+searchValues.projectId;
      }
      if (searchValues.tag) {
        param+='&tags.id='+searchValues.tag;
      }
      if (searchValues.keyWord) {
        param+='&orSearch_text='+searchValues.keyWord;
      }
      if (searchValues.smartNumber) {
        param+='&smartNumber='+searchValues.smartNumber;
      }

      if ((searchValues.type == 'pdf') || (searchValues.type == 'video') || (searchValues.type == 'image') || (searchValues.type == 'excel') || (searchValues.type == 'presentation')){
        param+='&filegeneric.extension='+searchValues.type;
      }
      
      if (searchValues.statut) {
        if (searchValues.statut == 'true') {
          param+='&isMessageAnswered='+searchValues.statut+'&type=QUESTION';
        }
        else
        {
          param+='&isMessageAnswered='+searchValues.statut+'&type=QUESTION&isMessageGlobal=false'; 
        }
      }

      if (searchValues.dateBefore) {
        var strDate = moment(searchValues.dateBefore).format('DD-MM-YYYY');    
        param+='&dateCreated[before]='+ strDate.toLocaleString( );
      }
      if (searchValues.dateAfter) {
        var strDate = moment(searchValues.dateAfter).format('DD-MM-YYYY');    
        param+='&dateCreated[after]='+ strDate.toLocaleString( );
      }

      return this.http.get<any>(this.uriApi + param, httpOptionsG);
    }
    else
    {
      return this.http.get<any>(this.uriApi + '/messages?exists[project.typeProject]=false&page='+(pageIndex+1)+'&perPage='+perPage+'&order[dateCreated]=desc', httpOptionsG);
    }
  }

  getAllMessagesSyndicats(pageIndex: any, perPage: any, searchValues: any) {
    
    const httpOptionsG = this.httpOptionsR();
    if(searchValues)
    {

      var param = '/messages?project.espace=SYNDICAT&page='+(pageIndex+1)+'&perPage='+perPage+'&order[dateCreated]=desc';

      if (searchValues.transmitter) {
        param+='&author='+searchValues.transmitter;
      }

      if (searchValues.recipient ) {
        if (searchValues.recipient != -1) {
          param+='&recipient='+searchValues.recipient;
        }
        else
        {
          param+='&isMessageGlobal=true';
        }
      }

      if (searchValues.projectId) {
        param+='&project='+searchValues.projectId;
      }
      if (searchValues.tag) {
        param+='&tags.id='+searchValues.tag;
      }
      if (searchValues.keyWord) {
        param+='&orSearch_text='+searchValues.keyWord;
      }
      if (searchValues.smartNumber) {
        param+='&smartNumber='+searchValues.smartNumber;
      }

      if ((searchValues.type == 'pdf') || (searchValues.type == 'video') || (searchValues.type == 'image') || (searchValues.type == 'excel') || (searchValues.type == 'presentation')){
        param+='&filegeneric.extension='+searchValues.type;
      }
      
      if (searchValues.statut) {
        if (searchValues.statut == 'true') {
          param+='&isMessageAnswered='+searchValues.statut+'&type=QUESTION';
        }
        else
        {
          param+='&isMessageAnswered='+searchValues.statut+'&type=QUESTION&isMessageGlobal=false'; 
        }
      }

      if (searchValues.dateBefore) {
        var strDate = moment(searchValues.dateBefore).format('DD-MM-YYYY');    
        param+='&dateCreated[before]='+ strDate.toLocaleString( );
      }
      if (searchValues.dateAfter) {
        var strDate = moment(searchValues.dateAfter).format('DD-MM-YYYY');    
        param+='&dateCreated[after]='+ strDate.toLocaleString( );
      }

      return this.http.get<any>(this.uriApi + param, httpOptionsG);
    }
    else
    {
      return this.http.get<any>(this.uriApi + '/messages?project.espace=SYNDICAT&page='+(pageIndex+1)+'&perPage='+perPage+'&order[dateCreated]=desc', httpOptionsG);
    }
  }

  getProjectMessages(ID: any) {
    const httpOptionsG = this.httpOptionsR();

    return this.http.get(this.uriApi + '/journal?project=' + ID, httpOptionsG)
      .pipe(map(res => {
        //console.log(res);
        return res['hydra:member'];
      }));
  }


  getContacts(uri?: string) {
    const httpOptionsG = this.httpOptionsR();
     uri = uri ? uri : '/contacts?perPage=100&order[familyName]=ASC';
    return this.http.get(this.uriApi + uri, httpOptionsG)
    .pipe( map ( res => {
      return res;
    }));
  }

  getContactsRh(uri?: string) { 
    const httpOptionsG = this.httpOptionsR();
     uri = uri ? uri : '/contactshr?perPage=100&order[familyName]=ASC';
    return this.http.get(this.uriApi + uri, httpOptionsG)
    .pipe( map ( res => {
      return res;
    }));
  }

  getContactsExp(uri?: string) {
    const httpOptionsG = this.httpOptionsR(); 
    // uri = uri ? uri : '/user-projects?UserWithMessage_id=1';
    uri = uri ? uri : '/expeditors';
    return this.http.get(this.uriApi + uri, httpOptionsG)
    .pipe( map ( res => {
      return res;
    }));
  }

  getContactsRecip(uri?: string) {
    const httpOptionsG = this.httpOptionsR(); 
    // uri = uri ? uri : '/user-projects?UserWithMessage_id=2';
    uri = uri ? uri : '/recipients';
    return this.http.get(this.uriApi + uri, httpOptionsG)
    .pipe( map ( res => {
      return res;
    }));
  }

  getContactsRecipID(ID) {
    const httpOptionsG = this.httpOptionsR();
    /*return this.http.get(this.uriApi + '/recipients?userProject.project=' + ID, httpOptionsG).pipe(map(resp => {
        return resp;
    }));*/

    return this.http.get(this.uriApi + '/user-projects?project='+ID+'&UserWithMessage_id=2', httpOptionsG).pipe(map(resp => {
        return resp;
    }));
   
  }


  getContactsExpID(ID) {
    const httpOptionsG = this.httpOptionsR();
    /*return this.http.get(this.uriApi + '/expeditors?userProject.project=' + ID, httpOptionsG).pipe(map(resp => {
        return resp;
    })); */

    return this.http.get(this.uriApi + '/user-projects?project='+ID+'&UserWithMessage_id=1', httpOptionsG).pipe(map(resp => {
        return resp;
    }));
   
  }

  sendQuestion(
    textMessage: any,
    theTags: any,
    /*newTag: boolean,*/
    theProject: any,
    recipient: any,
    copie: any,
    isPrivate: boolean,
    isCommentActive: boolean,
    deadline: any,
    file: any,
    allMessage: boolean
    ) {
     

        var strDate = null;

        if(deadline){
          deadline = moment(deadline).format('DD-MM-YYYY');
          strDate = deadline.toLocaleString( );
        }

        //console.log(' dans envoi dans un media ');

        let formData:FormData = new FormData();
        formData.append('type', 'QUESTION');
        formData.append('text', textMessage);
        if(strDate){
          formData.append('deadline', strDate);
        }
        formData.append('isPrivate',(isPrivate == true ? 'true' : 'false'));
        formData.append('project',theProject);

        for (var indexFile = 0; indexFile < file.length; indexFile++) {
          formData.append('filegeneric['+indexFile+'][type]','FILE');
          formData.append('filegeneric['+indexFile+'][file]',file[indexFile]);
        }

        for (var indexCopy = 0; indexCopy < copie.length; indexCopy++) {
          if (copie[indexCopy] != 0) {
            formData.append('copy['+indexCopy+'][project]',theProject);
            formData.append('copy['+indexCopy+'][user]','/api/users/'+copie[indexCopy]);
          }
        }
       
        if (allMessage == true) {
          formData.append('isMessageGlobal','true');
          //formData.append('isMessageDisabled','true');
          formData.append('recipient','/api/users/'+JSON.parse(localStorage.getItem('currentUser'))['id']);
        }
        else
        {
          formData.append('recipient','/api/users/'+recipient);
        }
        
        formData.append('tags[0][libelle]',theTags.libelle);
        formData.append('tags[0][project]',theProject);
        if (theTags.id) {
          formData.append('tags[0][tagId]','/api/tags/'+theTags.id);
        }
        
        /*formData.append('tags[0][libelle]',(newTag == false ? theTags.libelle : theTags));
        formData.append('tags[0][project]',theProject);
        formData.append('tags[0][tagId]',(newTag == false ? '/api/tags/'+theTags.id : ''));*/

        let options = {
          headers: new HttpHeaders({
            'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('currentUser'))['token']
          })
        };
 
        return this.http.post(this.uriApi + '/questions', formData, options)
        .pipe(map(res => {
          return res;
        }));

      

  }

  sendAnswer(
    text: any,
    theTags: any,
    isPrivate: boolean,
    project: any,
    question: any,
    file: any,
  ) {

      //console.log(' le tag --> '+ JSON.stringify(theTags));

      let formData:FormData = new FormData();
      formData.append('type', 'ANSWER');
      formData.append('text', text);
      formData.append('isPrivate',(isPrivate == true ? 'true' : 'false'));
      formData.append('project',project);
      
      for (var indexFile = 0; indexFile < file.length; indexFile++) {
        formData.append('filegeneric['+indexFile+'][type]','FILE');
        formData.append('filegeneric['+indexFile+'][file]',file[indexFile]);
      }

      formData.append('tags[0][libelle]',theTags.libelle);
      formData.append('tags[0][project]',project);
      formData.append('tags[0][tagId]',theTags.id);

      formData.append('question','/api/messages/' + question);

      let options = {
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('currentUser'))['token']
        })
      };

      return this.http.post(this.uriApi + '/answers', formData, options)
      .pipe(map(res => {
        return res;
      }));
 
  }

  sendComment(
    text: any,
    theTags: any,
    isPrivate: boolean,
    project: any,
    question: any,
    file: any,
  ) {
    
 
      let formData:FormData = new FormData();
      formData.append('type', 'COMMENT');
      formData.append('text', text);
      formData.append('isPrivate',(isPrivate == true ? 'true' : 'false'));
      formData.append('project',project);
      
      for (var indexFile = 0; indexFile < file.length; indexFile++) {
        formData.append('filegeneric['+indexFile+'][type]','FILE');
        formData.append('filegeneric['+indexFile+'][file]',file[indexFile]);
      }

      formData.append('tags[0][libelle]',theTags.libelle);
      formData.append('tags[0][project]',project);
      formData.append('tags[0][tagId]',theTags.id);

      formData.append('parentItem','/api/messages/' + question);
      
      let options = {
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('currentUser'))['token']
        })
      };

      return this.http.post(this.uriApi + '/comments', formData, options)
      .pipe(map(res => {
        return res;
      }));

    
  }


  saveProfile(
    image: string,
    email: string,
    gender: string,
    givenName: string,
    familyName: string,
    birthDate: string,
    jobTitle: string,
    description: string,
    telephone: string,
    country: string,
    plainPassword: string
  ) {
    return this.http.post(this.uriApi + '/users', {
      image: image,
      email: email,
      gender: gender,
      givenName: givenName,
      familyName: familyName,
      birthDate: birthDate,
      jobTitle: jobTitle,
      description: description,
      telephone: telephone,
      country: country,
      plainPassword: plainPassword
    })
    .pipe(map(resp => {
       return resp;
    }));

  }

  getNotification() {
    const httpOptionsG = this.httpOptionsR();
    return this.http.get(this.uriApi + '/notifications', httpOptionsG)
      .pipe(map(res => {
        return res;
      }));
  }

  getProjectNotification(notifMode, pageIndex: any, perPage: any) {
   const httpOptionsG = this.httpOptionsR();
   if (notifMode === 0) {
     return this.http.get<any>(this.uriApi+'/messages?type=QUESTION&author='+JSON.parse(localStorage.getItem('currentUser'))['id']+'&isMessageAnswered=false&isMessageDisabled=false&page='+(pageIndex+1)+'&perPage='+perPage+'&order[motionAt]=desc', httpOptionsG);
   }else if (notifMode === 1) {
     return this.http.get<any>(this.uriApi+'/messages?type=QUESTION&recipient='+JSON.parse(localStorage.getItem('currentUser'))['id']+'&isMessageDisabled=false&isMessageAnswered=false&page='+(pageIndex+1)+'&perPage='+perPage+'&order[motionAt]=desc', httpOptionsG);
   }
  else if (notifMode === 2) {
     return this.http.get<any>(this.uriApi+'/messages?copy.user='+JSON.parse(localStorage.getItem('currentUser'))['id']+'&isMessageDisabled=false&copy.isRead=false&page='+(pageIndex+1)+'&perPage='+perPage+'&order[motionAt]=desc', httpOptionsG);
   }
   else{
   // return this.http.get<any>(this.uriApi+'/messages?type=ANSWER&recipient='+JSON.parse(localStorage.getItem('currentUser'))['id']+'&isMessageDisabled=false&isMessageAnswered=false&isRecipientRead=false&page='+(pageIndex+1)+'&perPage='+perPage+'&order[motionAt]=desc', httpOptionsG);
    return this.http.get<any>(this.uriApi+'/messages?type=COMMENT&copy.user='+JSON.parse(localStorage.getItem('currentUser'))['id']+'&isMessageDisabled=false&copy.isRead=false&page='+(pageIndex+1)+'&perPage='+perPage+'&order[motionAt]=desc', httpOptionsG);
   }
 } 

  createProject(libelle, description, deadline, tags, userProject) {
    
    const httpOptionsG = this.httpOptionsR();

    if (deadline) {
      var strDate = moment(deadline).format('DD-MM-YYYY');    
      var dateString = strDate.toLocaleString( );
    }

    return this.http.post(this.uriApi + '/projects',{
      "libelle": libelle,
      "description": description,
      "deadline": strDate,
      "tags": tags,
      "userProject": userProject
    }, httpOptionsG)
    .pipe(map(res => {
      return res;
    }));
  
  }

  projectImage(projectId, newImage) {


    //console.log('newImage =>'+ JSON.stringify(newImage))

    let formData:FormData = new FormData();
    formData.append('imageFile', newImage);
    
    let options = {
      headers: new HttpHeaders({
        //'Content-Type': "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
        'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('currentUser'))['token']
      })
    };

    return this.http.post(this.uriApi + '/projects/'+projectId+'/image', formData, options).pipe(map(res => {
      return res;
    }));

  }

  addUserProject(project, userProject, email, typeProfil) {
    const httpOptionsG = this.httpOptionsR();

    if (typeProfil === 1 ) {
      if(email != null) 
      {
        return this.http.post(this.uriApi + '/user-projects',{
          "email": userProject,
          "profil": "HR",
          "isAdmin": true,
          "project": project
        }, httpOptionsG)
        .pipe(map(res => {
          return res;
        }));
      }
      else
      {
        return this.http.post(this.uriApi + '/user-projects',{
          "user": '/api/users/'+userProject,
          "profil": "HR",
          "isAdmin": true,
          "project": project
        }, httpOptionsG)
        .pipe(map(res => {
          return res;
        }));
      }
    }
    else if (typeProfil === 2) {
       if(email != null) 
      {
        return this.http.post(this.uriApi + '/user-projects',{
          "email": userProject,
          "profil": "CS",
          "isAdmin": false,
          "project": project
        }, httpOptionsG)
        .pipe(map(res => {
          return res;
        }));
      }
      else
      {
        return this.http.post(this.uriApi + '/user-projects',{
          "user": '/api/users/'+userProject,
          "profil": "CS",
          "isAdmin": false,
          "project": project
        }, httpOptionsG)
        .pipe(map(res => {
          return res;
        }));
      }
    }
  }


  getFiles(ID: any, pageIndex: any, perPage: any, specMsg: any, searchValues: any, salaryOnly: any) {
    const httpOptionsG = this.httpOptionsR();
    
    //console.log(' searchValues me voila!!!'+JSON.stringify(searchValues));
    
    var param = '/filegenerics?page='+(pageIndex+1)+'&perPage='+perPage+'&order[createdAt]=desc';
    
    if(ID) param += '&message.project='+ID;

    if (searchValues.transmitter) {
      param+='&message.author='+searchValues.transmitter;
    }
    if (searchValues.recipient) {
      param+='&message.recipient='+searchValues.recipient;
    }
    if (searchValues.tag) {
      param+='&message.tags.id='+searchValues.tag;
    }
    if (searchValues.keyWord) {
      param+='&message.text='+searchValues.keyWord;
    }
    if (searchValues.smartNumber) {
      param+='&message.smartNumber='+searchValues.smartNumber;
    }
    if (searchValues.dateBefore) {
      var strDate = moment(searchValues.dateBefore).format('DD-MM-YYYY');    
      param+='&message.dateCreated[before]='+ strDate.toLocaleString( );
    }
    if (searchValues.dateAfter) {
      var strDate = moment(searchValues.dateAfter).format('DD-MM-YYYY');    
      param+='&message.dateCreated[after]='+ strDate.toLocaleString( );
    }
    if(salaryOnly){
     param+='&exists[project.typeProject]=false';
    }

    if ((searchValues.type == 'pdf') || (searchValues.type == 'video') || (searchValues.type == 'image') || (searchValues.type == 'excel') || (searchValues.type == 'presentation')){
      param+='&extension='+searchValues.type;
    }

    return this.http.get<any>(this.uriApi + param, httpOptionsG);
  }



  getProjectHisMessages(ID: any, pageIndex: any, perPage: any, specMsg: any, specMsgType: any, searchValues: any) {
    const httpOptionsG = this.httpOptionsR();
    if(specMsg)
    {
      if(specMsgType && specMsgType == 'COMMENT')
      {
         return this.http.get<any>(this.uriApi +'/journal?project='+ID+'&page='+(pageIndex+1)+'&perPage='+perPage+'&order[motionAt]=desc&orSearch_number='+specMsg, httpOptionsG);
      }
      else
      {
        return this.http.get<any>(this.uriApi +'/journal?project='+ID+'&page='+(pageIndex+1)+'&perPage='+perPage+'&order[motionAt]=desc&smartNumber='+specMsg, httpOptionsG);
      }
    }
    else if(searchValues)
    {
      
      //console.log(' searchValues me voila!!!'+JSON.stringify(searchValues));

      var param = '/journal?project='+ID+'&page='+(pageIndex+1)+'&perPage='+perPage+'&order[motionAt]=desc';

      if (searchValues.transmitter) {
        param+='&author='+searchValues.transmitter;
      }
      if (searchValues.recipient ) {
        if (searchValues.recipient != -1) {
          param+='&recipient='+searchValues.recipient;
        }
        else
        {
          param+='&isMessageGlobal=true';
        }
      }
      if (searchValues.tag) {
        param+='&tags.id='+searchValues.tag;
      }
      if (searchValues.keyWord) {
        param+='&orSearch_text='+searchValues.keyWord;
      }
      if (searchValues.smartNumber) {
        param+='&smartNumber='+searchValues.smartNumber;
      }
      
      if ((searchValues.type == 'pdf') || (searchValues.type == 'video') || (searchValues.type == 'image') || (searchValues.type == 'excel') || (searchValues.type == 'presentation')){
        param+='&filegeneric.extension='+searchValues.type;
      }
      
      if (searchValues.statut) {
        param+='&isMessageAnswered='+searchValues.statut;
      }

      if (searchValues.dateBefore) {
        var strDate = moment(searchValues.dateBefore).format('DD-MM-YYYY');    
        param+='&dateCreated[before]='+ strDate.toLocaleString( );
      }
      if (searchValues.dateAfter) {
        var strDate = moment(searchValues.dateAfter).format('DD-MM-YYYY');    
        param+='&dateCreated[after]='+ strDate.toLocaleString( );
      }

      return this.http.get<any>(this.uriApi + param, httpOptionsG);

    }
    else
    {
      return this.http.get<any>(this.uriApi +'/journal?project='+ID+'&page='+(pageIndex+1)+'&perPage='+perPage+'&order[motionAt]=desc', httpOptionsG);
    }

  }

  getMediaData(mediaUrl: any) {
     return this.uriMediaFullSize+mediaUrl;
  }

  getMediaThumbail(mediaUrl: any) {
     return this.uriMediaThumbail+mediaUrl;
  }

  getUserInvitation(pageIndex: any, perPage: any){
     const httpOptionsG = this.httpOptionsR();
     return this.http.get<any>(this.uriApi + '/user-projects?status=PENDING&order[createdAt]=desc&page='+(pageIndex+1)+'&perPage='+perPage, httpOptionsG);
    /*return this.http.get(this.uriApi + '/user-projects?status=PENDING&order[createdAt]=desc', httpOptionsG)
    .pipe( map ( res => {
      return res;
    }));*/
  }

  accepterProjet(idInvitation){
    const httpOptionsG = this.httpOptionsR();
    return this.http.put(this.uriApi + '/user-projects/'+idInvitation,{
      "status": "ACCEPTED"
    }, httpOptionsG).pipe( map ( res => {
      return res;
    }));
  }

  declineProjet(idInvitation){
    const httpOptionsG = this.httpOptionsR();
    return this.http.put(this.uriApi + '/user-projects/'+idInvitation,{
      "status": "REFUSED"
    }, httpOptionsG).pipe( map ( res => {
      return res;
    }));
  }


  updateUserProject(item, values){
    const httpOptionsG = this.httpOptionsR();
    return this.http.put(this.uriApi + item['@id'].replace('/api', ''), values, httpOptionsG).pipe( map ( res => {
      return res;
    }));
  }

  deleteUserProject(item){
    const httpOptionsG = this.httpOptionsR();
    return this.http.delete(this.uriApi + item['@id'].replace('/api', ''), httpOptionsG).pipe( map ( res => {
      return res;
    }));
  }

  markAsRead(messageInfo){
    const httpOptionsG = this.httpOptionsR(); 
    return this.http.put(this.uriApi + '/messages/' + messageInfo.id,{
      "isRecipientRead": true
    }, httpOptionsG).pipe(map(res => {
      return res;
    }));
  }



  markAsReadCopy(idCopy) {
    const httpOptionsG = this.httpOptionsR();
    return this.http.put(this.uriApi + '/copies/' + idCopy,{
      "isRead": true
    }, httpOptionsG).pipe(map(res => {
      return res;
    }));
  }

  getInfoContact(idContact) {
    const httpOptionsG = this.httpOptionsR();
    return this.http.get(this.uriApi + '/users/'+ idContact, httpOptionsG)
    .pipe(map(resp => {
      return resp;
    })); 
  }

  udapteUser(givenName, familyName, birthDate, jobTitle, newemail, gender, description, telephone, oldPassword, plainPassword, mobilePro, mobilePerso) {
    const httpOptionsG = this.httpOptionsR();
    return this.http.put(this.uriApi + '/users/'+JSON.parse(localStorage.getItem('currentUser'))['id'],{
      "givenName": givenName,
      "familyName": familyName,
      "birthDate": birthDate,
      "jobTitle": jobTitle,
      "newemail": newemail,
      "gender": gender,
      "description": description,
      "telephone": telephone,
      "oldPassword": oldPassword,
      "plainPassword": plainPassword,
      "mobilePro": mobilePro,
      "mobilePerso": mobilePerso
    }, httpOptionsG).pipe( map ( res => {
      if (res) {
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this.currentUser.givenName = res['givenName'];
        this.currentUser.familyName = res['familyName'];
        this.currentUser.email = res['email'];
        this.currentUser.gender = res['gender'];
        this.currentUser.jobTitle = res['jobTitle'];
        this.currentUser.birthDate = res['birthDate'];
        this.currentUser.description = res['description'];
        this.currentUser.telephone = res['telephone'];
        this.currentUser.mobilePro = res['mobilePro'];
        this.currentUser.mobilePerso = res['mobilePerso'];
        localStorage.setItem('currentUser', JSON.stringify(this.currentUser));
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
      }
      return res;
    }));
  }


  udapteUserImage(imageFile) {
  
    let formData:FormData = new FormData();
    formData.append('imageFile', imageFile);  

    let options = {
      headers: new HttpHeaders({
        //'Content-Type': "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
        'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('currentUser'))['token']
      })
    };

    return this.http.post(this.uriApi +'/users/'+JSON.parse(localStorage.getItem('currentUser'))['id']+'/profil', formData, options)
    .pipe(map(res => {
       if (res) {
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this.currentUser.imageUrl = res['imageUrl'];
        localStorage.setItem('currentUser', JSON.stringify(this.currentUser));
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
      }
      return res;
    }));


  }

  sendQuestionFormContact(
    givenName: any,
    lastName: any,
    email: any,
    messageToSend: any,
  ) {
 
      const httpOptionsG = this.httpOptionsR();
   
      return this.http.post(this.uriApi + '/pub/contactus',{
        "givenName": givenName,
        "lastName": lastName,
        "email": email,
        "message": messageToSend,
        "objet":''
      }, httpOptionsG)
      .pipe(map(res => {
        return res;
      }));

  }

  transfertByMail(
    customerData: any,
    messageData: any
  ) {
 
      const httpOptionsG = this.httpOptionsR();
  
      return this.http.post(this.uriApi + '/messages/'+messageData.id+'/transfert',{
        "recipient": customerData.Destinataire,
        "copies": customerData.EnCopie,
        "message": customerData.message,
      }, httpOptionsG)
      .pipe(map(res => {
        return res;
      })); 
    
  }

  disableMessage(
    messageData: any
  ) {
 
    const httpOptionsG = this.httpOptionsR();

    return this.http.post(this.uriApi + '/messages/'+messageData.id+'/disable',{}, httpOptionsG)
    .pipe(map(res => {
      return res;
    })); 
    
  }

  recalls(
    messageData: any
  ) {
 
    const httpOptionsG = this.httpOptionsR();

    return this.http.post(this.uriApi + '/recalls',{
      "message":messageData['@id'] 
    }, httpOptionsG)
    .pipe(map(res => {
      return res;
    }));  
    
  }
 
  getLastFaceNumber(
    idProject: any
  ) {
      
    const httpOptionsG = this.httpOptionsR(); 
    return this.http.get(this.uriApi + '/messages?project='+idProject+'&order[smartNumber]=DESC&perPage=1', httpOptionsG)
    .pipe(map(resp => {
      return resp;
    })); 

  }

  getAnswerListe() {
    const httpOptionsG = this.httpOptionsR(); 
    return this.http.get(this.uriApi + '/quickanswers?perPage=20', httpOptionsG)
    .pipe(map(resp => {
      return resp;
    })); 
  }

  sendQuickAnswer(
    text: any,
    theTags: any,
    isPrivate: boolean,
    project: any,
    question: any
  ) {

      //console.log(' le tag --> '+ JSON.stringify(theTags));
      let formData:FormData = new FormData();
      formData.append('type', 'ANSWER');
      formData.append('text', text);
      formData.append('isPrivate',(isPrivate == true ? 'true' : 'false'));
      formData.append('project',project);
      formData.append('isQuickanswer','true');
      formData.append('tags[0][libelle]',theTags.libelle);
      formData.append('tags[0][project]',project);
      formData.append('tags[0][tagId]',theTags.id);
      formData.append('question','/api/messages/' + question);

      let options = {
        headers: new HttpHeaders({
          'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('currentUser'))['token']
        })
      };

      return this.http.post(this.uriApi + '/answers', formData, options)
      .pipe(map(res => {
        return res;
      }));
 
  }


  changeProjetName(libelle, projectId){
    const httpOptionsG = this.httpOptionsR();
    return this.http.put(this.uriApi + '/projects/'+projectId,{
      "libelle": libelle
    }, httpOptionsG).pipe( map ( res => {
      return res;
    }));
  }

  changeSubjectName(libelle, oldlibelle, tagsId){
   const httpOptionsG = this.httpOptionsR();
    /*return this.http.put(this.uriApi + '/tags/'+tagsId,{
      "libelle": libelle
    }, httpOptionsG).pipe( map ( res => {
      return res;
    }));*/
    return this.http.put(this.uriApi + '/topics/'+tagsId,{ 
      "name": libelle,
      "oldName":oldlibelle
    }, httpOptionsG).pipe( map ( res => {
      return res;
    }));
  }

  deleteProject(projectId){
    const httpOptionsG = this.httpOptionsR();
    return this.http.delete(this.uriApi + '/projects/'+projectId, httpOptionsG)
    .pipe( map ( res => {
      return res;
    }));
  }


  addNewSubject(libelle, project) {
    if(project == null) return;
    const httpOptionsG = this.httpOptionsR();
    return this.http.post(this.uriApi + '/tags',{
      "libelle": libelle,
      "project": "/api/projects/"+project
    }, httpOptionsG)
    .pipe(map(res => {
      return res;
    }));
  }

  getStat() {   
    const httpOptionsG = this.httpOptionsR(); 
    return this.http.get(this.uriApi + '/stats', httpOptionsG).pipe(map(resp => {
      return resp;
    })); 
  }

  getUserInfo(userEmail) { 
    var resEmail = userEmail.toLowerCase();
    const httpOptionsG = this.httpOptionsR(); 
    return this.http.get(this.uriApi + '/users?email='+resEmail, httpOptionsG).pipe(map(resp => {
      return resp;
    })); 
  }
  
  contactMessage(prenom, nom, email, companyName, jobTitle, message, telephone, objet) {  
    const httpOptionsG = this.httpOptionsR();
    return this.http.post(this.uriApi + '/pub/contactus?type="CONTACT"&object='+objet+'&phone='+telephone+'&givenName='+prenom+'&lastName='+nom+'&email='+email+'&companyName='+companyName+'&jobTitle='+jobTitle+'&message='+message, httpOptionsG)
      .pipe(map(res => {
        return res;
      }));
  }

  contactMessagePartenaire(prenom, nom, email, companyName, jobTitle, message, telephone, objet) {  
    const httpOptionsG = this.httpOptionsR();
    return this.http.post(this.uriApi + '/pub/contactus?type="PARTENAIRE"&object='+objet+'&phone='+telephone+'&givenName='+prenom+'&lastName='+nom+'&email='+email+'&companyName='+companyName+'&jobTitle='+jobTitle+'&message='+message, httpOptionsG)
      .pipe(map(res => {
        return res;
      }));
  }

  addMultipEmploye(file) {

    const httpOptionsG = this.httpOptionsR();

    let formData:FormData = new FormData();
    formData.append('file', file);
    
    let options = {
      headers: new HttpHeaders({
        //'Content-Type': "multipart/form-data; charset=utf-8; boundary=" + Math.random().toString().substr(2),
        'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('currentUser'))['token']
      })
    };

    return this.http.post(this.uriApi + '/users/import', formData, options).pipe(map(res => {
      return res;
    }));

  } 

  /*ajoutSousTag(libelle, projectId, tagsId){
    if(projectId == null) return;
    const httpOptionsG = this.httpOptionsR();
    return this.http.post(this.uriApi + '/tags',{
      "libelle": libelle,
      "project": "/api/projects/"+projectId,
      "tag":  "/api/tags/"+tagsId
    }, httpOptionsG).pipe( map ( res => {
      return res;
    }));
  }*/

  ajoutSousTag(libelle, code){
    const httpOptionsG = this.httpOptionsR();
    return this.http.post(this.uriApi + '/topics',{
      "name": libelle,
      "code": code
    }, httpOptionsG).pipe( map ( res => {
      return res;
    }));
  }

  deleteSousTag(tagsId){
    const httpOptionsG = this.httpOptionsR();
    return this.http.delete(this.uriApi + '/tags/'+tagsId, httpOptionsG)
    .pipe( map ( res => {
      return res;
    }));
  }

  addGeneralContact(customeJson){
    const httpOptionsG = this.httpOptionsR();https: 
    return this.http.post(this.uriApi + '/configurations',{
      "propertyID": "INVITE_PROJECT_RH",
      "value": customeJson.email,
      "value2": customeJson.lname, 
      "value3": customeJson.fname,
      "value4": customeJson.jobT
    }, httpOptionsG).pipe( map ( res => {
      return res;
    }));

  }

  getContactWaiting() {   
    const httpOptionsG = this.httpOptionsR(); 
    return this.http.get(this.uriApi + '/invits', httpOptionsG).pipe(map(resp => {
      return resp;
    })); 
  }

  udapteSAlary(idSalary, givenName, familyName, birthDate, jobTitle, newemail, gender, description, telephone, mobilePro, mobilePerso) {
    const httpOptionsG = this.httpOptionsR();
    return this.http.put(this.uriApi + '/users/'+idSalary,{
      "givenName": givenName,
      "familyName": familyName,
      "birthDate": birthDate,
      "jobTitle": jobTitle,
      "newemail": newemail,
      "gender": gender,
      "description": description,
      "telephone": telephone,
      "mobilePro": mobilePro,
      "mobilePerso": mobilePerso
    }, httpOptionsG).pipe( map ( res => {
      return res;
    }));
  }
  

  udapteContactSkill(idSalary, skills) {
    const httpOptionsG = this.httpOptionsR();
    return this.http.put(this.uriApi + '/users/'+idSalary,{
      "skills": skills
    }, httpOptionsG).pipe( map ( res => {
      return res;
    }));
  }

  registerCompagny(infoData){

    const httpOptionsG = this.httpOptionsR(); 
    return this.http.post(this.uriApi + '/pub/companies',infoData, httpOptionsG).pipe( map ( res => {
      return res;
    }));
  
  }

  getUserSkill(idUser){
    const httpOptionsG = this.httpOptionsR(); 
    return this.http.get(this.uriApi + '/users/'+ idUser, httpOptionsG).pipe(map(resp => {
      return resp;
    })); 
  }
 
  deleleteRRHInvitation(idUser){
   const httpOptionsG = this.httpOptionsR();
    return this.http.delete(this.uriApi + '/configurations/'+idUser, httpOptionsG)
    .pipe( map ( res => {
      return res;
    })); 
  }
 
  companyGender(){
    const httpOptionsG = this.httpOptionsR(); 
    return this.http.get(this.uriApi + '/company-gender', httpOptionsG).pipe(map(resp => {
      return resp;
    })); 
  }
 
  designRRH(idSalary) {
    const httpOptionsG = this.httpOptionsR();
    return this.http.put(this.uriApi + '/users-rrh/'+idSalary,{}, httpOptionsG).pipe( map ( res => {
      return res;
    }));
  }

  removeRRH(idSalary) {
    const httpOptionsG = this.httpOptionsR();
    return this.http.put(this.uriApi + '/users-removerrh/'+idSalary,{}, httpOptionsG).pipe( map ( res => {
      return res;
    }));
  }

  getTopics(uri?: string) {
    const httpOptionsG = this.httpOptionsR();
    uri = uri ? uri : '/topics?perPage=300';
    return this.http.get(this.uriApi + uri, httpOptionsG)
    .pipe( map ( res => {
      return res;
    }));
  }

  getPublicTopics(connected, uri?: string) {
    const httpOptionsG = this.httpOptionsR();
    let theUrl = connected ? '/topics?perPage=300' : '/pub/topics?perPage=300';
    uri = uri ? uri : theUrl;
    return this.http.get(this.uriApi + uri, httpOptionsG)
    .pipe( map ( res => {
      return res;
    }));
  }

  addmanagement(ID_DU_MANAGe, ID_DU_MANAGER){   
    const httpOptionsG = this.httpOptionsR();    
    return this.http.post(this.uriApi + '/users/'+ID_DU_MANAGe+'/manager?manager='+ID_DU_MANAGER,{}, httpOptionsG)
      .pipe(map(res => {
        return res;
    }));
  }

  removemanagement(ID_DU_MANAGe){   
    const httpOptionsG = this.httpOptionsR();    
    return this.http.post(this.uriApi + '/users/'+ID_DU_MANAGe+'/manager?manager=null',{}, httpOptionsG)
      .pipe(map(res => {
        return res;
    }));
  }

  getExpFormSujetID(ID,subjectID) {
    const httpOptionsG = this.httpOptionsR();
    return this.http.get(this.uriApi + '/user-projects?project='+ID+'&UserWithMessage_id=1_'+subjectID, httpOptionsG).pipe(map(resp => {
        return resp;
    }));
  }

  getDestFormSujetID(ID,subjectID) {
    const httpOptionsG = this.httpOptionsR();
    return this.http.get(this.uriApi + '/user-projects?project='+ID+'&UserWithMessage_id=2_'+subjectID, httpOptionsG).pipe(map(resp => {
        return resp;
    }));
  }

}

