import { Component, OnInit } from '@angular/core';
import { MessageService } from 'src/app/services/message/message.service';

@Component({
  selector: 'app-un-reg-menu',
  templateUrl: './un-reg-menu.component.html',
  styleUrls: ['./un-reg-menu.component.scss']
})
export class UnRegMenuComponent implements OnInit {

  constructor( private messageService: MessageService) { }

  ngOnInit() {
  }

}
