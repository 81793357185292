import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-menu-guide',
  templateUrl: './menu-guide.component.html',
  styleUrls: ['./menu-guide.component.scss']
})
export class MenuGuideComponent implements OnInit {
  menuTuto = false;
  salaried = false;
  menuSecu = false;
  menuTheme = false;
  menuCollab = false;
  menuCGV  = false;
  menuIRP  = false;
  menuSyndicat = false;
  menuCHO = false;
  menuManager = false;


  menuGuide = false;
  menuInteraction = false; 

  constructor() { }

  ngOnInit() {
  	this.menuGuide = true;
  }

}
