import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from 'src/app/services/auth-service/auth.service';
import { Router } from '@angular/router';
import { first } from 'rxjs/internal/operators/first';
import { MessageService } from 'src/app/services/message/message.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  fname = '';
  lname = '';
  jobT = '';
  email = '';
  passwd = '';
  passwd_confirm = '';
  gender = 'male';
  loading = false;
  error = '';
  message = '';
  pconerror = '';
  values = '';
  hide = true;
  mdpValues = '';

  constructor(
    private authService: AuthService,
    private messageService: MessageService,
    private router: Router) { 

      router.events.subscribe((val) => {
        //console.log("out", location.href);
        if(location.href.includes('email=')){
            let email = location.hash.replace('#/register?email=', '');
            this.email = email;
        }
      });
  }

  ngOnInit() {
    if (this.authService.logIn) {
      this.router.navigate(['/']);
    }
  }

  onKey(event: any) { // without type info
    this.mdpValues += event.target.value + ' | ';
  } 

  registerNu() {

    if (this.passwd.trim() != this.passwd_confirm.trim() ) {
      alert("Votre mot de passe est différent de celui entré dans l'espace de confirmation");
      return;
    }

    this.loading = true;
    //console.log('Registering in ' + this.fname + ' ' + this.lname + ' ' + this.jobT + ' ' + this.email + ' ' + this.passwd);

    this.authService.register(this.fname, this.lname, this.jobT, this.email, this.passwd, this.gender)
      .pipe(first())
      .subscribe(
        data => {
          // this.router.navigate(['/']);
          //console.log(data);
          this.message = "Inscription terminée!";
          this.Login();
        },
        err => {
          const error = err.error;
          const errorS = error.message;
          const errorD = error.detail;
          const errorH = error['hydra:description'];
          if (errorS === 'Bad credentials') {
            this.error = 'email / mot de passe incorrect';
          } else if (errorD === "L'utilisateur n'est pas encore activé. pour l'activer, vous devez cliquer sur le lien de confirmation du mail que vous avez reçu") {
            this.error = errorD;
          } else if (errorH) {
            this.error = errorH;
          }
          this.loading = false;
        }
      );
  }

  Login() {
    
    this.authService.login( this.email, this.passwd)
    .pipe( first() )
    .subscribe(
      data => {
        this.router.navigate(['/']);  
      },
      err => {
        const error = err.error;
        const errorS = error.message;
        const errorD = error.detail;
        if (!errorD ) {
          this.error = 'email / mot de passe incorrect';
        } else if (!errorS) {
          this.error = errorD;
        }
      
      }
    );
  }


}
