import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth-service/auth.service';
import { MessageService } from 'src/app/services/message/message.service';
import { environment } from 'src/environments/environment';
import { FormBuilder, FormGroup, Validators, FormControl, FormGroupDirective, NgForm} from '@angular/forms';
import {ErrorStateMatcher} from '@angular/material/core';
import { DatePipe } from '@angular/common'
import * as introJs from 'intro.js';
import { Subject } from 'rxjs';
import { CoreServiceService } from '../services/core/core-service.service';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';

export interface Contacts {
  '@id': string;
  '@type': string;
  familyName: string;
  fullName: string;
  gender: string;
  givenName: string;
  id: string;
  image: string;
  imageUrl: string;
  jobTitle: string;
  position: number;
}

/* Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-general-setting',
  templateUrl: './general-setting.component.html',
  styleUrls: ['./general-setting.component.scss']
})
export class GeneralSettingComponent implements OnInit, OnDestroy {

  menuTheme = false;
  menuCollab = false;
  menuTuto = false;
  menuGuide = false;
  menuCGV = false;
  menuSecu  = false;
  menuIRP = false;
  menuCHO = false;
  menuSyndicat = false;
  salaried  = false;
  menuInteraction = false;
  menuManager = false ;

  /* ------ VARIABLE PARTIE THEME ------ */

  currentUser: any;
  isAdmin = false;
  isDRH = false;
  idProjectAll = null;
  projectTagWithNoMessage = [];
  projectAllSalary: any;

  projectTag = [];
  payeTag = [];
  socialeTag = [];
  informationTag = [];
  choTag = [];
  managerTag = [];
  cseTag = [];
  syndicatsTag = [];


  renameSubjectPaie = '';
  renameSubjectSociale = '';
  renameSubjectInfo = '';
  renameSubjectCHO = '';
  renameSubjectManager = '';
  renameSubjectCse = '';
  renameSubjectSyndicat = '';

  newSubjectInfo = '';
  newSubjectPaye = '';
  newSubjectSocial = '';
  newSubjectCHO = '';
  newSubjectManager = '';
  newSubjectCse = '';
  newSubjectSyndicat = '';

  editeSubjectModePaie = false;
  editeSubjectModeInfo = false;
  editeSubjectModeSociale = false;
  editeSubjectModeCHO = false;
  editeSubjectModeManager = false;
  editeSubjectModeCse = false;
  editeSubjectModeSyndicat = false;

  addSubjectModeInfo = false;
  addSubjectModePaye = false;
  addSubjectModeSociale = false;
  addSubjectModeCHO = false;
  addSubjectModeManager = false;
  addSubjectModeCse = false;
  addSubjectModeSyndicat = false;

  tagInfoPaie: any;
  tagInfoSociale: any;
  tagInfoInformation: any;
  tagInfoCHO: any;
  tagInfoManager: any;
  tagInfoCse: any;
  tagInfoSyndicat: any;


  modeDeletePaye = false;
  modeDeleteSociale = false;
  modeDeleteInfo = false;
  modeDeleteCho = false;
  modeDeleteManager = false;
  modeDeleteCse = false;
  modeDeleteSyndicat = false;

  /* ------ VARIABLE PARTIE CONTACT ------ */

    contacts = [];
    showNewContactInput = false;
    searchContact: any;

    emailFormControl: FormGroup;
    matcher = new MyErrorStateMatcher();
    addingMember = false;
    onSubmit = false;


  /* ------ VARIABLE PARTIE salarié ------ */

  salaryLists: Array<any> = [];

  imageFil = null;

  noChangeImage = true;

  edit = false;
  fname = '';
  lname = '';
  dob = '';
  jobT = '';
  email = '';
  gender = 'male';
  description = '';
  profilUser = '';
  phone = '';
  mobilePro = '';
  mobilePerso = '';
  img: any;
  idUser = null;
  contactInfo: any;
  mdpValues = '';
  projectData = null;
  searchProject : any;
  openEmail = false;
  showProfilMode = false;

  /* ------ VARIABLE PARTIE GUIDE------ */
  introJS = introJs();
  protected _onDestroy = new Subject<void>();

  /* ------ VARIABLE PARTIE CONTACT IRP, CHO et SYNDICAT------ */

    /*contactsIRP: Array<Contacts> = [];
    contactsCSE: Array<Contacts> = [];
    contactsCHO: Array<Contacts> = [];
    contactsSave: Array<Contacts> = [];*/

    contactsIRP = [];
    contactsCSE = [];
    contactsCHO = [];
    contactsSave = [];
    searchContactCSE: any;
    searchContactSyndicat: any;
    searchContactCHO: any;



  /* ------ VARIABLE PARTIE MANAGER ------ */

    searchContactManager: any;
    managerArray = [];
    ArrayContactsToManaged = [];
    contactSalaryLength = 0;


  /* ------ FCT MAIN ------ */

  constructor(private authService: AuthService,
     private messageService: MessageService,
      public datepipe: DatePipe,
      private router: Router,
      private coreService: CoreServiceService,
      private formBuilder: FormBuilder) {

    this.emailFormControl = this.formBuilder.group({
      fname: ['', Validators.required],
      lname: ['', Validators.required],
      jobT: ['', Validators.required],
      email: ['', Validators.required]
    });


  }

  ngOnDestroy(): void {
    this.introJS.exit();
  }

  startDemo() {
    if(this.router.url === "/generalSetting"){
    this.introJS.setOptions({
        steps: [
          {
            intro: 'Welcome! 👋'
          },
          {
            element: '#step101',
            intro: 'Cette éspace vous permettez de configurer votre app!',
            position: 'auto'
          },
          {
            element: '#step103',
            intro: 'Cliquez ici pour voir tous les responsables RH!',
            position: 'auto'
          },
          {
            element: '#step104',
            intro: 'Cliquez ici pour voir tous les responsables CSE!',
            position: 'auto'
          },
          {
            element: '#step105',
            intro: 'Cliquez ici pour voir tous les responsables CHO!',
            position: 'auto'
          },
          {
            element: '#step106',
            intro: 'Cliquez ici pour voir tous les Managers!',
            position: 'auto'
          },
          {
            element: '#step107',
            intro: 'Cliquez ici pour voir tous les Syndicats!',
            position: 'auto'
          }
        ],
        showProgress: true,
        hideNext: true,
        hidePrev: true,
        showStepNumbers: true,
        keyboardNavigation: true,
        showButtons:  true,
        scrollToElement: true,
        showBullets:  true
      }
    ).start();}
  }


  ngOnInit() {


    this.coreService.startDemo
    .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.startDemo();
      });

    this.onSubmit = false;

    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));

    if(this.currentUser.profil == 'HRD' || this.currentUser.profil == 'HR')
    {
      this.isAdmin = true;
      //console.log(" le user est admin");
    }

    if(this.currentUser.profil == 'HRD')
    {
      this.isDRH = true;
      //console.log(" le user est admin");
    }

    this.salaried = true;
    this.showProfilMode = false;
    this.contacts = [];
    this.salaryLists = [];
    this.contactsIRP = [];
    this.contactsCSE = [];
    this.contactsCHO = [];
    this.contactsSave = [];
    this.managerArray = [];
    this.ArrayContactsToManaged = [];
    this.contactSalaryLength = 0;
    //this.loadProject();
    this.iniTableSujet();
    this.loadContact();
    this.loadSalarie();
    this.loadContactIRP();
    this.loadContactSave();
    this.loadContactCHO();
    this.loadContactCSE();
    this.getContactSalary();

  }

   /* --------------------- */

   /* ------ FCT PARTIE THEME ------ */

  /*initDataTableTsujet(idProjectAllSalary){

    this.authService.getTheProject(idProjectAllSalary)
      .subscribe(res => {

        this.projectAllSalary = res;
        this.projectTag = this.projectAllSalary['tags'];
        this.projectTag.sort(this.compare);
        this.iniTableSujet();
        this.getSujetWithNoMessage(this.projectAllSalary.id);

      }, (error) => {
         //console.log(' getTheProject subscribe error '+error.statusText);
    });

  }*/

  /*loadProject(url?: string){
    url = url ? url : undefined;
    this.authService.getProjects(url).subscribe(res => {

      let list = this.setetSalaries(res['hydra:member']);

      if (list.length > 0) {
         for (var i = 0; i < list.length; i++) {
            if (list[i].isDefaultChanel == true && list[i].typeProject == 'DRH') {
              this.idProjectAll = list[i].id;
              this.initDataTableTsujet(list[i].id);
              return;
            }
          }
      }

      if(res['hydra:view']['hydra:next']){
        this.loadProject(res['hydra:view']['hydra:next'].replace("/api", ""));
      }

    });
  }*/

  /*getSujetWithNoMessage(idProject){
    this.authService.getProjectSuject(idProject).subscribe(res => {
      this.projectTagWithNoMessage = res['hydra:member'];
    }, (error) => {
       //console.log(' getTheProject subscribe error '+error.statusText);
    });
  }*/


  setetSalaries(list){
    list = list.map(item => {
      item.salary = item.userProject.filter(item => item.profil === 'EMPLOYEE');
      return item;
    })
    return list;
  }


  compareTag(tag){
    for (var i = 0; i < this.projectTagWithNoMessage.length; i++) {
      if(this.projectTagWithNoMessage[i].id == tag.id){
        return true;
      }
    }
    return false;
  }

  iniTableSujet(){

    this.editeSubjectModeSociale = false;
    this.editeSubjectModePaie = false;
    this.editeSubjectModeInfo = false;
    this.editeSubjectModeCHO = false;
    this.editeSubjectModeManager = false
    this.editeSubjectModeCse = false;
    this.editeSubjectModeSyndicat = false;

    this.addSubjectModeInfo = false;
    this.addSubjectModePaye = false;
    this.addSubjectModeSociale = false;
    this.addSubjectModeCHO = false;
    this.addSubjectModeManager = false;
    this.addSubjectModeCse = false;
    this.addSubjectModeSyndicat = false;

    this.modeDeletePaye = false;
    this.modeDeleteSociale = false;
    this.modeDeleteInfo = false;
    this.modeDeleteCse = false;
    this.modeDeleteCho = false;
    this.modeDeleteManager = false;
    this.modeDeleteSyndicat = false;

    //this.payeTag = []; plus utilisé

    this.socialeTag = [];
    this.informationTag = [];
    this.choTag = [];
    this.managerTag = [];
    this.cseTag = [];
    this.syndicatsTag = [];

    this.loadAllTopics();

  }


  loadAllTopics(url?: string){
    url = url ? url : undefined;
    this.authService.getTopics(url).subscribe(res => {
      let tempTopicsArray = res['hydra:member'];
      for (var i = 0; i < tempTopicsArray.length; i++) {
         if (tempTopicsArray[i].code == "EMPLOYEE"){
          this.informationTag.push(tempTopicsArray[i]);
          this.informationTag.sort(this.compare);
         }
         else if (tempTopicsArray[i].code == "DRH") {
          this.socialeTag.push(tempTopicsArray[i]);
          this.socialeTag.sort(this.compare);
         }
         else if (tempTopicsArray[i].code == "CHO") {
          this.choTag.push(tempTopicsArray[i]);
          this.choTag.sort(this.compare);
         }
         else if (tempTopicsArray[i].code == "CSE") {
          this.cseTag.push(tempTopicsArray[i]);
          this.cseTag.sort(this.compare);
         }
        else if (tempTopicsArray[i].code == "MANAGER") {
          this.managerTag.push(tempTopicsArray[i]);
          this.managerTag.sort(this.compare);
        }
        else if (tempTopicsArray[i].code == "SYNDICAT") {
          this.syndicatsTag.push(tempTopicsArray[i]);
          this.syndicatsTag.sort(this.compare);
        }
      }
      if(res['hydra:view']['hydra:next']){
        this.loadAllTopics(res['hydra:view']['hydra:next'].replace("/api", ""));
      }
    });
  }

  compare( a, b ) {
    if ( a.name.trim().toLowerCase() < b.name.trim().toLowerCase() ){
      return -1;
    }
    if ( a.name.trim().toLowerCase() > b.name.trim().toLowerCase() ){
      return 1;
    }
    return 0;
  }

  fctaddSubjectCse(): void {
    this.addSubjectModeCse = true;
    this.modeDeleteCse = false;
    this.newSubjectCse = '';
  }

  addSubjectCancelCse(): void {
    this.addSubjectModeCse = false;
    this.modeDeleteCse = false;
    this.newSubjectCse = '';
  }

  fctDeleteSubjectCse(){
    this.tagInfoCse = null;
    this.editeSubjectModeCse = false;
    this.renameSubjectCse = '';
  }

  deleteThisSubjectCse(tag){
   /*
    this.authService.deleteSousTag(tag.id).subscribe(
      res => {
        const index: number = this.projectTag.indexOf(tag);
        if (index !== -1) {
          this.fctDeleteSubjectInfo();
          this.projectTag.splice(index, 1);
          this.iniTableSujet();
        }
      },
      (error) => {
      }
    ); */
  }

  addSubjectCse(): void {
    /*
    this.authService.ajoutSousTag(this.newSubjectInfo, this.projectAllSalary.id, this.idInfo).subscribe(
      res => {
        this.projectTag.push(res);
        this.projectTag.sort(this.compare);
        this.iniTableSujet();
        this.messageService.needRefreshFiltre();
        this.newSubjectInfo = '';
        this.addSubjectModeInfo = false;
      },
      (error) => {
      }
    );*/

    this.authService.ajoutSousTag(this.newSubjectCse, 'CSE').subscribe(res => {
        this.iniTableSujet();
        this.messageService.needRefreshFiltre();
        this.newSubjectCse = '';
        this.addSubjectModeCse = false;
      },
      (error) => {
      }
    );

  }

  fctaddSubjectSyndicat(): void {
    this.addSubjectModeSyndicat = true;
    this.modeDeleteSyndicat = false;
    this.newSubjectSyndicat = '';
  }

  addSubjectCancelSyndicat(): void {
    this.addSubjectModeSyndicat = false;
    this.modeDeleteSyndicat = false;
    this.newSubjectSyndicat= '';
  }

  fctDeleteSubjectSyndicat(){
    this.tagInfoSyndicat = null;
    this.editeSubjectModeSyndicat = false;
    this.renameSubjectSyndicat = '';
  }

  deleteThisSubjectSyndicat(tag){
   /*
    this.authService.deleteSousTag(tag.id).subscribe(
      res => {
        const index: number = this.projectTag.indexOf(tag);
        if (index !== -1) {
          this.fctDeleteSubjectInfo();
          this.projectTag.splice(index, 1);
          this.iniTableSujet();
        }
      },
      (error) => {
      }
    ); */
  }

  addSubjectSyndicat(): void {
    /*
    this.authService.ajoutSousTag(this.newSubjectInfo, this.projectAllSalary.id, this.idInfo).subscribe(
      res => {
        this.projectTag.push(res);
        this.projectTag.sort(this.compare);
        this.iniTableSujet();
        this.messageService.needRefreshFiltre();
        this.newSubjectInfo = '';
        this.addSubjectModeInfo = false;
      },
      (error) => {
      }
    );*/

    this.authService.ajoutSousTag(this.newSubjectSyndicat, 'SYNDICAT').subscribe(res => {
        this.iniTableSujet();
        this.messageService.needRefreshFiltre();
        this.newSubjectSyndicat = '';
        this.addSubjectModeSyndicat = false;
      },
      (error) => {
      }
    );


  }

  fctaddSubjectCHO(): void {
    this.addSubjectModeCHO = true;
    this.modeDeleteCho = false;
    this.newSubjectCHO = '';
  }

  addSubjectCancelCHO(): void {
    this.addSubjectModeCHO = false;
    this.modeDeleteCho = false;
    this.newSubjectCHO = '';
  }

  fctDeleteSubjectCHO(){
    this.tagInfoCHO = null;
    this.editeSubjectModeCHO = false;
    this.renameSubjectCHO = '';
  }

  deleteThisSubjectCHO(tag){
   /*
    this.authService.deleteSousTag(tag.id).subscribe(
      res => {
        const index: number = this.projectTag.indexOf(tag);
        if (index !== -1) {
          this.fctDeleteSubjectInfo();
          this.projectTag.splice(index, 1);
          this.iniTableSujet();
        }
      },
      (error) => {
      }
    ); */
  }

  addSubjectCHO(): void {
    /*
    this.authService.ajoutSousTag(this.newSubjectInfo, this.projectAllSalary.id, this.idInfo).subscribe(
      res => {
        this.projectTag.push(res);
        this.projectTag.sort(this.compare);
        this.iniTableSujet();
        this.messageService.needRefreshFiltre();
        this.newSubjectInfo = '';
        this.addSubjectModeInfo = false;
      },
      (error) => {
      }
    );*/

    this.authService.ajoutSousTag(this.newSubjectCHO, 'CHO').subscribe(res => {
        this.iniTableSujet();
        this.messageService.needRefreshFiltre();
        this.newSubjectCHO = '';
        this.addSubjectModeCHO = false;
      },
      (error) => {
      }
    );

  }

  fctaddSubjectManager(): void {
    this.addSubjectModeManager = true;
    this.modeDeleteManager = false;
    this.newSubjectManager = '';
  }

  addSubjectCancelManager(): void {
    this.addSubjectModeManager = false;
    this.modeDeleteManager = false;
    this.newSubjectManager = '';
  }

  fctDeleteSubjectManager(){
    this.tagInfoManager = null;
    this.editeSubjectModeManager = false;
    this.renameSubjectManager = '';
  }

  deleteThisSubjectManager(tag){
   /*
    this.authService.deleteSousTag(tag.id).subscribe(
      res => {
        const index: number = this.projectTag.indexOf(tag);
        if (index !== -1) {
          this.fctDeleteSubjectInfo();
          this.projectTag.splice(index, 1);
          this.iniTableSujet();
        }
      },
      (error) => {
      }
    ); */
  }

  addSubjectManager(): void {
    /*
    this.authService.ajoutSousTag(this.newSubjectInfo, this.projectAllSalary.id, this.idInfo).subscribe(
      res => {
        this.projectTag.push(res);
        this.projectTag.sort(this.compare);
        this.iniTableSujet();
        this.messageService.needRefreshFiltre();
        this.newSubjectInfo = '';
        this.addSubjectModeInfo = false;
      },
      (error) => {
      }
    );*/


    this.authService.ajoutSousTag(this.newSubjectManager, 'MANAGER').subscribe(res => {
        this.iniTableSujet();
        this.messageService.needRefreshFiltre();
        this.newSubjectManager = '';
        this.addSubjectModeManager = false;
      },
      (error) => {
      }
    );

  }

  fctaddSubjectInfo(): void {
    this.addSubjectModeInfo = true;
    this.modeDeleteInfo = false;
    this.newSubjectInfo = '';
  }

  addSubjectCancelInfo(): void {
    this.addSubjectModeInfo = false;
    this.modeDeleteInfo = false;
    this.newSubjectInfo = '';
  }

  fctDeleteSubjectInfo(){
    this.tagInfoInformation = null;
    this.editeSubjectModeInfo = false;
    this.renameSubjectInfo = '';
  }

  deleteThisSubjectInfo(tag){
    //console.log('go delete info ==> '+tag);
    /*this.authService.deleteSousTag(tag.id).subscribe(
      res => {
        const index: number = this.projectTag.indexOf(tag);
        if (index !== -1) {
          this.fctDeleteSubjectInfo();
          this.projectTag.splice(index, 1);
          this.iniTableSujet();
        }
      },
      (error) => {
      }
    );*/
  }

  addSubjectInfo(): void {

    this.authService.ajoutSousTag(this.newSubjectInfo, 'EMPLOYEE').subscribe(res => {
        this.iniTableSujet();
        this.messageService.needRefreshFiltre();
        this.newSubjectInfo = '';
        this.addSubjectModeInfo = false;
      },
      (error) => {
      }
    );

   /* this.authService.ajoutSousTag(this.newSubjectInfo, this.projectAllSalary.id, this.idInfo).subscribe(
      res => {
        this.projectTag.push(res);
        this.projectTag.sort(this.compare);
        this.iniTableSujet();
        this.messageService.needRefreshFiltre();
        this.newSubjectInfo = '';
        this.addSubjectModeInfo = false;
      },
      (error) => {
      }
    ); */

  }

  fctaddSubjectPaye(): void {
    this.addSubjectModePaye = true;
    this.modeDeletePaye = false;
    this.newSubjectPaye = '';
  }

  addSubjectCancelPaye(): void {
    this.addSubjectModePaye = false;
    this.modeDeletePaye = false;
    this.newSubjectPaye = '';
  }

  fctDeleteSubjectPaye(){
    this.tagInfoPaie = null;
    this.editeSubjectModePaie = false;
    this.renameSubjectPaie = '';
  }

  deleteThisSubjectPaye(tag){
    //console.log('go delete paye ==> '+tag);
    /* this.authService.deleteSousTag(tag.id).subscribe(
      res => {
        const index: number = this.projectTag.indexOf(tag);
        if (index !== -1) {
          this.fctDeleteSubjectPaye();
          this.projectTag.splice(index, 1);
          this.iniTableSujet();
        }
      },
      (error) => {
      }
    ); */
  }

  addSubjectPaye(): void {

/*    this.authService.ajoutSousTag(this.newSubjectPaye, this.projectAllSalary.id, this.idPaye).subscribe(
      res => {
        this.projectTag.push(res);
        this.projectTag.sort(this.compare);
        this.iniTableSujet();
        this.messageService.needRefreshFiltre();
        this.newSubjectPaye = '';
        this.addSubjectModePaye = false;
      },
      (error) => {
      }
    ); */

  }

  fctaddSubjectSocial(): void {
    this.addSubjectModeSociale = true;
    this.modeDeleteSociale = false;
    this.newSubjectSocial = '';
  }

  addSubjectCancelSocial(): void {
    this.addSubjectModeSociale = false;
    this.modeDeleteSociale = false;
    this.newSubjectSocial = '';
  }

  fctDeleteSubjectSocial(){
    this.tagInfoSociale = null;
    this.editeSubjectModeSociale = false;
    this.renameSubjectSociale = '';
  }

  deleteThisSubjectSociale(tag){
    //console.log('go delete sociale ==> '+tag);
    /*this.authService.deleteSousTag(tag.id).subscribe(
      res => {
        const index: number = this.projectTag.indexOf(tag);
        if (index !== -1) {
          this.fctDeleteSubjectSocial();
          this.projectTag.splice(index, 1);
          this.iniTableSujet();
        }
      },
      (error) => {
      }
    ); */
  }

  addSubjectSociale(): void {

   /* this.authService.ajoutSousTag(this.newSubjectSocial, this.projectAllSalary.id, this.idSocial).subscribe(
      res => {
        this.projectTag.push(res);
        this.projectTag.sort(this.compare);
        this.iniTableSujet();
        this.messageService.needRefreshFiltre();
        this.newSubjectSocial = '';
        this.addSubjectModeSociale = false;
      },
      (error) => {
      }
    ); */

      this.authService.ajoutSousTag(this.newSubjectSocial, 'DRH').subscribe(res => {
        this.iniTableSujet();
        this.messageService.needRefreshFiltre();
        this.newSubjectSocial = '';
        this.addSubjectModeSociale = false;
      },
      (error) => {
      }
    );


  }

  writeNewSujetInfo(newSubProjetName){
    //console.log('newSubProjetName => '+newSubProjetName);
    this.newSubjectInfo = newSubProjetName;
  }

  writeNewSujetPaye(newSubProjetName){
    //console.log('newSubProjetName => '+newSubProjetName);
    this.newSubjectPaye = newSubProjetName;
  }

  writeNewSujetSociale(newSubProjetName){
    //console.log('newSubProjetName => '+newSubProjetName);
    this.newSubjectSocial = newSubProjetName;
  }

  writeNewSujetCHO(newSubProjetName){
    //console.log('newSubProjetName => '+newSubProjetName);
    this.newSubjectCHO = newSubProjetName;
  }

  writeNewSujetManager(newSubProjetName){
    //console.log('newSubProjetName => '+newSubProjetName);
    this.newSubjectManager = newSubProjetName;
  }

  writeNewSujetCse(newSubProjetName){
    //console.log('newSubProjetName => '+newSubProjetName);
    this.newSubjectCse = newSubProjetName;
  }

  writeNewSujetSyndicat(newSubProjetName){
    //console.log('newSubProjetName => '+newSubProjetName);
    this.newSubjectSyndicat = newSubProjetName;
  }

  doTextareaValueChangeInfo(ev) {
    this.renameSubjectInfo = ev.target.value;
  }

  doTextareaValueChangePaie(ev) {
    this.renameSubjectPaie = ev.target.value;
  }

  doTextareaValueChangeSociale(ev) {
    this.renameSubjectSociale = ev.target.value;
  }

  doTextareaValueChangeCHO(ev) {
    this.renameSubjectCHO = ev.target.value;
  }

  doTextareaValueChangeManager(ev) {
    this.renameSubjectManager = ev.target.value;
  }

  doTextareaValueChangeCse(ev) {
    this.renameSubjectCse = ev.target.value;
  }

  doTextareaValueChangeSyndicat(ev) {
    this.renameSubjectSyndicat = ev.target.value;
  }


  fctChangeSubjectPaie(element){
    this.renameSubjectPaie = '';
    this.tagInfoPaie = element;
    this.editeSubjectModePaie = true;
  }

  fctChangeSubjectInfo(element){
    //console.log('dans change subject => '+JSON.stringify(element));
    this.renameSubjectInfo = '';
    this.tagInfoInformation = element;
    this.editeSubjectModeInfo = true;
  }

  fctChangeSubjectSociale(element){
    this.renameSubjectSociale = '';
    this.tagInfoSociale = element;
    this.editeSubjectModeSociale = true;
  }

  fctChangeSubjectCHO(element){
    this.renameSubjectCHO = '';
    this.tagInfoCHO = element;
    this.editeSubjectModeCHO = true;
  }

  fctChangeSubjectManager(element){
    this.renameSubjectManager = '';
    this.tagInfoManager = element;
    this.editeSubjectModeManager = true;
  }

  fctChangeSubjectCse(element){
    this.renameSubjectCse = '';
    this.tagInfoCse = element;
    this.editeSubjectModeCse = true;
  }

  fctChangeSubjectSyndicat(element){
    this.renameSubjectSyndicat = '';
    this.tagInfoSyndicat = element;
    this.editeSubjectModeSyndicat = true;
  }

  validerModif(tag, type){

    if (type === 0) {
      if (this.renameSubjectPaie.trim() != '') {
        this.authService.changeSubjectName(this.renameSubjectPaie, tag.name, tag.id)
          .subscribe(res => {

            this.tagInfoPaie = null;
            this.editeSubjectModePaie = false;
            this.renameSubjectPaie = '';
            //this.envoyerObs.next();
            //this.initDataTableTsujet(this.idProjectAll);
            this.iniTableSujet();
          }, (error) => {
             //console.log(' getTheProject subscribe error '+error.statusText);
        });
      }
      else
      {
        this.tagInfoPaie = null;
        this.editeSubjectModePaie = false;
        this.renameSubjectPaie = '';
      }
   }
   else if (type === 1) {
     if (this.renameSubjectSociale.trim() != '') {
        this.authService.changeSubjectName(this.renameSubjectSociale, tag.name, tag.id)
          .subscribe(res => {
            /*console.log(' retour validerModif => '+res);*/

            this.tagInfoSociale= null;
            this.editeSubjectModeSociale = false;
            this.renameSubjectSociale= '';
            //this.envoyerObs.next();
            //this.initDataTableTsujet(this.idProjectAll);
             this.iniTableSujet();
          }, (error) => {
             //console.log(' getTheProject subscribe error '+error.statusText);
        });
      }
      else
      {
        this.tagInfoSociale = null;
        this.editeSubjectModeSociale = false;
        this.renameSubjectSociale = '';
      }
   }else if (type === 2){
     if (this.renameSubjectInfo.trim() != '') {
        this.authService.changeSubjectName(this.renameSubjectInfo, tag.name, tag.id)
          .subscribe(res => {
            /*console.log(' retour validerModif => '+res);*/

            this.tagInfoInformation= null;
            this.editeSubjectModeInfo = false;
            this.renameSubjectInfo = '';
            //this.envoyerObs.next();
            //this.initDataTableTsujet(this.idProjectAll);
            this.iniTableSujet();
          }, (error) => {
             //console.log(' getTheProject subscribe error '+error.statusText);
        });
      }
      else
      {
        this.tagInfoInformation = null;
        this.editeSubjectModeInfo = false;
        this.renameSubjectInfo = '';
      }
   }else if (type === 3){
      if (this.renameSubjectCHO.trim() != '') {
        this.authService.changeSubjectName(this.renameSubjectCHO, tag.name, tag.id)
          .subscribe(res => {
            this.tagInfoCHO = null;
            this.editeSubjectModeCHO = false;
            this.renameSubjectCHO = '';
            this.iniTableSujet();
          }, (error) => {
             //console.log(' getTheProject subscribe error '+error.statusText);
        });
      }
      else{
        this.tagInfoCHO = null;
        this.editeSubjectModeCHO = false;
        this.renameSubjectCHO = '';
      }
   }else if (type === 4){
      if (this.renameSubjectManager.trim() != '') {
        this.authService.changeSubjectName(this.renameSubjectManager, tag.name, tag.id)
          .subscribe(res => {
            this.tagInfoManager= null;
            this.editeSubjectModeManager = false;
            this.renameSubjectManager = '';
            this.iniTableSujet();
          }, (error) => {
             //console.log(' getTheProject subscribe error '+error.statusText);
        });
      }
      else{
        this.tagInfoManager= null;
        this.editeSubjectModeManager = false;
        this.renameSubjectManager = '';
      }
   }else if (type === 5){
    if (this.renameSubjectCse.trim() != '') {
      this.authService.changeSubjectName(this.renameSubjectCse, tag.name, tag.id)
        .subscribe(res => {
          this.tagInfoCse = null;
          this.editeSubjectModeCse = false;
          this.renameSubjectCse = '';
          this.iniTableSujet();
        }, (error) => {
           //console.log(' getTheProject subscribe error '+error.statusText);
      });
    }
    else{
      this.tagInfoCse = null;
      this.editeSubjectModeCse = false;
      this.renameSubjectCse = '';
    }
   }else if (type === 6){
    if (this.renameSubjectSyndicat.trim() != '') {
      this.authService.changeSubjectName(this.renameSubjectSyndicat, tag.name, tag.id)
        .subscribe(res => {
           this.tagInfoSyndicat = null;
          this.editeSubjectModeSyndicat = false;
          this.renameSubjectSyndicat = '';
          this.iniTableSujet();
        }, (error) => {
           //console.log(' getTheProject subscribe error '+error.statusText);
      });
    }
    else{
      this.tagInfoSyndicat = null;
      this.editeSubjectModeSyndicat = false;
      this.renameSubjectSyndicat = '';
    }
   }



  }

   /* --------------------- */

  /* ------ FCT PARTIE COLLABORATEUR ------ */

  loadContact(url?: string){
    url = url ? url : undefined;
    /*this.authService.getContactsRh(url).subscribe(res => {
      let tempContactArray = res['hydra:member'];
      for (var i = 0; i < tempContactArray.length; i++) {
        if(tempContactArray[i].profil == 'HR'){
          this.contacts.push(tempContactArray[i]);
        }
      }
      if(res['hydra:view']['hydra:next']){
        this.loadContact(res['hydra:view']['hydra:next'].replace("/api", ""));
      }
      else{
        this.loadContactWaiting();
      }
    }); */
    this.authService.getContacts(url).subscribe(res => {
      let tempContactArray = res['hydra:member'];
      for (var i = 0; i < tempContactArray.length; i++) {
          tempContactArray[i]['EditMode'] = false;
          if (this.isAdmin == true) {
            this.contacts.push(tempContactArray[i]);
          }
          else
          {
            if (tempContactArray[i].profil == 'HR') {
              this.contacts.push(tempContactArray[i]);
            }
          }
      }
      if(res['hydra:view']['hydra:next']){
        this.loadContact(res['hydra:view']['hydra:next'].replace("/api", ""));
      }
      else{
        this.loadContactWaiting();
      }
    });
  }

  loadContactWaiting(){
    this.authService.getContactWaiting().subscribe(res => {
      let arrayContactWaiting = res['hydra:member'];
      for (var i = 0; i < arrayContactWaiting.length; i++) {
        arrayContactWaiting[i].givenName = arrayContactWaiting[i].value;
        arrayContactWaiting[i].familyName = arrayContactWaiting[i].value;
      }
      this.contacts = this.contacts.concat(arrayContactWaiting);
      //this.contacts = this.contacts.concat(res['hydra:member']);
    });
  }

  showProfil(idUser): void{
    //this.router.navigate(['/profile/'+idUser]);
  }

  onClickDesignRRHEdit(contactInfo): void{
    //this.router.navigate(['/profile/'+idUser]);
    if (contactInfo.EditMode == true) {
      contactInfo.EditMode = false;
    }
    else{
      contactInfo.EditMode = true;
    }
  }

  onClickDesignRRH(contact): void{
    this.authService.designRRH(contact.id)
      .subscribe(res => {
        this.searchContact = "";
        this.contacts = [];
        this.loadContact();
      }, (error) => {
         //console.log(' getTheProject subscribe error '+error.statusText);
    });
  }

  onClickRemoveRRH(contact){
     this.authService.removeRRH(contact.id)
      .subscribe(res => {
        this.searchContact = "";
        this.contacts = [];
        this.loadContact();
        if (contact.id == this.currentUser.id) {
          alert("Votre profil vient d'être modifié. Veuillez vos reconnecter afin d'actualiser vos droits d'utilisation.");
          this.authService.logout();
        }
      }, (error) => {
        console.log('Uh-oh, an error occurred! : ' +JSON.stringify(error));
        alert('une erreur est survenue');
    });
  }

  deleteContact(idUser): void{
    this.authService.deleleteRRHInvitation(idUser)
      .subscribe(res => {
        this.searchContact = "";
        this.contacts = [];
        this.loadContact();
      }, (error) => {
         //console.log(' getTheProject subscribe error '+error.statusText);
    });
  }

  getStatus(key){
    switch (key) {
      case 'HRD':
        return "D.R.H.";
        break;
      case 'HR':
        return "R.R.H.";
        break;
      case 'CS':
        return "Manager";
        break;
      case 'EMPLOYEE':
        return "Salarié";
        break;
      default:
        return key;
        break;
    }
  }

   newContact(){
    if (this.showNewContactInput == true)
      this.showNewContactInput = false;
    else
      this.showNewContactInput = true;
  }


  onSubmitForm(customerData) {

    this.onSubmit = true;

     // Process checkout data here
     console.log('Your order has been submitted new rrh ', customerData);

    //console.log('this.emailFormControl.status', this.emailFormControl.status);

     // stop here if form is invalid
    if (this.emailFormControl.status == 'INVALID') {
      return;
    }

    if (this.emailFormControl.value.fname.trim() == '') {
      return;
    }

    if (this.emailFormControl.value.lname.trim() == '') {
      return;
    }


    if (this.emailFormControl.value.jobT.trim() == '') {
      return;
    }

    if (this.emailFormControl.value.email.trim() == '') {
      return;
    }


    this.authService.addGeneralContact(customerData).subscribe(res => {
       //console.log('retour ajout membre mail => '+JSON.stringify(res));
      this.searchContact = "";
      alert('Invitation envoyé');
      this.contacts = [];
      this.loadContact();
      this.onSubmit = false;
    },
    (error) => {

      this.onSubmit = false;

      if(error.error && error.error.violations){
        alert(error.error.violations[0].message);
      }
      else
      {
        alert(error.statusText);
      }

    });

  }

  getAvatarlUrl(avatarLink){
    return environment.server_url+avatarLink;
  }

  openEmailInvite(){
     if (this.openEmail == true) {
      this.openEmail = false;
     }
     else
     {
       this.openEmail = true;
     }
  }


    /* --------------------- */

  /* ------ FCT PARTIE SALARIE ------ */


  loadSalarie(url?: string){
    url = url ? url : undefined;
    this.authService.getContacts(url).subscribe(res => {
      // console.log(res)
      let list = res['hydra:member'];

      if (list.length > 0) {
        for (var i = 0; i < list.length; i++) {
          //if (list[i].profil == "EMPLOYEE"){
            this.salaryLists.push(list[i]);
          //}
        }
        this.salaryLists.sort(this.sortsalaryLists);
      }

      if(res['hydra:view']['hydra:next']){
        this.loadSalarie(res['hydra:view']['hydra:next'].replace("/api", ""));
      }

    });
  }

  sortsalaryLists(a, b) {
    return a.givenName - b.givenName;
  }


  fctGetImageUser(userData){
    if (userData.imageUrl)
    {
      return  this.getAvatarlUrl(userData.imageUrl);
    }
    else
    {
      return null;
    }
  }

  fctprojectChosen(salariedId){
    //console.log('salarié id ==> '+ salariedId);
    this.idUser = salariedId;
    if (this.idUser) {
      this.getCurrentUser();
    }
    this.showProfilMode = true;
  }

  getCurrentUser(){
   // console.log('getCurrentUser paramètre id ==> '+this.idUser);
    this.authService.getInfoContact(this.idUser)
      .subscribe( dataContact => {
        this.contactInfo = dataContact;
        //this.img = (this.contactInfo.imageUrl ? this.getAvatarlUrl(this.contactInfo.imageUrl) : '../../../../assets/img/default-avatar.jpg');

        if (this.contactInfo.imageUrl) {
          this.img = this.getAvatarlUrl(this.contactInfo.imageUrl)
        }
        else
        {
          this.img ='/assets/img/default-avatar.png';
          // if ( this.contactInfo.gender == 'male') {
          //   this.img = '../../../../assets/img/Homme_vert.png';
          // }
          // else if ( this.contactInfo.gender == 'female')
          // {
          //   this.img = '../../../../assets/img/Femme_vert.png';
          // }
          // else
          // {
          //   this.img = '../../../../assets/img/Homme_vert.png';
          // }
        }

        this.fname =  this.contactInfo.givenName;
        this.lname =  this.contactInfo.familyName;
        if(this.contactInfo.birthDate) {
          if (this.contactInfo.birthDate.date)
          {
            this.dob = this.contactInfo.birthDate.date;
          }
          else
          {
            this.dob = this.contactInfo.birthDate;
          }
        }
        //this.dob =  this.contactInfo.birthDate;
        this.jobT =  this.contactInfo.jobTitle;
        this.email =  this.contactInfo.email;
        this.gender =  this.contactInfo.gender;
        this.description =  this.contactInfo.description;
        this.phone =  this.contactInfo.telephone;
        this.mobilePro = this.contactInfo.mobilePro;
        this.mobilePerso = this.contactInfo.mobilePerso;
        this.profilUser = this.contactInfo.profil;


    }, (error) => {
        console.log('Uh-oh, an error occurred! : ' +JSON.stringify(error));
        alert('une erreur est survenue');
    });

  }


  saveProfile() {

    let latest_date = null;

    if (this.dob) {
      latest_date = this.datepipe.transform(this.dob, 'dd-MM-yyyy');
    }

    // console.log('getCurrentUser paramètre id ==> '+this.idUser);
    this.authService.udapteSAlary(this.idUser, (this.fname ? this.fname : ''), (this.lname ? this.lname : ''), (latest_date ? latest_date : null), (this.jobT ? this.jobT : ''), (this.email ? this.email : '')
      ,(this.gender ? this.gender : ''), (this.description ? this.description : ''), (this.phone ? this.phone : ''), (this.mobilePro ? this.mobilePro : ''), (this.mobilePerso ? this.mobilePerso : '')).subscribe( data => {
       //console.log('retour saveProfile '+JSON.stringify(data));
       this.edit = false; 
       this.getCurrentUser();
       this.salaryLists = [];
       this.loadSalarie();
       alert('Modification(s) enregistré');
    }, (error) => {
        //console.log('Uh-oh, an error occurred! : ' +JSON.stringify(error));
        alert('une erreur est survenue');
    });


  }

  /* CONTACT IRP(SYNDICAT) CHO CSE  */


  loadContactSave(url?: string){
    url = url ? url : undefined;
    this.authService.getContacts(url).subscribe(res => {
      let tempContactArray = res['hydra:member'];
      for (var i = 0; i < tempContactArray.length; i++) {
        tempContactArray[i]['EditMode'] = false;
        this.contactsSave.push(tempContactArray[i]);
      }
      if(res['hydra:view']['hydra:next']){
        this.loadContactSave(res['hydra:view']['hydra:next'].replace("/api", ""));
      }
    });
  }

  loadContactIRP(url?: string){
    url = url ? url : undefined;
    this.authService.getContacts(url).subscribe(res => {

      let tempContactArray = res['hydra:member'];
      for (var i = 0; i < tempContactArray.length; i++) {
          tempContactArray[i]['EditMode'] = false;
          if (this.isAdmin == true) {
            this.contactsIRP.push(tempContactArray[i]);
          }
          else
          {
            if ((tempContactArray[i].skills.includes(1) == true) || (tempContactArray[i].skills.includes(5) == true) || (tempContactArray[i].skills.includes(6) == true)
              || (tempContactArray[i].skills.includes(7) == true) || (tempContactArray[i].skills.includes(8) == true) ) {
              this.contactsIRP.push(tempContactArray[i]);
            }
          }
      }

      if(res['hydra:view']['hydra:next']){
        this.loadContactIRP(res['hydra:view']['hydra:next'].replace("/api", ""));
      }
    });
  }


  loadContactCSE(url?: string){
    url = url ? url : undefined;
    this.authService.getContacts(url).subscribe(res => {

      let tempContactArray = res['hydra:member'];
      for (var i = 0; i < tempContactArray.length; i++) {
          tempContactArray[i]['EditMode'] = false;
          if (this.isAdmin == true) {
            this.contactsCSE.push(tempContactArray[i]);
          }
          else
          {
            if (tempContactArray[i].skills.includes(10) == true) {
              this.contactsCSE.push(tempContactArray[i]);
            }
          }
      }

      if(res['hydra:view']['hydra:next']){
        this.loadContactCSE(res['hydra:view']['hydra:next'].replace("/api", ""));
      }
    });
  }

  loadContactCHO(url?: string){
    url = url ? url : undefined;
    this.authService.getContacts(url).subscribe(res => {

      let tempContactArray = res['hydra:member'];
      for (var i = 0; i < tempContactArray.length; i++) {
          tempContactArray[i]['EditMode'] = false;
          if (this.isAdmin == true) {
            this.contactsCHO.push(tempContactArray[i]);
          }
          else
          {
            if (tempContactArray[i].skills.includes(20) == true) {
              this.contactsCHO.push(tempContactArray[i]);
            }
          }
      }

      if(res['hydra:view']['hydra:next']){
        this.loadContactCHO(res['hydra:view']['hydra:next'].replace("/api", ""));
      }
    });
  }


  onClickEditMode(contact){
    if (contact.EditMode === true) {
      contact.EditMode = false;
    }
    else{
      contact.EditMode = true;
    }
  }

  changeType(contact, type)
  {
    var skillArray = contact.skills;
    if(skillArray.indexOf(type) != -1)
    {
      let index = skillArray.indexOf(type);
      skillArray.splice(index, 1);
      contact.skills = skillArray;
    }
    else
    {
      skillArray.push(type);
      if (type == 10) {// on ne peut pas etre cse et syndicat en même temps
        if(skillArray.indexOf(1) != -1)
        {
          let index = skillArray.indexOf(1);
          skillArray.splice(index, 1);
        }
        if(skillArray.indexOf(5) != -1)
        {
          let index = skillArray.indexOf(5);
          skillArray.splice(index, 1);
        }
        if(skillArray.indexOf(6) != -1)
        {
          let index = skillArray.indexOf(6);
          skillArray.splice(index, 1);
        }
        if(skillArray.indexOf(7) != -1)
        {
          let index = skillArray.indexOf(7);
          skillArray.splice(index, 1);
        }
        if(skillArray.indexOf(8) != -1)
        {
          let index = skillArray.indexOf(8);
          skillArray.splice(index, 1);
        }
      }
      else if (type == 1 || type == 5 || type == 6 || type == 7 ||  type == 8){
        if(skillArray.indexOf(10) != -1)
        {
          let index = skillArray.indexOf(10);
          skillArray.splice(index, 1);
        }
      }
      contact.skills = skillArray;
    }

  }

  validate(contact){
    //console.log('skill du contact => '+contact.skills);
    this.authService.udapteContactSkill(contact.id, contact.skills)
    .subscribe( data => {
      contact.EditMode = false;
      for (var i = 0; i < this.contactsSave.length; i++) {
        if (this.contactsSave[i].id == contact.id) {
          var skillsArray = [];
          for (var index = 0; index < contact.skills.length; index++) {
            skillsArray.push(contact.skills[index]);
          }
          this.contactsSave[i].skills = skillsArray;
        } 
      }
      if(contact.skills.indexOf(10) != -1)
      {
        for (var i = 0; i < this.contactsIRP.length; i++) {
          if(this.contactsIRP[i].id == contact.id){
            var skillsArray = [];
            for (var index = 0; index < contact.skills.length; index++) {
              skillsArray.push(contact.skills[index]);
            }
            this.contactsIRP[i].skills = skillsArray;    
          }
        }
      }
      if(contact.skills.indexOf(1) != -1 || contact.skills.indexOf(5) != -1 || contact.skills.indexOf(6) != -1 || contact.skills.indexOf(7) != -1
        || contact.skills.indexOf(8) != -1)
      {
        for (var i = 0; i < this.contactsCSE.length; i++) {
          if(this.contactsCSE[i].id == contact.id){
            var skillsArray = [];
            for (var index = 0; index < contact.skills.length; index++) {
              skillsArray.push(contact.skills[index]);
            }
            this.contactsCSE[i].skills = skillsArray;    
          }
        }
      }
      this.messageService.nextReloadUserInfo();
    }, (error) => {
      alert('une erreur est survenue');
    });
  }

  annuler(contact){
    contact.EditMode = false;
    for (var i = 0; i < this.contactsSave.length; i++) {
      if (this.contactsSave[i].id == contact.id) {
        //console.log({Annulecontactsave:this.contactsSave[i]});
        var skillsArray = [];
        for (var index = 0; index < this.contactsSave[i].skills.length; index++) {
          skillsArray.push(this.contactsSave[i].skills[index]);
        }
        contact.skills = skillsArray;
        return;
      } 
    }
  }

  contactIsSyndicat(contact){
     return (contact.skills.indexOf(1) != -1 || contact.skills.indexOf(5) != -1 || contact.skills.indexOf(6) != -1 || contact.skills.indexOf(7) != -1 || contact.skills.indexOf(8) != -1) ? true : false ;
  }

  contactIsIRP(contact){
     return contact.skills.indexOf(10) != -1 ? true : false;
  }

  contactIsCHO(contact){
     return contact.skills.indexOf(20) != -1 ? true : false;
  }



  /********* PARTIE MANAGER *********/



  getContactSalary(url?: string){
    url = url ? url : undefined;
    this.authService.getContacts(url).subscribe(res => {
      let tempContactArray = res['hydra:member'];
      this.contactSalaryLength += tempContactArray.length;

      for (var i = 0; i < tempContactArray.length; i++) {
        tempContactArray[i]['EditMode'] = false;
        tempContactArray[i]['salaryToManage'] = [];
        tempContactArray[i]['noLongerManage'] = [];
        if (this.isAdmin == true) {
          if ((tempContactArray[i].isManager && (tempContactArray[i].isManager == true)) || (tempContactArray[i].manager == null)){
            this.managerArray.push(tempContactArray[i]);
          }
        }
        else
        {
          if (tempContactArray[i].isManager && (tempContactArray[i].isManager == true)){
            this.managerArray.push(tempContactArray[i]);
          }
        }
      }

      if(res['hydra:view']['hydra:next']){
        this.getContactSalary(res['hydra:view']['hydra:next'].replace("/api", ""));
      }
      else if(res['hydra:totalItems'] == this.contactSalaryLength){
        this.loadContactsToManaged();
      }
    });
  }

  loadContactsToManaged(url?: string){
    url = url ? url : undefined;
    this.authService.getContacts(url).subscribe(res => {
      let tempContactArray = res['hydra:member'];
      for (var i = 0; i < tempContactArray.length; i++) {
        if (!tempContactArray[i].isManager || (tempContactArray[i].isManager == false)){
          this.ArrayContactsToManaged.push(tempContactArray[i]);
        }
      }
      if(res['hydra:view']['hydra:next']){
        this.loadContactsToManaged(res['hydra:view']['hydra:next'].replace("/api", ""));
      }
    });
  }

  contactsToManagedWhoIsNotCurrentManager(manager){
    var tempArray = []
    for (var i = 0; i < this.ArrayContactsToManaged.length; i++) {
      if(this.ArrayContactsToManaged[i].id != manager.id)
      {
        tempArray.push(this.ArrayContactsToManaged[i]);
      }
    }
    return tempArray;
  }

  onClickEditModeManager(salary){
    if (salary.EditMode === true) {
      salary.EditMode = false;
    }
    else{
      salary.EditMode = true;
      salary.noLongerManage = [];
      for (var i = 0; i < this.managerArray.length; i++) {
        for (var index = 0; index < this.ArrayContactsToManaged.length; index++) {
          if((this.ArrayContactsToManaged[index].manager == this.managerArray[i].id) && (this.managerArray.indexOf(this.ArrayContactsToManaged[index]) == -1 )){
            this.managerArray[i].salaryToManage.push(this.ArrayContactsToManaged[index]);
          }
        }
      }
    }
  }

  onSelection(e, v, salary){
    var indexValue = salary.salaryToManage.indexOf(e.option.value);
    if(indexValue > -1)
    {
      salary.salaryToManage.splice(indexValue, 1);
      var indexRemove = salary.noLongerManage.indexOf(e.option.value);
      if (indexRemove == -1) {
        salary.noLongerManage.push(e.option.value);
        //console.log('On retiré => ', e.option.value);
      }
    }
    else
    {
      salary.salaryToManage.push(e.option.value);
      var indexRemove = salary.noLongerManage.indexOf(e.option.value);
      if (indexRemove > -1) {
        salary.noLongerManage.splice(indexRemove, 1);
      }
      //console.log('On ajouté', e.option.value);
    }
  }

  validateManager(salary){
    var indexTable = 0;
    this.addmanagement(salary, indexTable);
  }

  addmanagement(salary, indexTable){

    if (!salary.salaryToManage[indexTable]) {
      var indexTableManager = 0;
      this.removeManager(salary, indexTableManager);
      return;
    }

    this.authService.addmanagement(salary.salaryToManage[indexTable].id, salary.id).subscribe(res => {
       indexTable+=1;
       this.addmanagement(salary, indexTable);
    },
    (error) => {
      if(error.error && error.error.violations){
        if (error.error.violations[0].message == 'Cet utilisateur est déjà affecté a cette activité') {
          indexTable+=1;
          this.addmanagement(salary, indexTable);
        }
        else
        {
          alert(error.error.violations[0].message);
        }
      }
      else
      {
        alert(error.statusText);
      }
    });

  }

  removeManager(salary, indexTableManager){


    // a refaire quand il y aura le remove
    if (!salary.noLongerManage[indexTableManager]) {
      salary.EditMode = false;
      this.managerArray = [];
      this.ArrayContactsToManaged = [];
      this.contactSalaryLength = 0;
      this.getContactSalary();
      return;
    }

    //console.log('on retire salary ==>',salary );

    this.authService.removemanagement(salary.noLongerManage[indexTableManager].id).subscribe(res => {
       indexTableManager+=1;
       this.removeManager(salary, indexTableManager);
    },
    (error) => {
      alert(error.statusText);
      salary.EditMode = false;
      this.managerArray = [];
      this.ArrayContactsToManaged = [];
      this.contactSalaryLength = 0;
      this.getContactSalary();
    });


  }

}

