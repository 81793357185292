import { Component, OnInit, ViewChild, Input,ElementRef, Output, EventEmitter, OnDestroy } from '@angular/core';
import { MatPaginator, MatTableDataSource } from '@angular/material';
import { AuthService } from 'src/app/services/auth-service/auth.service';
import { merge, Observable, of as observableOf, Subject } from 'rxjs';
import { catchError, map, startWith, switchMap, takeUntil } from 'rxjs/operators';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { MessageService } from 'src/app/services/message/message.service';
import { FormControl } from '@angular/forms';

export interface searchFilter {
  transmitter: string;
  recipient: string;
  projectId: string;
  dateBefore: any;
  dateAfter: any;
  tag: string;
  statut: boolean;
  type: string;
  keyWord: string;
  smartNumber: string;
}

@Component({
  selector: 'app-dashboardcontent',
  templateUrl: './dashboardcontent.component.html',
  styleUrls: ['./dashboardcontent.component.scss']
})

export class DashboardcontentComponent implements OnInit, OnDestroy {
 
  messages: any = [];
  dataSource: any;
  resultsLength = 0;
  perPage = 30;
  isLoadingResults = false;
  isRateLimitReached = false;
  lastDate = new Date('01/01/4970');
  filterChoosen = null;
  typeList = { QUESTION: "Question", ANSWER: "Réponse", COMMENT: "Commentaire"};
  hiddenSearchEngin = false;

  displayedColumns: string[] = [
    'Date',
    'Projet',
    'Sujet',
    'Expéditeur',
    'Destinataire',
    /*'Type',*/
    'Statut',
    'consult'
  ];
  
  @ViewChild(MatPaginator) paginator: MatPaginator;

  @Output() EventLaunchSearch = new EventEmitter();
  @Output() EventLaunchSearchDoc = new EventEmitter();
  @Input() ged: boolean = false;

  transmitter = null;
  recipient = null;
  projectId = null;
  dateBefore = null;
  dateAfter = null;
  tag = null;
  statut = null;
  type = null;
  keyWord = null;
  smartNumber = null;
  modeDoc = false;
  contacts: Array<any> = [];
  contactsExp: Array<any> = [];
  contactsRecip: Array<any> = [];
  tmpprojectLists: Array<any> = [];
  projectLists: Array<any> = [];
  projectTags: any;
  chosenFilter: searchFilter;
  currentUser = null;
  saveCtc = null;
  saveCtr = null;
  saveProject = null;
  saveTheme   = null;
  public EmmetterCtrl: FormControl = new FormControl();
  public recipientCtrl: FormControl = new FormControl();
  public projectCtrl: FormControl = new FormControl();
  public themeCtrl: FormControl = new FormControl();
  protected _onDestroy = new Subject<void>();

  constructor(private authService: AuthService, private router: Router, private route: ActivatedRoute, private messageService: MessageService) { 
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    delete currentUser.token;
    delete currentUser.refresh_token;
    delete currentUser.roles;
    this.currentUser = currentUser;
    //console.log('so big => '+JSON.stringify(this.currentUser));
  }


  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  ngOnInit() {  

    this.EmmetterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterEmmetter();
      });

    this.recipientCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterRecipient();
      });
 

    this.projectCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterProject();
      });

    this.themeCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.themeProject();
      });


    this.getAllMessages(null);
  
    this.tmpprojectLists = [];

    this.getContact();
    this.loadProject(); 

    this.authService.obprojectChosen.subscribe((idMessage) =>{ 
      this.smartNumber = idMessage;
      //console.log('dans le smarnumber idmessage ==> '+idMessage);
    });

    this.ged = false;


  }

  fcthiddenSearchEngin(){
    if (this.hiddenSearchEngin == false)
      this.hiddenSearchEngin = true;
    else
      this.hiddenSearchEngin = false;
  }

  protected filterEmmetter() {
    if(this.saveCtc == null){
      this.saveCtc = this.contactsExp;
    }

    let search = this.EmmetterCtrl.value;
    if (!search) {
      this.contactsExp = this.saveCtc;
      return;
    } else {
      search = search.toLowerCase();
      this.contactsExp = this.saveCtc.filter(bank => bank.givenName.toLowerCase().indexOf(search) > -1);
    }
  }

  protected filterRecipient() {
    if(this.saveCtr == null){
      this.saveCtr = this.contactsRecip;
    }

    let search = this.EmmetterCtrl.value;
    if (!search) {
      this.contactsRecip = this.saveCtr;
      return;
    } else {
      search = search.toLowerCase();
      this.contactsRecip = this.saveCtr.filter(bank => bank.givenName.toLowerCase().indexOf(search) > -1);
    }
  }

  protected filterProject() {
    if(this.saveProject == null){
      this.saveProject = this.projectLists;
    }

    let search = this.projectCtrl.value;
    if (!search) {
      this.projectLists = this.saveProject;
      return;
    } else {
      search = search.toLowerCase();
      this.projectLists = this.saveProject.filter(bank => bank.libelle.toLowerCase().indexOf(search) > -1);
    }
  }

  protected themeProject() {
    // console.log("calling themeProject");
    if(this.saveTheme == null){
      this.saveTheme = this.projectTags;
      // console.log("in themeProject", this.saveTheme);
    }else{
      // console.log("else themeProject", this.projectTags);
    }

    let search = this.themeCtrl.value;
    if (!search) {
      this.projectTags = this.saveTheme;
      return;
    } else {
      search = search.toLowerCase();
      this.projectTags = this.saveTheme.filter(bank => bank.libelle.toLowerCase().indexOf(search) > -1);
    }
  }
  

  getStatus(key){
    switch (key) {
      case 'HRD':
        return "DRH";
        break;
      case 'HR':
        return "RRH";
        break;
      case 'EMPLOYEE':
        return "Salarié";
        break;
      case 'CS':
        return "Manager";
        break;
      default:
        return key;
        break;
    }
  }

  getSkill(contact){
    var skillList = ''; 
    if (contact.skills.includes(1) == true){
      skillList += 'CGT ';
    } 
    if (contact.skills.includes(5) == true){
      skillList += 'CFDT ';
    }  
    if (contact.skills.includes(6) == true){
      skillList += 'FO ';
    }   
    if (contact.skills.includes(7) == true){
      skillList += 'CFE-CGC ';
    }   
    if (contact.skills.includes(8) == true){
      skillList += 'CFTC ';
    }   
    return skillList;
  }

  dataMap(data: any) {

    var strId = data['project']['@id']; 
    var splittedID = strId.split("/"); 
    var idProject = splittedID[splittedID.length-1];  
    var listTag : any[] = data['tags'];
    var date = data['dateCreated'];
    var isNewDay = this.isNewDay(date);

    var typeMessage = 'QUESTION';

    if (data['type'] == 'QUESTION') {
      typeMessage = 'Question';
    }
    else if (data['type'] == 'COMMENT'){
      typeMessage = 'Commentaire';
    }
    else if (data['type'] == 'ANSWER'){
      typeMessage = 'Réponse';
    }

    const message: any = {
      'id': idProject,
      'project': data['project'],
      'tag': (listTag[0] ? listTag[0].libelle  : '' ),
      'date': date,
      'typeParse': typeMessage,
      'type': data['type'],
      'private': data['isPrivate'],
      'sender': (data['author'] ? data['author']['fullName'] : ''),
      'recipient': (data['recipient'] ? data['recipient']['fullName'] : ''),
      'read': data['isMessageAnswered'],
      'deadline': data['deadline'],
      'number': data['number'],
      'smartNumber': data['smartNumber'],
      'filegeneric': data['filegeneric'],
      'isMessageDisabled': data['isMessageDisabled'],
      'isMessageAnswered': (data['isMessageAnswered'] === true ? 'Répondu' : 'Non répondu'),
      'isNewDay' : isNewDay,
      'isMessageGlobal' : data['isMessageGlobal']
    };

    return message;
  }

 public getAllMessages(searchChoose) {

    this.paginator.pageIndex = 0;
    this.filterChoosen = searchChoose;

    merge(this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          return this.authService.getAllMessages(this.paginator.pageIndex, this.perPage, this.filterChoosen);
        }),
        map(data => {
          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
          this.isRateLimitReached = false;    

          this.resultsLength = data['hydra:totalItems']; 

          return data['hydra:member'];
        }),
        catchError(() => {
          this.isLoadingResults = false;
          // Catch if the GitHub API has reached its rate limit. Return empty data.
          this.isRateLimitReached = true;
          return observableOf([]);
        })
      ).subscribe(data => { 
         
        //console.log('contenu de data '+ JSON.stringify(data));
        this.messages = [];
        this.lastDate = new Date('01/01/4970');
        data.forEach(element => {
          if (element['isMessageDisabled'] == false && element['project'].typeProject != 'MANAGER' && element['project'].typeProject != 'CHO' &&
            element['project'].typeProject != 'CGT' && element['project'].typeProject != 'CFDT' && element['project'].typeProject != 'CFE-CGC' && element['project'].typeProject != 'CFTC' && element['project'].typeProject != 'FO') {
            this.messages.push(this.dataMap(element));
          }
        });

        let afterIsNewDay = false;
        this.messages.reverse().forEach((element, index) => {
          if(afterIsNewDay || index == 0){
            this.messages[index]["afterIsNewDay"] = true;
            afterIsNewDay = false;
            //console.log("afterIsNewDay")
          }
          if(element.isNewDay == true){
            afterIsNewDay = true;
          }
        });

        this.dataSource = this.messages.reverse(); 
         

      });

  }

  isRoundFirst(afterIsNewDay, isNewDay){
    if(afterIsNewDay && isNewDay){
      return "divcontaine afterIsNewDayBL afterIsNewDayTL";
    }else if(afterIsNewDay){
      return "divcontaine afterIsNewDayBL";
    }else if(isNewDay){
      return "divcontaine afterIsNewDayTL";
    }
    return "divcontaine";
  }

  isRoundLast(afterIsNewDay, isNewDay){
    if(afterIsNewDay && isNewDay){
      return "divcontaine afterIsNewDayBR afterIsNewDayTR";
    }else if(afterIsNewDay){
      return "divcontaine afterIsNewDayBR";
    }else if(isNewDay){
      return "divcontaine afterIsNewDayTR";
    }
    return "divcontaine";
  }

  isNewDay(messageDate){
    let currentDate = new Date(messageDate.substring(0,10)); 
    //console.log('this.lastDate ==> '+this.lastDate);
    //console.log('currentDate ==> '+currentDate);
    if(currentDate < this.lastDate)
    {
      // console.log('Une section ==> '+ currentDate);
      this.lastDate = currentDate;
      return true;
    } 
    else
    { 
      this.lastDate = currentDate; 
      return false;
    }
  }

  getContact(){
    //this.loadContact();
    this.loadContactExp();
    this.loadContactRecip();
  }

  loadContactExp(){
  
    this.authService.getContactsExp().subscribe(res => {
       
      this.contactsExp = [];
      this.contactsExp = res['hydra:member'];

  
      if(this.contactsExp.length && this.contactsExp[0].id != this.currentUser.id){
        this.contactsExp.unshift(this.currentUser);
      }

      this.contactsExp.sort(this.compare);
     
    }); 
  }

  loadContactRecip(){

    this.authService.getContactsRecip().subscribe(res => {
      
      this.contactsRecip = [];
      this.contactsRecip = res['hydra:member'];

      if(this.contactsRecip.length && this.contactsRecip[0].id != this.currentUser.id){
        this.contactsRecip.unshift(this.currentUser);
      }

      this.contactsRecip.sort(this.compare);
      
    }); 
  }

  loadContact(url?: string){
    url = url ? url : undefined;
    this.authService.getContacts(url).subscribe(res => {
      this.contacts = this.contacts.concat(res['hydra:member']);
      
      //this.contacts = res['hydra:member'];
      if(this.contacts.length && this.contacts[0].id != this.currentUser.id){
        this.contacts.unshift(this.currentUser);
      }
      this.contacts.sort(this.compare);

      if(res['hydra:view']['hydra:next']){
        this.loadContact(res['hydra:view']['hydra:next'].replace("/api", ""));
      }
    }); 
  }

  compare( a, b ) {
    if ( a.familyName < b.familyName ){
      return -1;
    }
    if ( a.familyName > b.familyName ){
      return 1;
    }
    return 0;
  }

  loadProject(url?: string){
    url = url ? url : undefined;
    this.authService.getProjectsWithMessage(url).subscribe(res => {
      this.tmpprojectLists = this.tmpprojectLists.concat(res['hydra:member']);
      if(res['hydra:view']['hydra:next']){
        this.loadProject(res['hydra:view']['hydra:next'].replace("/api", ""));
      }else if (res['hydra:totalItems'] === this.tmpprojectLists.length) {
        var salaryArray = [];
        var otherArray = [];
        this.projectLists = [];
        for (let i = 0; i < this.tmpprojectLists.length; i++) {           
          if (!this.tmpprojectLists[i].typeProject) {
            salaryArray.push(this.tmpprojectLists[i]);
          }
          else
          {
            if (this.tmpprojectLists[i].typeProject != 'MANAGER' && this.tmpprojectLists[i].typeProject != 'CHO' && this.tmpprojectLists[i].typeProject != 'CGT' 
              && this.tmpprojectLists[i].typeProject != 'CFDT' && this.tmpprojectLists[i].typeProject != 'CFE-CGC' && this.tmpprojectLists[i].typeProject != 'CFTC' && this.tmpprojectLists[i].typeProject != 'FO') {
              otherArray.push(this.tmpprojectLists[i]);
            }
          }
        }
        salaryArray.sort(function (a, b) {
          if(a.libelle < b.libelle) { return -1; }
          if(a.libelle > b.libelle) { return 1; }
        });
        this.projectLists = this.projectLists.concat(salaryArray);
        this.projectLists = this.projectLists.concat(otherArray);  
      }
    }); 
  }

  getProjectInfo(idProject){
    this.projectId = idProject;
    /*this.authService.getTheProject(idProject).subscribe(res => {
      this.projectTags = res['tags'];
    }, (error) => {
       //console.log(' getTheProject subscribe error '+error.statusText);
    });*/
    if(idProject != null){

     this.authService.getTheProject(idProject).subscribe(res => {
        var tmpArrayUser = res['userProject'];
        this.contactsExp = [];
        this.contactsRecip = [];
        for (var i = 0; i < tmpArrayUser.length; i++) {
          if(tmpArrayUser[i].user){
            this.contactsExp.push(tmpArrayUser[i].user);
            this.contactsRecip.push(tmpArrayUser[i].user);
          }
        }
        this.contactsExp.sort(this.compare);
        this.contactsRecip.sort(this.compare);
      }, (error) => {
           //console.log(' getTheProject subscribe error '+error.statusText);
      });

      this.authService.getProjectSuject(idProject).subscribe(res => {
        this.projectTags = res['hydra:member'];
      }, (error) => {
         //console.log(' getTheProject subscribe error '+error.statusText);
      });

    }

  }


  getExpDestFromTag(TagID){
    
    this.authService.getExpFormSujetID(this.projectId, TagID).subscribe(res => {
      var tmpArrayUser = res['hydra:member'];
      this.contactsExp = [];
      for (var i = 0; i < tmpArrayUser.length; i++) {
        if(tmpArrayUser[i].user){
          this.contactsExp.push(tmpArrayUser[i].user);
        }
      }
      this.contactsExp.sort(this.compare);
    }, (error) => {
        //console.log(' getTheProject subscribe error '+error.statusText);
    });

    this.authService.getDestFormSujetID(this.projectId, TagID).subscribe(res => {
      var tmpArrayUser = res['hydra:member'];
      this.contactsRecip = [];
      for (var i = 0; i < tmpArrayUser.length; i++) {
        if(tmpArrayUser[i].user){
          this.contactsRecip.push(tmpArrayUser[i].user);
        }
      }
      this.contactsRecip.sort(this.compare);
    }, (error) => {
        //console.log(' getTheProject subscribe error '+error.statusText);
    });

  }


  launchSearch(){

    this.modeDoc = false;

    this.chosenFilter = { 
      transmitter: this.transmitter,
      recipient: this.recipient,
      projectId: this.projectId,
      dateBefore: this.dateBefore,
      dateAfter: this.dateAfter,
      tag: this.tag,
      statut: this.statut, 
      type: this.type,
      keyWord: (this.keyWord ? this.keyWord.trim() : null),
      smartNumber: this.smartNumber
    };
    //console.log(' launchSearch '+ JSON.stringify(this.chosenFilter) );
    this.EventLaunchSearch.emit(this.chosenFilter);

  }

  launchSearchDoc(){

    this.modeDoc = true;

    this.chosenFilter = { 
      transmitter: this.transmitter,
      recipient: this.recipient,
      projectId: this.projectId,
      dateBefore: this.dateBefore,
      dateAfter: this.dateAfter,
      tag: this.tag,
      statut: this.statut,
      type: this.type, 
      keyWord: (this.keyWord ? this.keyWord.trim() : null),
      smartNumber: this.smartNumber
    };
    //console.log(' launchSearchDoc '+ JSON.stringify(this.chosenFilter) );
    this.EventLaunchSearchDoc.emit(this.chosenFilter);

  }

  public resetSearch(){

    this.transmitter = null;
    this.recipient = null;
    this.projectId = null;
    this.dateBefore  = null;
    this.dateAfter  = null;
    this.tag = null;
    this.statut = null;
    this.type = null;
    this.keyWord = null;
    this.smartNumber = null;

    this.refreshInfoFilter();

    if(this.modeDoc == false) 
    {
      this.launchSearch();
    }
    else
    {
      this.launchSearchDoc();
    }

  }


  public refreshInfoFilter(){
    this.getContact();
    this.loadProject();  
  }

}


