import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth-service/auth.service';

@Component({
  selector: 'app-statistique',
  templateUrl: './statistique.component.html',
  styleUrls: ['./statistique.component.scss']
})
export class StatistiqueComponent implements OnInit {

  dataStats:any;

  constructor(public authService: AuthService) { }

  ngOnInit() {
  	this.getStatistique();
  }

  getStatistique(){
    this.authService.getStat().subscribe(
    (success) => {

      this.dataStats = success['hydra:member'];
    },
    (error) => {
      alert('Erreur ! : ' + error)
    });
  }

}
