import {first} from 'rxjs/internal/operators';
import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth-service/auth.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-setnew',
  templateUrl: './setnew.component.html',
  styleUrls: ['./setnew.component.scss']
})
export class SetnewComponent implements OnInit {

  Rtoken: any = '';
  password: any = '';
  passwd_confirm: any = '';
  message: any = '';
  error: any = '';

  hide = true;
   
  constructor( private authService: AuthService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
    if (this.authService.logIn) {
      this.router.navigate(['/']);
    }
    //console.log('dans le set new component '+this.route.params['value'].token);
    this.Rtoken = this.route.params['value'].token;
  }

  setnewPass() {
    //console.log('Canging password with ' + this.password);

    if (this.password.trim() != this.passwd_confirm.trim() ) {
      alert("Votre mot de passe est différent de celui entré dans l'espace de confirmation");
      return;
    }

    this.authService.setNew( this.password, this.Rtoken )
      .pipe(first())
      .subscribe(
        () => {
          this.message = 'Votre mot de passe a été modifié, vous pouvez maintenant vous connecter avec le nouveau mot de passe.';
          // console.log(data);
        },
        err => {
          const error = err.error;
          this.error = error['hydra:description'];
          //console.log(this.error);
        }
      );
  }

}
