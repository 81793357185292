import { Component, OnInit, ChangeDetectorRef, ElementRef, ViewChild, Input, OnDestroy} from '@angular/core';
import { AuthService } from 'src/app/services/auth-service/auth.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { environment } from '../../../environments/environment';
import { DatePipe } from '@angular/common';
import { MessageService } from 'src/app/services/message/message.service';
import { CoreServiceService } from 'src/app/services/core/core-service.service';
import * as introJs from 'intro.js';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';


@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit, OnDestroy {
  edit = false;
  fname = '';
  lname = '';
  dob = '';
  jobT = '';
  email = '';
  oldemail = '';
  gender = 'male';
  mobilePro = '';
  mobilePerso = '';
  region = '';
  description = '';
  profilUser = '';
  phone = '';
  oldPassword = '';
  newPassword = '';
  img: any;
  idUser = null;
  contactInfo: any;
  imageFil = null;
  hide = true;
  hidebis = true;
  mdpValues = '';
  isCurrentuser = true;
  protected _onDestroy = new Subject<void>();

  introJS = introJs();


  constructor( private authService: AuthService,
    private route: ActivatedRoute,
     private cd: ChangeDetectorRef,
     public datepipe: DatePipe,
     private messageService: MessageService,
     private coreService: CoreServiceService) {

    this.route.paramMap.subscribe(params => {
      // console.log('getCurrentProject paramètre id ==> '+params.get('ID'));
      // console.log('getCurrentProject paramètre specMsg ==> '+params.get('specMsg'));
      this.idUser = params.get('ID');

    });

  }

  getCurrentUser() {
   // console.log('getCurrentUser paramètre id ==> '+this.idUser);
    this.authService.getInfoContact(this.idUser)
    .subscribe( dataContact => {
      this.contactInfo = dataContact;

      /* this.img = (this.contactInfo.imageUrl ? this.getAvatarlUrl(this.contactInfo.imageUrl)
       : '../../../../assets/img/default-avatar.jpg'); */

      if (this.contactInfo.imageUrl) {
        this.img = this.getAvatarlUrl(this.contactInfo.imageUrl);
      } else {
        // this.img = '../../../../assets/img/default-avatar.png';

        if (this.contactInfo.gender === 'male') {
           this.img = '../../../../assets/img/employee.png';
        } else if (this.contactInfo.gender === 'female') {
          this.img = '../../../../assets/img/businesswoman.png';
        } else {
          this.img = '../../../../assets/img/employee.png';
        }

      }

      this.fname =  this.contactInfo.givenName;
      this.lname =  this.contactInfo.familyName;
      if (this.contactInfo.birthDate) {
        if (this.contactInfo.birthDate.date) {
          this.dob = this.contactInfo.birthDate.date;
        } else {
          this.dob = this.contactInfo.birthDate;
        }
      }
      // this.dob =  this.contactInfo.birthDate;
      this.jobT =  this.contactInfo.jobTitle;
      this.email =  this.contactInfo.email;
      this.oldemail = this.contactInfo.email;
      this.gender =  this.contactInfo.gender;
      this.description = this.contactInfo.description;
      this.phone =  this.contactInfo.telephone;
      this.mobilePro = this.contactInfo.mobilePro;
      this.mobilePerso = this.contactInfo.mobilePerso;
      this.profilUser = this.contactInfo.profil;

    }, (error) => {
        console.log('Uh-oh, an error occurred! : ' + JSON.stringify(error));
        alert('une erreur est survenue');
    });

  }


  getMyInfo() {

    const userData = JSON.parse(localStorage.getItem('currentUser'));
    // console.log(' Avatar --> '+userData.imageUrl);
    // this.img = (userData.imageUrl ? this.getAvatarlUrl(userData.imageUrl) : '../../../../assets/img/default-avatar.jpg');

    if (userData.imageUrl) {
      this.img = this.getAvatarlUrl(userData.imageUrl);
    } else {
      // this.img = '../../../../assets/img/default-avatar.png';
      if (userData.gender === 'male') {
         this.img = '../../../../assets/img/employee.png';
      } else if (userData.gender === 'female') {
        this.img = '../../../../assets/img/businesswoman.png';
      } else {
        this.img = '../../../../assets/img/employee.png';
      }
    }


    this.fname = userData.givenName;
    this.lname = userData.familyName;
    // console.log(' anniversaire -> '+JSON.stringify(userData.birthDate));
    if (userData.birthDate) {
      if (userData.birthDate.date) {
        this.dob = userData.birthDate.date;
      } else {
        this.dob = userData.birthDate;
      }
    }
    this.jobT = userData.jobTitle;
    this.email = userData.email;
    this.oldemail = userData.email;
    this.gender = userData.gender;
    this.description = userData.description;
    this.phone = userData.telephone;
    this.profilUser = userData.profil;
    this.mobilePro = userData.mobilePro;
    this.mobilePerso = userData.mobilePerso;

  }


  onKey(event: any) { // without type info
    this.mdpValues += event.target.value + ' | ';
  }

  ChoseImage(event): void {

    const reader = new FileReader();
    let files = event.files;

    // loop through files
    for (let i = 0; i < files.length; i++) {
      this.imageFil = files[i];
    }

    reader.readAsDataURL(this.imageFil);

    reader.onload = () => {
      // need to run CD since file load runs outside of zone
      this.cd.markForCheck();
      this.img = reader.result;
    };

    if (this.imageFil) {
      this.authService.udapteUserImage(this.imageFil).subscribe( data => {
         // console.log('retour saveProfile '+JSON.stringify(data));
        this.messageService.nextReloadUserInfo();
        this.getMyInfo();
      }, (error) => {
          // console.log('Uh-oh, an error occurred! : ' +JSON.stringify(error));
          alert('une erreur est survenue');
      });

    }

  }

  getAvatarlUrl(avatarLink) {
    return environment.server_url + avatarLink;
  }

  saveProfile() {

    let latest_date = null;

    if (this.dob) {
      latest_date = this.datepipe.transform(this.dob, 'dd-MM-yyyy');
    }

    if ((this.newPassword.trim() != '' && this.oldPassword.trim() != '') && (((this.oldPassword && this.oldPassword.trim().length > 0) || (this.newPassword && this.newPassword.trim().length > 0)) && (this.oldPassword.trim() == this.newPassword.trim())) ) {
      alert("Le nouveau mot de passe doit être différent de l'ancien");
      return;
    }

    // console.log('getCurrentUser paramètre id ==> '+this.idUser);
    this.authService.udapteUser((this.fname ? this.fname : ''), (this.lname ? this.lname : ''),
     (latest_date ? latest_date : null), (this.jobT ? this.jobT : ''), (this.email ? this.email : ''),
      (this.gender ? this.gender : ''), (this.description ? this.description : ''),
       (this.phone ? this.phone : ''), (this.oldPassword ? this.oldPassword : ''),
        (this.newPassword ? this.newPassword : ''), (this.mobilePro ? this.mobilePro : ''),
         (this.mobilePerso ? this.mobilePerso : '')).subscribe( data => {  
       // console.log('retour saveProfile '+JSON.stringify(data));
       this.edit = false;
       if (this.oldemail != this.email) {
          alert("Votre profil vient d'être modifié. Veuillez vos reconnecter afin d'actualiser votre email.");
          this.authService.logout();
          return;
       }
       this.getMyInfo();
       this.messageService.nextReloadUserInfo();
       alert('Modification(s) enregistré');
    }, (error) => {
        // console.log('Uh-oh, an error occurred! : ' +JSON.stringify(error));
          //console.log('Uh-oh, an error occurred! : ' +error.error['hydra:description'] );      
          alert('Une erreur est survenue : '+error.error['hydra:description']);
    });


    if (this.imageFil) {
      this.authService.udapteUserImage(this.imageFil).subscribe( data => {
         // console.log('retour saveProfile '+JSON.stringify(data));
        this.messageService.nextReloadUserInfo();
        this.getMyInfo();
      }, (error) => {
          //console.log('Uh-oh, an error occurred! : ' +error.error['hydra:description'] );     
          alert("une erreur est survenue lors de la modification de l'image de profil");
      });

    }

  }

  getStatus(key) {
    switch (key) {
      case 'HRD':
        return 'D.R.H.';
        break;
      case 'HR':
        return 'R.H.';
        break;
      case 'EMPLOYEE':
        return 'Salarié';
        break;
      default:
        return key;
        break;
    }
  }

  ngOnInit() {
    const currentUser  = JSON.parse(localStorage.getItem('currentUser'));
    this.isCurrentuser = (currentUser.id === this.idUser || this.idUser == null) ? true : false;
    if (this.idUser) {
      this.getCurrentUser();
    } else {
      this.getMyInfo();
    }

    this.coreService.startDemo
    .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.startDemo();
      });

  }


  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
    this.introJS.exit();
  }

  startDemo() {
    this.introJS.setOptions({
        steps: [
          {
            intro: 'Welcome! 👋'
          },
          {
            element: '#step30',
            intro: 'Vous pouvez changez votre photo de profil!',
            position: 'auto'
          },
          {
            element: '#step31',
            intro: 'Vous pouvez modifiez vos infos!',
            position: 'auto'
          }
        ],
        showProgress: true,
        hideNext: true,
        hidePrev: true,
        showStepNumbers: true,
        keyboardNavigation: true,
        showButtons:  true,
        showBullets:  true
      }
    ).start();
  }

}
