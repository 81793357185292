import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth-service/auth.service';
import { first } from 'rxjs/operators';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';

@Component({
  selector: 'app-activate-accountcmp',
  templateUrl: './activate-accountcmp.component.html',
  styleUrls: ['./activate-accountcmp.component.scss']
})
export class ActivateAccountcmpComponent implements OnInit {

  hide = true;
  error = '';
  token = '';
  message = "Votre compte est en cours d'activation";
  constructor(
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    ) { 
  }

  ngOnInit() {
    if (this.authService.logIn) {
      this.router.navigate(['/']);
    }
    this.route.paramMap.subscribe(params => {
      this.token = params.get('token');
    	this.activateAccount(this.token)
    });

  }


 activateAccount(userToken){
 	console.log('your userToken ' + userToken);
   this.authService.confirm(userToken)
    .subscribe(
      data => {
        //console.log('you are logging in ' + this.email + ' ' + this.password);
        this.message = 'Votre compte est activé. Vous pouvez maintenant vous connectez.'
        //alert('Votre compte est activé, vous pouvez maintenant vous connecter.');
      },
      err => {
        //console.log('login erreur ===> '+JSON.stringify(err));
        const error = err.error;
        const errorS = error.message;
        const errorD = error.detail;
        const errordescription = err['hydra:description'];
        if (errordescription) {
          this.error = errordescription;
        }
        else if (errorD){  
          this.error = errorD;
        }else if (errorS) {
          this.error = errorS;
        } else {
          this.error = error;
        }

  
        //console.log(error);
        // console.log(errorS);
        // console.log(errorD);
        // this.loading = false;
      }
    );
  }


}
