import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { AuthService } from 'src/app/services/auth-service/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { DashboardcontentComponent } from '../content/dashboardcontent.component';
import { SingleProjectComponent } from '../single-project/single-project.component';
import { SearchComponent } from '../search/search.component';
import { GedComponent } from '../ged/ged.component';
import { CoreServiceService } from 'src/app/services/core/core-service.service';
import { MatSidenav } from '@angular/material';
import { MessageService } from 'src/app/services/message/message.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  //num = 0;
  ID: any = '';
  GED: boolean = false;
  hideNav = localStorage.getItem('hideNav') ? localStorage.getItem('hideNav') : 0;
  subscription: Subscription;

  @ViewChild('dashboardcontent') dashboardcontent: DashboardcontentComponent;
  @ViewChild('singleproject') singleproject: SingleProjectComponent;
  //@ViewChild('appsearch') appsearch: SearchComponent;
  @ViewChild('appged') appged: GedComponent;
  @ViewChild('sidenav') public sidenav: MatSidenav;

  constructor(private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private messageService: MessageService,
    private coreService: CoreServiceService) {
    // this.Arr = Array;
    //this.num = 20;
  }

  ngOnInit() {
    if (!this.ID) {
      this.ID = this.route.params['value'].ID;
      //this.dashboardcontent.iDProjet = this.ID;
      if(this.singleproject){
        this.singleproject.iDProjet = this.ID;
      }
      //console.log(' this.ID de app-dashboard => '+this.ID);
    }

    if(this.hideNav == 1) {
      //console.log("close");
      setTimeout(() => {
        if (this.sidenav) {
          this.sidenav.close();
        }
      }, 1000);

    }else{
      //console.log("open");
      setTimeout(() => {
        if (this.sidenav) {
          this.sidenav.open();
        }
      }, 1000);
    }
    //console.log("ngOnInit")
    this.coreService.toggleSidebar.subscribe((res) => {
      localStorage.setItem('hideNav', res);
      //console.log(res)
      this.hideNav = res;
      if (this.sidenav) {
        this.sidenav.toggle();
      }
    });

    this.authService.obCommonProjectChosen.subscribe((res) =>{
      this.onChosenProject(res);
    });

    this.subscription = this.messageService.getRefreshFiltre().subscribe(() => {
     this.dashboardcontent.refreshInfoFilter();
      if(this.singleproject){
        this.singleproject.refreshInfoFilter();
      }
    });

  }

  onLaunchSearch(chosenFilter) {

    //console.log(' chosenFilter -)--) '+ JSON.stringify(chosenFilter));

    this.GED = false;

    if(this.ID)
    {
      this.singleproject.filterMessage(chosenFilter);
      this.appged.reset();
    }
    else
    {
      //console.log('dans le else dashboardcontent --> '+ this.dashboardcontent);
      this.dashboardcontent.getAllMessages(chosenFilter);
      this.appged.reset();
    }

  }

  onLaunchSearchDoc(chosenFilter) {
    this.GED = true;
    this.ID = this.route.params['value'].ID;
    this.appged.filterFichier(chosenFilter, this.ID, true);
  }

  onLaunchSearchDocDashboard(chosenFilter) {
    this.GED = true;
    this.ID = null;
    this.appged.filterFichier(chosenFilter, chosenFilter.projectId, true);
  }

  onChosenProject(IdProjectChosen) {
    this.GED = false;
    this.ID = IdProjectChosen;
    this.appged.reset();
    //this.dashboardcontent.projecthasChange(IdProjectChosen);
    this.singleproject.projecthasChange(IdProjectChosen);
    //this.appsearch.iDProjet = IdProjectChosen;
    //this.appsearch.resetSearch();
  }

}
