import { Component, OnInit, ViewChild, ElementRef, Inject, ChangeDetectorRef, OnDestroy, Input, QueryList, EventEmitter, Output} from '@angular/core';
import { AuthService } from 'src/app/services/auth-service/auth.service';
import { MessageService } from 'src/app/services/message/message.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import {MatPaginator} from '@angular/material/paginator';
import { MatOption } from '@angular/material';
import {merge, Observable, of as observableOf, Subject, Subscription} from 'rxjs';
import {catchError, map, startWith, switchMap, takeUntil} from 'rxjs/operators';
import { FormBuilder, FormGroup, Validators, FormControl, FormGroupDirective, NgForm} from '@angular/forms';
import { requiredFileType } from '../../../app-file-upload/upload-file-validators';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {MatTableDataSource} from '@angular/material/table';
import {ErrorStateMatcher} from '@angular/material/core';
import { ConfirmationDialogComponent } from '../../../components/shared/confirmation-dialog/confirmation-dialog.component';
import { Lightbox } from 'ngx-lightbox';
import { environment } from '../../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import * as introJs from 'intro.js';
import { CoreServiceService } from 'src/app/services/core/core-service.service';

export interface searchFilter {
  transmitter: string;
  recipient: string;
  projectId: string;
  dateBefore: any;
  dateAfter: any;
  tag: string;
  statut: boolean;
  type: string;
  keyWord: string;
  smartNumber: string;
}

@Component({
  selector: 'app-single-project',
  templateUrl: './single-project.component.html',
  styleUrls: ['./single-project.component.scss']
})
export class SingleProjectComponent implements OnInit, OnDestroy {

  displayedColumns: string[] = ['Message'];
  @Input() ged: boolean = false;
  introJS = introJs();

  isLoadingResults = true;
  isRateLimitReached = false;
  private _album = [];

  writeMode = -1; // 1 = write answer, 2 = comment
  openWriteFace = false;
  showMessages = true;
  hiddenSearchEngin = false;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  //@ViewChild('divHeaderTable') divHeaderTable: ElementRef;
  @ViewChild('divComSpecMsg') divComSpecMsg: ElementRef;
  @ViewChild('divConvSpecMsg') divConvSpecMsg: ElementRef;
  @ViewChild('theForm') theForm: FormGroupDirective;
  @ViewChild('inputMedia') inputMedia: ElementRef;
  @ViewChild('writeMsg') writeMsg: ElementRef;
  @ViewChild('allSelected') private allSelected: MatOption;

  resultsLength = 0;
  perPage = 10;
  userAdmin = false;
  dataSource : any[];
  specMsg: any;
  specMsgType: any;
  ID: any;
  projectFull: any;
  projectUsers = [];
  projectTags  = [];
  arrayQuickAnswer = [];
  newTagsArray: any;
  project: string;
  isCommentActivated = true;
  messageInfo: any;
  chosenFilterProject = null;
  expanded: boolean = true;
  limit: number = 200;
  hasMessage = false;
  currentUser: any;
  currentUserInfo: any;
  sendMessageForm: FormGroup;
  sendanswerForm: FormGroup;
  formComment: FormGroup;
  currentDate = new Date();
  submitted = false;
  files: any = [];
  lastFaceNumber = 1;
  showRedBar = false;
  showTool = false;
  userIsManager = false;
  mySelections: string[];

  /*private envoyerObs = new Subject();
  public envoyerObs$ = this.envoyerObs.asObservable();*/

  private refreshPageObs = new Subject();
  public refreshPageObs$ = this.refreshPageObs.asObservable();
  public isReady:boolean = false;
  lang='fr';
  public curUser = null;


  public iDProjet: any;

  @Output() EventLaunchSearch = new EventEmitter();
  @Output() EventLaunchSearchDoc = new EventEmitter();

  transmitter = null;
  recipient = null;
  projectId = null;
  dateBefore = null;
  dateAfter = null;
  tag = null;
  statut = null;
  type = null;
  keyWord = null;
  smartNumber = null;
  modeDoc = false;
  contactsExp: Array<any> = [];
  contactsRecip: Array<any> = [];
  projectLists: Array<any> = [];

  chosenFilter: searchFilter;
  public recipientCtrl: FormControl = new FormControl();
  public emetterCtrl: FormControl = new FormControl();
  public themeCtrl: FormControl = new FormControl();
  public choseDest: FormControl = new FormControl();
  protected _onDestroy = new Subject<void>();
  saveCtc = null;
  saveCtR = null;
  saveTheme = null;
  saveDest = null;
  checked = false;

  constructor(
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    public  dialog: MatDialog,
    private messageService: MessageService,
    private translate: TranslateService,
    private _lightbox: Lightbox,
    private coreService: CoreServiceService) {

    this.sendMessageForm = this.formBuilder.group({
      subProject: ['', Validators.required],
      newSubProject: '',
      recipient: ['', Validators.required],
      inCopy: [],
      private: false,
      deadLine: '',
      messageToSend: ['', [Validators.required, Validators.minLength(1)]],
      files: [null, [requiredFileType(['png','jpeg','jpg','mov','mp4'])]]
    });

    this.sendanswerForm = this.formBuilder.group({
      answerMessage: ['', [Validators.required, Validators.minLength(1)]],
      files: [null, [requiredFileType(['png','jpeg','jpg','mov','mp4'])]]
    });

    this.formComment = this.formBuilder.group({
      commentMessage: ['', [Validators.required, Validators.minLength(1)]],
      files: [null, [requiredFileType(['png','jpeg','jpg','mov','mp4'])]]
    });

    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    delete currentUser.token;
    delete currentUser.refresh_token;
    delete currentUser.roles;
    this.curUser = currentUser;
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
    this.introJS.exit();
  }

  startDemo() {
    this.introJS.setOptions(
    {
      steps: [
        {
          intro: 'Welcome! 👋'
        },
        {
          element: document.querySelector('#titleConv'),
          intro: 'Bienvennue dans votre espace de discussion!'
        },
        {
          element: document.querySelector('#writeNewFace'),
          intro: 'Cliquez içi pour créer un message!'
        },
        {
          element: document.querySelector('#step30'),
          intro: 'Tapez içi pour crhercher un message!'
        },
        {
          element: document.querySelector('#step31'),
          intro: 'Tapez içi pour faire rénisialiser!'
        },
        {
          element: document.querySelector('#step32'),
          intro: 'Tapez içi pour consulter votre bibliothèque!'
        },
        {
          element: document.querySelector('#step33'),
          intro: 'Tapez içi pour crhercher un message!'
        }
      ],
      showProgress: true,
      hideNext: true,
      hidePrev: true,
      showStepNumbers: true,
      keyboardNavigation: true,
      showButtons:  true,
      showBullets:  true
    }
    ).start();
  }


  fcthiddenSearchEngin(){
    if (this.hiddenSearchEngin == false)
      this.hiddenSearchEngin = true;
    else
      this.hiddenSearchEngin = false;
  }

  protected themeProject() {
    if(this.saveTheme == null){
      this.saveTheme = this.projectTags;
    }

    let search = this.themeCtrl.value;
    if (!search) {
      this.projectTags = this.saveTheme;
      return;
    } else {
      search = search.toLowerCase();
      this.projectTags = this.saveTheme.filter(bank => bank.libelle.toLowerCase().indexOf(search) > -1);
    }
  }


  protected choseDestProject() {
    if(this.saveDest == null){
      this.saveDest = this.projectUsers;
    }

    let search = this.choseDest.value;
    if (!search) {
      this.projectUsers = this.saveDest;
      return;
    } else {
      search = search.toLowerCase();
      this.projectUsers = this.saveDest.filter(bank => bank.user.fullName.toLowerCase().indexOf(search) > -1);
    }
  }


  protected filterExp() {

    if(this.saveCtc == null){
      this.saveCtc = this.contactsExp;
    }

    let search = this.emetterCtrl.value;
    if (!search) {
      this.contactsExp = this.saveCtc;
      return;
    } else {
      search = search.toLowerCase();
    }

    this.contactsExp = this.saveCtc.filter(bank => bank.fullName.toLowerCase().indexOf(search) > -1);
  }

  protected filterRecipientCtrl() {

    if(this.saveCtR == null){
      this.saveCtR = this.contactsRecip;
    }

    let search = this.recipientCtrl.value;
    if (!search) {
      this.contactsRecip = this.saveCtR;
      return;
    } else {
      search = search.toLowerCase();
    }

    this.contactsRecip = this.saveCtR.filter(bank => bank.fullName.toLowerCase().indexOf(search) > -1);
  }

  ngOnInit() {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'))['id'];
    this.curUser = JSON.parse(localStorage.getItem('currentUser'));
   
    this.emetterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterExp();
      });

    this.recipientCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterRecipientCtrl();
      });

      
    this.themeCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.themeProject();
      });

    this.choseDest.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.choseDestProject();
    });



    this.messageService.getOpenListUser().subscribe((data) => {
      this.fctListeMembre();
    });

    this.route.paramMap.subscribe(params => {
      //console.log('getCurrentProject paramètre id ==> '+params.get('ID'));
      //console.log('getCurrentProject paramètre specMsg ==> '+params.get('specMsg'));
      //console.log("ID", params.get('ID'));
      // console.log("projectGlobal", this.curUser);

     
      //console.log('le user est manager ==> '+this.userIsManager);

      this.currentUserInfo = JSON.parse(localStorage.getItem('currentUser'));

      this.getCurrentUserInfo();

      this.specMsg = params.get('specMsg');
      this.specMsgType = params.get('type');
      if (this.specMsg) {
        this.showRedBar = true;
        this.authService.obprojectChosen.next(this.specMsg);
      }
      this.ID = params.get('ID');
      this.resetQuestionFrom();
      this.resetAnswerFrom();
      this.resetCommentForm();
      this.writeMode = -1;
      this.openWriteFace = false;
      this.showMessages = true;
      this.submitted = false;
      this.paginator.pageIndex = 0;
      this.dataSource = [];
      this.resultsLength = 0;
      this.lastFaceNumber = 1;
      this.chosenFilterProject = null;
      this.isReady = false;
      this.userAdmin = false;

      if (this.messageService.getShowCreateProjectTuto() == false) {
        this.showTool = this.messageService.getShowProjectTutobis();
      }

      if(this.ID) this.getCurrentProject();

      if(this.ID) this.projecthasChange(this.ID);

      setTimeout(() => {
       window.scroll(0,0);
      }, 500);

    });


     this.messageService.getShowCreateProjectTutoIsFalse().subscribe(( ) =>{
      this.showTool = this.messageService.getShowProjectTutobis();
    });

    this.messageService.getTranslate().subscribe((data) => {
      this.lang = data;
    });
    //this.divHeaderTable.nativeElement.scrollIntoView();

    this.expanded = true;
    this.ged = false;

    this.messageService.getWriteMessage().subscribe(
      (data)=>{
        this.fctEcrire();
      }
    )

    this.getAnswerListe();

    this.getContact();
    if(this.iDProjet != null) this.getSujet(this.iDProjet);

    this.authService.obprojectChosen.subscribe((idMessage) =>{
      this.smartNumber = idMessage;
      //console.log('dans le smarnumber idmessage ==> '+idMessage);
    });

    this.coreService.startDemo
    .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.startDemo();
      });

  }


  fctUserIsManager(project){
    if (project.manager && (project.manager.id == this.currentUserInfo.id)){
      return true;
    }
    return false;
  }

  itsMyProject(project){
    if (!project.typeProject) {
      for (var i = 0; i < project.userProject.length; i++) {
        if ((project.userProject[i].profil == 'EMPLOYEE')  && project.userProject[i].user && (project.userProject[i].user.id == this.currentUserInfo.id)) {
          //console.log('mon project est ==>', project.libelle);
          return true;
        }
      }
    }
    return false;
  }

  getExpDestFromTag(TagID){

    this.authService.getExpFormSujetID(this.projectId, TagID).subscribe(res => {
      var tmpArrayUser = res['hydra:member'];
      this.contactsExp = [];
      for (var i = 0; i < tmpArrayUser.length; i++) {
        if(tmpArrayUser[i].user){
          this.contactsExp.push(tmpArrayUser[i].user);
        }
      }
      this.contactsExp.sort(this.compare);
    }, (error) => {
        //console.log(' getTheProject subscribe error '+error.statusText);
    });

    this.authService.getDestFormSujetID(this.projectId, TagID).subscribe(res => {
      var tmpArrayUser = res['hydra:member'];
      this.contactsRecip = [];
      for (var i = 0; i < tmpArrayUser.length; i++) {
        if(tmpArrayUser[i].user){
          this.contactsRecip.push(tmpArrayUser[i].user);
        }
      }
      this.contactsRecip.sort(this.compare);
    }, (error) => {
        //console.log(' getTheProject subscribe error '+error.statusText);
    });
  
  }

  getCurrentUserInfo(){
   // console.log('getCurrentUser paramètre id ==> '+this.idUser);
    this.authService.getInfoContact(this.currentUserInfo.id)
    .subscribe( dataContact => {
      this.currentUserInfo = dataContact;
      //console.log('currentUserinfo skills ==> '+JSON.stringify(this.currentUserInfo.skills));
    }, (error) => {
        alert('une erreur est survenue');
    });

  }


  getUserListWithOutCurrentUser(){
    if(!this.projectUsers) return [];
    var arrayUser = [];
    for (var i = 0; i < this.projectUsers.length; i++) {
      if(this.projectUsers[i].user.id != this.curUser.id){
        arrayUser.push(this.projectUsers[i]);
      }
    }
    return arrayUser;
  }

  uploadFile(event) {
    //console.log('item récupéré '+JSON.stringify(event));
    /*for (let index = 0; index < event.length; index++) {
      const element = event[index];
      this.files[this.files.length] = element;
      this.inputMedia.nativeElement.value = "";
    }  */

    for (let index = 0; index < event.length; index++) {
      const element = event[index];
      this.files.push(element);
    }
    this.inputMedia.nativeElement.value = "";
  }


  pendingDev(){
    if(this.lang == "fr")
      alert("Processus action délai bientôt disponible");
    else
      alert("Deadline action process soon available");
  }

  pendingDevReunion(){
    if(this.lang == "fr")
      alert("Compte rendu de réunion bientôt disponible");
    else
      alert("Meeting minutes coming soon");
  }

  fctEcrire(){

    this.writeMode = -1;
    this.resetCommentForm();
    this.resetAnswerFrom();

    if(this.openWriteFace == false)
    {
      this.showRedBar = false;
      this.openWriteFace = true;
      this.hiddenSearchEngin = true;
      this.showMessages = false;
      this.getLastFaceNumber();
    }
    else
    {
      this.resetQuestionFrom();
      this.openWriteFace = false;
      this.showMessages = true;
    }

    setTimeout(() => {
      // this.writeMsg.nativeElement.focus();
      window.scroll(0,0);
    }, 500);
    //this.divWriteMessage.nativeElement.scrollIntoView();
    // window.scroll(0,0);
  }


  exiteWriteMode(){
    this.writeMode = -1;
    this.resetCommentForm();
    this.resetAnswerFrom();
    this.resetQuestionFrom();
    this.openWriteFace = false;
    this.showMessages = true;
    this.messageService.getCloseWriteMessage(true);
  }

  getLastFaceNumber(){
     this.authService.getLastFaceNumber(this.ID)
      .subscribe(res => {
        //console.log(' retour getLastFaceNumber => '+res);
        let arrayMessage = res['hydra:member']

        if (arrayMessage.length > 0) {
           this.lastFaceNumber = arrayMessage[0].smartNumber;
        }
        // console.log(this.projectUsers);
        //console.log('fin chargement projet ');
      }, (error) => {
         //console.log(' getTheProject subscribe error '+error.statusText);
    });
  }

  onSelectPrivate(){
    if (this.sendMessageForm.controls.private.value === false)
    {
       this.sendMessageForm.get('private').setValue(true);
    }
    else
    {
      this.sendMessageForm.get('private').setValue(false);
    }
  }

  getAnswerListe(){
     this.authService.getAnswerListe( )
      .subscribe(res => {
        //console.log(' retour getAnswerListe => '+JSON.stringify(res));
        this.arrayQuickAnswer = res['hydra:member'];
      }, (error) => {
         //console.log(' getTheProject subscribe error '+error.statusText);
    });
  }

  showAnswerListe(message){
      return message.replace(/<.*?>/g, '');
  }

  sendQuickAnswer (answerItem,  infoMessage) {
    const msg = this.lang == 'fr' ? answerItem.message : answerItem.title
    this.authService.sendQuickAnswer(
      msg,
      infoMessage.tags[0],
      infoMessage.isPrivate,
      this.project,
      infoMessage.id
    )
    .subscribe(res => {
      //console.log('réponse answer => '+res);
      //this.writeMode = 0;
      this.writeMode = -1;
      //this.envoyerObs.next();
      this.messageService.messageRefreshConv();
      this.messageService.messageUpdated();

      //scroll in view
      //this.divHeaderTable.nativeElement.scrollIntoView();
       window.scroll(0,0);

    });
  }

  getRecipient(){
    for (var i = 0; i < this.projectUsers.length; i++) {
      if(this.projectUsers[i].user.id == this.sendMessageForm.controls.recipient.value){
        return this.projectUsers[i].user.fullName;
      }
    }
    return false;
    //console.log('Your order has been submitted ---> ', this.sendMessageForm.value);
  }

  getRecipientData(){
    for (var i = 0; i < this.projectUsers.length; i++) {
      if(this.projectUsers[i].user.id == this.sendMessageForm.controls.recipient.value){
        return this.projectUsers[i].user;
      }
    }
    return false;
    //console.log('Your order has been submitted ---> ', this.sendMessageForm.value);
  }

  getDeadline(){
    if(this.sendMessageForm.controls.deadLine.value){
      return this.sendMessageForm.controls.deadLine.value;
    }
    return '';
    //console.log('Your order has been submitted ---> ', this.sendMessageForm.value);
  }


  transfertByMail(messageData){
    const dialogRef = this.dialog.open(transfertdialog, {
      minWidth: '600px',
      position:{ top: '50px' },
      data: messageData
    });

    dialogRef.afterClosed().subscribe(result => {
      //console.log('The dialog was closed');
    });
  }

  getUserListWithNoCurrentUser(){
    const recipient = this.sendMessageForm.controls.recipient.value
    if(!this.projectUsers) return [];
    var arrayUser = [];
    for (var i = 0; i < this.projectUsers.length; i++) {
      if(this.projectUsers[i].user.id != this.currentUser && this.projectUsers[i].user.id != recipient){
        arrayUser.push(this.projectUsers[i]);
      }
    }
    return arrayUser;
  }


 /* open(url){
    // alert(url)
    const album = {
      src: url
   };
   let albums = [];
   albums.push(album);
   this._lightbox.open(albums, 0);

   setTimeout(() => {
    document.querySelector( 'img.lb-image' ).removeAttribute("hidden");
    if(document.querySelector( '.lb-loader' )) document.querySelector( '.lb-loader' ).setAttribute("hidden", "true");
    if(document.querySelector( '.lb-nav' )) document.querySelector( '.lb-nav' ).setAttribute("hidden", "true");
   }, 1500);
  }*/

  open(file){

    const reader = new FileReader();
    reader.onload = (e: any) => {
       //console.log('csv content', e.target.result);
       const album = {
          src: e.target.result
       };
       let albums = [];
       albums.push(album);
       this._lightbox.open(albums, 0);

       setTimeout(() => {
        document.querySelector( 'img.lb-image' ).removeAttribute("hidden");
        if(document.querySelector( '.lb-loader' )) document.querySelector( '.lb-loader' ).setAttribute("hidden", "true");
        if(document.querySelector( '.lb-nav' )) document.querySelector( '.lb-nav' ).setAttribute("hidden", "true");
       }, 1500);

    };
    reader.readAsDataURL(file);

  }

  playVideoOrAudio(file, source){
    source.src = URL.createObjectURL(file);
  }

  createFileUrl(file, source){
    source.href = URL.createObjectURL(file);
  }

  getFiletype(file){

    if (file.type.includes("image/") == true) {
      return 1;
    }
    else if (file.type.includes("video/") == true) {
      return 2;
    }
    else if (file.type.includes("application/") == true) {
      return 3;
    }
    else if (file.type.includes("audio/") == true) {
      return 4;
    }
    else
    {
      return -1;
    }

  }

  close(): void {
    // close lightbox programmatically
    this._lightbox.close();
  }

  deleteAttachment(index) {
    this.files.splice(index, 1);
    //console.log(this.files)
  }

  toggleAllSelection() {
    console.log('Personne selectionné ');
    if(this.sendMessageForm.controls.inCopy.value.length == this.getUserListWithNoCurrentUser().length){
      this.sendMessageForm.controls.inCopy.patchValue([]);
      this.checked = false;
    }
    else
    {
      this.sendMessageForm.controls.inCopy.patchValue([...this.getUserListWithNoCurrentUser().map(item => item.user.id)]);
      this.checked = true;
    }
  }
 
  inCopyLimitSelection() {
    if (this.sendMessageForm.controls.inCopy.value.length <= 5) {
      this.mySelections = this.sendMessageForm.controls.inCopy.value;
    } else {
      this.sendMessageForm.controls.inCopy.setValue(this.mySelections);
    }
  }

  truncate(value: string, limit = 25, completeWords = false, ellipsis = '...') {
    if (completeWords) {
      limit = value.substr(0, limit).lastIndexOf(' ');
    }
    let val =  value.length > limit ? value.substr(0, limit) + ellipsis : value;
    return val.replace(/(http?s:\/\/[^\s]+)/g, "<a href='$1' target='__blank'>$1</a>");
  }

  getCurrentProject() {
    //console.log('getCurrentProject paramètre id ==> '+this.ID);
    this.authService.getTheProject(this.ID)
      .subscribe(res => {

        this.projectFull = res;

        let curUser = JSON.parse(localStorage.getItem('currentUser'));
        if(this.projectFull &&  this.projectFull.isDefaultChanel == true && curUser.profil == 'EMPLOYEE'){
          this.messageService.setHideMessage(true);
        }else{
          this.messageService.setHideMessage(false);
        }

        var tmpArrayUser = res['userProject'];
        this.projectUsers = [];
        for (var i = 0; i < tmpArrayUser.length; i++) {
          if(tmpArrayUser[i].user){
            this.projectUsers.push(tmpArrayUser[i]);
            /*if(tmpArrayUser[i].profil && tmpArrayUser[i].profil == "EMPLOYEE"){
              this.projectUsers.push(tmpArrayUser[i]);
            }*/
          }
        }

        if(res['id'] != null){
          this.project = '/api/projects/' + res['id'];
        }


        for (var i = 0; i < this.projectUsers.length; i++) {
          if((this.projectUsers[i].isAdmin == true) && (this.projectUsers[i].user.id == this.currentUser))
          {
            this.userAdmin = true;
          }
        }

        this.userIsManager = this.fctUserIsManager(this.projectFull);
        //console.log(' manager est => '+this.userIsManager);

        this.loadProjectMessages();

      }, (error) => {
         //console.log(' getTheProject subscribe error '+error.statusText);
    });

    //this.loadProjectMessages();

  }


  loadProjectMessages(){

     this.messageService.getMessageRefreshConv().subscribe(() =>{
      this.paginator.pageIndex = 0;
    });

    merge(this.paginator.page, this.messageService.getMessageRefreshConv(), this.refreshPageObs)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          return this.authService.getProjectHisMessages(this.ID, this.paginator.pageIndex, this.perPage, (this.specMsg ? this.specMsg : null ), (this.specMsgType ? this.specMsgType : null ),(this.chosenFilterProject ? this.chosenFilterProject : null ))
        }),
        map(data => {
          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
          this.isRateLimitReached = false;
          this.resultsLength = data['hydra:totalItems'];
          return data['hydra:member'];
        }),
        catchError(() => {
          this.isLoadingResults = false;
          // Catch if the  API has reached its rate limit. Return empty data.
          this.isRateLimitReached = true;
          return observableOf([]);
        })
      ).subscribe(data => {



          for (var i = 0; i < data.length; i++) {
            data[i].truncating = true;
            if(data[i].isMessageAnswered == true && data[i].answer) {
              data[i].answer.truncating = true;
            }
          }

          this.isReady = true;
          this.dataSource = data;

          if(this.dataSource.length > 0){

            this.hasMessage = true;

            for (var i = 0; i < this.dataSource.length; i++) {
              //this.dataSource[i].comment
              this.dataSource[i].comment.sort((a: any, b: any) => {// order by date
                  return b.smartNumber - a.smartNumber;
              });
            }

            if (this.specMsg) {
              setTimeout(() => {
                if (this.divComSpecMsg) {
                  this.divComSpecMsg.nativeElement.scrollIntoView({block: "center"});
                }
                if (this.divConvSpecMsg) {
                  this.divConvSpecMsg.nativeElement.scrollIntoView({block: "center"});
                }
              }, 500);
            }

            let curUser = JSON.parse(localStorage.getItem('currentUser'));
            if(this.projectFull && this.projectFull.isDefaultChanel == true && curUser.profil == 'EMPLOYEE'){
              this.messageService.setHideMessage(true);
            }

          }else {
            //console.log('this.chosenFilterProject  ==> '+ JSON.stringify(this.chosenFilterProject) );
            this.hasMessage = false;


            if(!this.chosenFilterProject && this.projectFull && this.projectFull.isDefaultChanel == true  && this.projectFull.typeProject == 'DRH' && this.curUser.profil != 'EMPLOYEE'){
              this.messageService.openWriteMessageFace(null);
            }
            else if(!this.chosenFilterProject && this.projectFull && this.projectFull.isDefaultChanel == false && !this.projectFull.typeProject){
              this.messageService.openWriteMessageFace(null);
            }
          }
      });
  }


  // convenience getter for easy access to form fields
  get f() { return this.sendMessageForm.controls; }
  get fanswer() { return this.sendanswerForm.controls; }
  get fcomment() { return this.formComment.controls; }

  convertText(text){
    return text.replace(/(http?s:\/\/[^\s]+)/g, "<a href='$1'>$1</a>");
  }


  onSubmitForm(customerData) {

     // Process checkout data here
    // console.log(JSON.stringify(this.files));

    //console.log('Your onSubmitForm order has been submitted', customerData);

    this.submitted = true;
     // stop here if form is invalid
    if (this.sendMessageForm.invalid) {
      return;
    }

    //console.log('sendMessge est valide');

    if (customerData.recipient == -1) {// si tous les salariés en destinataire
      this.sendMessageForm.get('recipient').setValidators(null);
      this.sendMessageForm.get('recipient').setValue('');
      this.sendMessageForm.get('recipient').updateValueAndValidity();
      this.sendMessageForm.get('private').setValue(false);
      this.sendMessageForm.controls.inCopy.patchValue([...this.getUserListWithNoCurrentUser().map(item => item.user.id)]);
      this.sendQuestion(this.sendMessageForm.value, true);
    }
    else{
      this.sendQuestion(customerData, false);
    }

    if (this.showTool == false) {
      this.messageService.setShowProjectTutoIsFalse();
    }

    this.messageService.setMessageIsSend();

    this.submitted = false;
    this.theForm.resetForm();
    this.resetQuestionFrom();
    this.openWriteFace = false;
    this.showMessages = true;
    this.messageService.getCloseWriteMessage(true);

    //scroll in view
     window.scroll(0,0);


  }

  onSelectSubProject(sujetChosen){
    //console.log('sujetChosen ==> ', sujetChosen);
    this.sendMessageForm.get('newSubProject').setValue('');
    this.sendMessageForm.get('subProject').setValidators([Validators.required]);
    this.sendMessageForm.get('subProject').updateValueAndValidity();
  }


  onSelectNewSubProject(newSubProjetName){
    this.sendMessageForm.get('subProject').setValue('');
    this.sendMessageForm.get('subProject').clearValidators();
    this.sendMessageForm.get('subProject').updateValueAndValidity();
  }

  sendQuestion(messageTosendData, AllMessage) {
    this.authService.sendQuestion(
      messageTosendData.messageToSend,
      messageTosendData.subProject,
      this.project,
      messageTosendData.recipient,
      messageTosendData.inCopy,
      messageTosendData.private,
      this.isCommentActivated,
      messageTosendData.deadLine,
      this.files,
      AllMessage
    )
    .subscribe(res => {
      //console.log('reponse envoi message: ',res);
      this.newTagsArray = res['tags'];
      for (var i = 0; i < this.newTagsArray.length; i++) {
        if (this.containsTheTag(this.newTagsArray[i]) == false) {
          //console.log('ajout du nouveau tag => '+ this.newTagsArray[i]);
          var tag = this.newTagsArray[i];
          this.projectTags.push(tag);
        }
      }
      this.chosenFilterProject = null;
      this.specMsg = null;
      this.showRedBar = false;
      this.specMsgType = null;
      //this.envoyerObs.next();
      this.messageService.messageRefreshConv();
      this.messageService.messageUpdated();
      this.messageService.needRefreshFiltre();
    });
  }

  containsTheTag(newTag){
    for(var i = 0; i < this.projectTags.length; i++) {
      if(this.projectTags[i].id == newTag.id){
        return true;
      }
    }
    return false;
  }

  quitReply(messageData){
    this.writeMode = -1;
    this.messageService.getCloseWriteMessage(true);
  }

  openAnswerMode(messageData) {
    //console.log(' messageData => '+ JSON.stringify(messageData));
    this.resetAnswerFrom();
    this.resetQuestionFrom();
    this.openWriteFace = false;
    this.showMessages = true;
    this.messageInfo = messageData;
    this.writeMode = 1;

    this.messageService.setAnswerOpen();

  }

  quitComment(messageData){
    this.writeMode = -1;
  }

  openCommentMode(messageData){
    if(messageData && messageData.isCommentActivated == true){
      //if (this.writeMode !== 2) {
        this.resetCommentForm();
        this.resetQuestionFrom();
        this.openWriteFace = false;
        this.showMessages = true;
        this.messageInfo = messageData;
        this.writeMode = 2;
      /*}
      else
      {
        this.writeMode = -1;
      }*/
    }
    else
    {
      if(!messageData)
      {
        alert('Aucun message à commenter');
      }
      else if (messageData.isCommentActivated == false)
      {
        alert('Commentaire déactivé');
      }
      else
      {
        alert('oups Error !!!');
      }
    }
  }


  onSubmitAnswerForm(answerFormData) {

     // Process checkout data here
    //console.log('Your order has been submitted onSubmitAnswerForm ', answerFormData);

    this.submitted = true;

     // stop here if form is invalid
    if (this.sendanswerForm.invalid) {
      return;
    }

    this.sendAnswer(answerFormData);

    this.submitted = false;
    this.files = [];
    this.resetAnswerFrom();

    //scroll in view
     window.scroll(0,0);
    //this.divHeaderTable.nativeElement.scrollIntoView();

  }

  sendAnswer(answerData) {
    this.authService.sendAnswer(
      answerData.answerMessage,
      this.messageInfo.tags[0],
      this.messageInfo.isPrivate,
      this.project,
      this.messageInfo.id,
      this.files
      // answerData.files
    )
    .subscribe(res => {
      //console.log('réponse answer => '+res);
      //this.writeMode = 0;
      this.writeMode = -1;
      //this.envoyerObs.next();
      this.messageService.messageRefreshConv();
      this.messageService.messageUpdated();
      this.messageService.getCloseWriteMessage(true);
    });
  }

  onSubmitFormComment(commentFormData){

    // Process checkout data here
    //console.log('Your order has been submitted onSubmitFormComment ', commentFormData);

    this.submitted = true;

     // stop here if form is invalid
    if (this.formComment.invalid) {
      return;
    }

    this.sendComment(commentFormData);

    this.submitted = false;
    this.files = [];
    this.resetCommentForm();

    //scroll in view
    window.scroll(0,0);
    //this.divHeaderTable.nativeElement.scrollIntoView();

  }

  sendComment(commentFormData) {
    this.authService.sendComment(
      commentFormData.commentMessage,
      this.messageInfo.tags[0],
      this.messageInfo.isPrivate,
      this.project,
      this.messageInfo.id,
      this.files
      // commentFormData.files
    )
    .subscribe(res => {
      //console.log('réponse comment => '+res);
      //this.writeMode = 0;
      this.writeMode = -1;
      //this.envoyerObs.next();
      this.messageService.messageRefreshConv();
      this.messageService.messageUpdated();
    });
  }

  resetQuestionFrom(){
     // reset form
    // this.sendMessageForm.reset();
    // this.sendMessageForm.get('subProject').clearValidators();
    // this.sendMessageForm.get('subProject').updateValueAndValidity();
    // this.sendMessageForm.get('recipient').clearValidators();
    // this.sendMessageForm.get('recipient').updateValueAndValidity();
    // this.sendMessageForm.get('messageToSend').clearValidators();
    // this.sendMessageForm.get('messageToSend').updateValueAndValidity();
    // this.sendMessageForm.get('files').clearValidators();
    // this.sendMessageForm.get('files').updateValueAndValidity();
    // this.sendMessageForm.reset();



    //set defaut form values
    this.files = [];
    this.checked = false;
    this.sendMessageForm.get('subProject').setValue('');
    this.sendMessageForm.get('subProject').setValidators([Validators.required]);
    this.sendMessageForm.get('subProject').updateValueAndValidity();
    this.sendMessageForm.get('newSubProject').setValue('');
    this.sendMessageForm.get('recipient').setValue('');
    this.sendMessageForm.get('recipient').setValidators([Validators.required]);
    this.sendMessageForm.get('recipient').updateValueAndValidity();
    this.sendMessageForm.get('inCopy').setValue([]);
    this.sendMessageForm.get('private').setValue(false);
    this.sendMessageForm.get('deadLine').setValue('');
    this.sendMessageForm.get('messageToSend').setValue('');
    this.sendMessageForm.get('messageToSend').setValidators([Validators.required, Validators.minLength(1)]);
    this.sendMessageForm.get('messageToSend').updateValueAndValidity();
    this.sendMessageForm.get('files').setValue(null);
    this.sendMessageForm.get('files').setValidators([requiredFileType(['png','jpeg','jpg','mov','mp4'])]);
    this.sendMessageForm.get('files').updateValueAndValidity();

    // Object.keys(this.sendMessageForm.controls).forEach(key => {
    //   this.sendMessageForm.controls[key].setErrors(null)
    // });
  }

  resetAnswerFrom(){

    // reset form
    this.sendanswerForm.get('answerMessage').clearValidators();
    this.sendanswerForm.get('answerMessage').updateValueAndValidity();
    this.sendanswerForm.get('files').clearValidators();
    this.sendanswerForm.get('files').updateValueAndValidity();
    this.sendanswerForm.reset();

    //set defaut form values
    this.sendanswerForm.get('answerMessage').setValue('');
    this.sendanswerForm.get('answerMessage').setValidators([Validators.required, Validators.minLength(1)]);
    this.sendanswerForm.get('answerMessage').updateValueAndValidity();
    this.sendanswerForm.get('files').setValue(null);
    this.sendanswerForm.get('files').setValidators([requiredFileType(['png','jpeg','jpg','mov','mp4'])]);
    this.sendanswerForm.get('files').updateValueAndValidity();

  }

  resetCommentForm(){

    // reset form
    this.formComment.get('commentMessage').clearValidators();
    this.formComment.get('commentMessage').updateValueAndValidity();
    this.formComment.get('files').clearValidators();
    this.formComment.get('files').updateValueAndValidity();
    this.formComment.reset();

    //set defaut form values
    this.formComment.get('commentMessage').setValue('');
    this.formComment.get('commentMessage').setValidators([Validators.required, Validators.minLength(1)]);
    this.formComment.get('commentMessage').updateValueAndValidity();
    this.formComment.get('files').setValue(null);
    this.formComment.get('files').setValidators([requiredFileType(['png','jpeg','jpg','mov','mp4'])]);
    this.formComment.get('files').updateValueAndValidity();

  }

  getMediaLink(mediaUrl){
    return this.authService.getMediaData(mediaUrl);
  }

  getMediaThumbail(mediaUrl){
    return this.authService.getMediaThumbail(mediaUrl);
  }

  fctListeMembre(){
    const dialogRef = this.dialog.open(DialogMemberListe, {
      minWidth: '600px',
      position:{ top: '50px' },
      data:{idProjet: this.ID}
    });

    dialogRef.afterClosed().subscribe(result => {
      //console.log('The dialog was closed result '+result);
      if(result && result === 1){
        this.fctAddMember();
      }

    });
  }

  fctAddMember() {
    const dialogRef = this.dialog.open(DialogAddMember, {
      minWidth: '500px',
      position:{ top: '50px' },
      data: {project: this.project, projectUsers: this.projectUsers}
    });

    dialogRef.afterClosed().subscribe(result => {
      //console.log('The dialog was closed result add membre '+result);
    });
  }

  showHideAllMessage(){
    if (this.showMessages == true) {
        this.showMessages = false;
    }
    else{
       this.showMessages = true;
    }
  }


  loadAllMessage(){
    this.ged = false;
    this.chosenFilterProject = null;
    this.specMsg = null;
    this.showRedBar = false;
    this.specMsgType = null;
    //this.envoyerObs.next();
    this.messageService.messageRefreshConv();
  }

  public filterMessage(wantedFilter){
    //console.log(' wantedFilter --> '+ JSON.stringify(wantedFilter));
    if (this.isEmpty(wantedFilter) == true)
    {
      this.chosenFilterProject = null;
    }
    else
    {
      this.chosenFilterProject = wantedFilter;
    }
    this.ged = false;
    this.expanded = true;
    this.specMsg = null;
    this.showRedBar = false;
    this.specMsgType = null;
    //this.envoyerObs.next();
    this.messageService.messageRefreshConv();
  }

  isEmpty(obj) {
    if (obj) {
      for(var key in obj) {
        if ((key != 'projectId') && obj[key]) {
          return false;
        }
      }
    }
    return true;
  }

  getAvatarlUrl(avatarLink){
    return environment.server_url+avatarLink;
  }

  userInCopyHasNoRead(element){
    if(!element) return false;
    for (var i = 0; i < element.copy.length; i++) {
      if((element.copy[i].user.id == JSON.parse(localStorage.getItem('currentUser'))['id']) && (element.copy[i].isRead == false)){
        return true;
      }
    }
    return false;
  }

  userInCopyHasRead(element){
    if(!element) return false;
    for (var i = 0; i < element.copy.length; i++) {
      if((element.copy[i].user.id == JSON.parse(localStorage.getItem('currentUser'))['id']) && (element.copy[i].isRead == true)){
        return true;
      }
    }
    return false;
  }

  isNoRead(element){
    if((element.recipient && element.recipient.id == JSON.parse(localStorage.getItem('currentUser'))['id']) && (element.isRecipientRead == false))
    {
      return true;
    }
    return false;
  }

  isRead(element){
    if((element.recipient && element.recipient.id == JSON.parse(localStorage.getItem('currentUser'))['id']) && (element.isRecipientRead == true))
    {
      return true;
    }
    return false;
  }

  userIsNotConcerned(element){
    if(!element) return true;
    if(element.recipient && (element.recipient.id == JSON.parse(localStorage.getItem('currentUser'))['id']))
    {
      return false;
    }
    for (var i = 0; i < element.copy.length; i++) {
      if(element.copy[i].user.id == JSON.parse(localStorage.getItem('currentUser'))['id']){
        return false;
      }
    }
    return true;
  }


  markAsRead(element){
    if((element.isRecipientRead == false) && (element.type != "COMMENT") && (element.recipient.id == JSON.parse(localStorage.getItem('currentUser'))['id'])){
      //console.log('lancement de en recipient');
      this.authService.markAsRead(element).subscribe(res => {
        this.refreshPageObs.next();
        this.messageService.messageUpdated();
      },
      error => {
        alert("oups une erreur s'est produite!");
      });
    }
    else
    {
      //console.log('lancement de en copie');
       for (var i = 0; i < element.copy.length; i++) {
        if((element.copy[i].user.id == JSON.parse(localStorage.getItem('currentUser'))['id']) && (element.copy[i].isRead == false)){
          this.authService.markAsReadCopy(element.copy[i].id).subscribe(res => {
            this.refreshPageObs.next();
            this.messageService.messageUpdated();
          },
          error => {
            alert("oups une erreur s'est produite!");
          });
          return;
        }
      }
    }
  }



  fctinfoProjet(): void{
    const dialogRef = this.dialog.open(projectInfoDialog, {
      minWidth: '800px',
      position:{ top: '50px' },
      data: this.projectFull
    });
    dialogRef.afterClosed().subscribe(result => {
      //console.log('The dialog was closed fctinfoProjet '+result);
        if (result && result.deleteReturn) {
          this.authService.obRefreshProjet.next();
          this.router.navigate(['/']);
        }else if (result) {
          this.projectFull = result;
          this.authService.obRefreshProjet.next();
       }

    });
  }

  changeSubject(){

    if (this.showTool == true) {
      this.showTool = false;
      this.messageService.setShowProjectTutobis(false);
    }


    const dialogRef = this.dialog.open(subjectInfodialog, {
      //minWidth: ((this.projectFull.isDefaultChanel == true) || (this.projectFull.typeProject && this.projectFull.typeProject == 'MANAGER')) ? '400px' : '900px',
      minWidth: '400px',
      position:{ top: '10px' },
      data: {
        projectData : this.projectFull,
        sendMessageForm : this.sendMessageForm
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      //console.log('The dialog was closed fctinfoProjet '+result);
      if (result) {
       this.sendMessageForm.patchValue({
          subProject: result
        });
      }
    });

  }

  disabledMessage(messageData): void{
    this.authService.disableMessage(messageData)
    .subscribe(res => {
      this.refreshPageObs.next();
      this.messageService.messageUpdated();
    },
    error => {
      alert("oups une erreur s'est produite!");
    });
  }

  recalls(messageData): void{
    this.authService.recalls(messageData)
    .subscribe(res => {
      this.refreshPageObs.next();
      this.messageService.messageUpdated();
      alert("Rappel envoyé");
    },
    error => {
      //console.log('error '+JSON.stringify(error));
      alert(error['error']['hydra:description']);
    });
  }


  getContact(){
    /*if(this.iDProjet == null) return;

    this.authService.getTheProject(this.iDProjet)
    .subscribe(res => {

      var tmpArrayUser = res['userProject'];

      this.contacts = [];
      for (var i = 0; i < tmpArrayUser.length; i++) {
        if(tmpArrayUser[i].user){
          this.contacts.push(tmpArrayUser[i].user);
        }
      }

      this.contacts.sort(this.compare);

    }, (error) => {
         //console.log(' getTheProject subscribe error '+error.statusText);
    });*/

    if(this.iDProjet == null){
      return;
    }
    
    this.authService.getContactsExpID(this.iDProjet).subscribe(res => {

      this.contactsExp = [];

      var tmpArrayUser = res['hydra:member'];

      for (var i = 0; i < tmpArrayUser.length; i++) {
        if(tmpArrayUser[i].user){
          this.contactsExp.push(tmpArrayUser[i].user);
        }
      }

      this.contactsExp.sort(this.compare);

    }, (error) => {
         //console.log(' getTheProject subscribe error '+error.statusText);
    });

    this.authService.getContactsRecipID(this.iDProjet).subscribe(res => {


      this.contactsRecip = [];

      var tmpArrayUser = res['hydra:member'];

      for (var i = 0; i < tmpArrayUser.length; i++) {
        if(tmpArrayUser[i].user){
          this.contactsRecip.push(tmpArrayUser[i].user);
        }
      }

      this.contactsRecip.sort(this.compare);

    }, (error) => {
         //console.log(' getTheProject subscribe error '+error.statusText);
    });
 
  }

  compare( a, b ) {
    if ( a.familyName.trim().toLowerCase() < b.familyName.trim().toLowerCase() ){
      return -1;
    }
    if ( a.familyName.trim().toLowerCase() > b.familyName.trim().toLowerCase() ){
      return 1;
    }
    return 0;
  }

  getSujet(idProject){

    this.projectId = idProject;
    /*this.authService.getTheProject(idProject).subscribe(res => {
      this.projectTags = res['tags'];
    }, (error) => {
       //console.log(' getTheProject subscribe error '+error.statusText);
    });*/
    if(idProject != null)
    this.authService.getProjectSuject(idProject).subscribe(res => {
      this.projectTags = res['hydra:member'];
    }, (error) => {
       //console.log(' getTheProject subscribe error '+error.statusText);
    });

  }

  launchSearch(){

    this.modeDoc = false;

    this.chosenFilter = {
      transmitter: this.transmitter,
      recipient: this.recipient,
      projectId: this.projectId,
      dateBefore: this.dateBefore,
      dateAfter: this.dateAfter,
      tag: this.tag,
      statut: this.statut,
      type: this.type,
      keyWord: (this.keyWord ? this.keyWord.trim() : null),
      smartNumber: this.smartNumber
    };
    //console.log(' launchSearch '+ JSON.stringify(this.chosenFilter) );
    this.EventLaunchSearch.emit(this.chosenFilter);

  }

  launchSearchDoc(){

    this.modeDoc = true;

    this.chosenFilter = {
      transmitter: this.transmitter,
      recipient: this.recipient,
      projectId: this.projectId,
      dateBefore: this.dateBefore,
      dateAfter: this.dateAfter,
      tag: this.tag,
      statut: this.statut,
      type: this.type,
      keyWord: (this.keyWord ? this.keyWord.trim() : null),
      smartNumber: this.smartNumber
    };
    //console.log(' launchSearchDoc '+ JSON.stringify(this.chosenFilter) );
    this.EventLaunchSearchDoc.emit(this.chosenFilter);

  }

  public resetSearch(){

    this.transmitter = null;
    this.recipient = null;
    this.projectId = null;
    this.dateBefore  = null;
    this.dateAfter  = null;
    this.tag = null;
    this.statut = null;
    this.type = null;
    this.keyWord = null;
    this.smartNumber = null;

    this.getContact();
    this.getSujet(this.iDProjet);

    if(this.modeDoc == false)
    {
      //this.launchSearch();
    }
    else
    {
      this.launchSearchDoc();
    }

  }

  public projecthasChange(newIdProject){

    //console.log(' projecthasChange => '+newIdProject);

    this.iDProjet = newIdProject;
    this.transmitter = null;
    this.recipient = null;
    this.projectId = null;
    this.dateBefore  = null;
    this.dateAfter  = null;
    this.tag = null;
    this.statut = null;
    this.type = null;
    this.keyWord = null;
    this.smartNumber = null;

    this.getContact();
    this.getSujet(this.iDProjet);


  }

  public refreshInfoFilter(){
    this.getContact();
    this.getSujet(this.iDProjet);
  }

  openDialogInfoWrite(){

    const dialogRef = this.dialog.open(infoWriteMessageDialog, {
      minWidth: '900px',
      position:{ top: '50px' },
      data:{}
    });

    dialogRef.afterClosed().subscribe(result => {

      if (result){

      }

    })

  }

  fctGetImageUser(userData){

    if(this.projectFull && this.projectFull.typeProject && (this.projectFull.typeProject == 'PARTENAIRE') && (userData.skills.includes(10) == true) ){
      return '../../../../assets/img/CSE.png';
    }
    else if(this.projectFull && this.projectFull.typeProject && (this.projectFull.typeProject == 'CHO') && (userData.skills.includes(20) == true) ){
      return '../../../../assets/img/CHO.png';
    }
    else if(this.projectFull && this.projectFull.typeProject && (this.projectFull.typeProject == 'CGT') && (userData.skills.includes(1) == true) ){
      return '../../../../assets/img/Syndicats.png';
    }
    else if(this.projectFull && this.projectFull.typeProject && (this.projectFull.typeProject == 'CFDT') && (userData.skills.includes(5) == true) ){
      return '../../../../assets/img/Syndicats.png';
    }
    else if(this.projectFull && this.projectFull.typeProject && (this.projectFull.typeProject == 'CFE-CGC') && (userData.skills.includes(7) == true) ){
      return '../../../../assets/img/Syndicats.png';
    }
    else if(this.projectFull && this.projectFull.typeProject && (this.projectFull.typeProject == 'CFTC') && (userData.skills.includes(8) == true) ){
      return '../../../../assets/img/Syndicats.png';
    }
    else if(this.projectFull && this.projectFull.typeProject && (this.projectFull.typeProject == 'FO') && (userData.skills.includes(6) == true) ){
      return '../../../../assets/img/Syndicats.png';
    }
    else {
      switch (userData.profil) {
        case 'HRD':
          return '../../../../assets/img/DRH.png';
          break;
        case 'HR':
          return '../../../../assets/img/DRH.png';
          break;
        case 'EMPLOYEE':
          return '../../../../assets/img/Salarie.png';
          break;
        case 'CS':
          return '../../../../assets/img/Manager.png';
          break;
        default:
          return '../../../../assets/img/default-avatar.png';
          break;
      }
    }
  }

  getStatusbis(userData){

    if(this.projectFull && this.projectFull.typeProject && (this.projectFull.typeProject == 'PARTENAIRE') && (userData.skills.includes(10) == true) ){
      return 'CSE';
    }
    else if(this.projectFull && this.projectFull.typeProject && (this.projectFull.typeProject == 'CHO') && (userData.skills.includes(20) == true) ){
      return 'CHO';
    }
    else if(this.projectFull && this.projectFull.typeProject && (this.projectFull.typeProject == 'CGT') && (userData.skills.includes(1) == true) ){
      return 'Syndiqué CGT';
    }
    else if(this.projectFull && this.projectFull.typeProject && (this.projectFull.typeProject == 'CFDT') && (userData.skills.includes(5) == true) ){
      return 'Syndiqué CFDT';
    }
    else if(this.projectFull && this.projectFull.typeProject && (this.projectFull.typeProject == 'CFE-CGC') && (userData.skills.includes(7) == true) ){
      return 'Syndiqué CFE-CGC';
    }
    else if(this.projectFull && this.projectFull.typeProject && (this.projectFull.typeProject == 'CFTC') && (userData.skills.includes(8) == true) ){
      return 'Syndiqué CFTC';
    }
    else if(this.projectFull && this.projectFull.typeProject && (this.projectFull.typeProject == 'FO') && (userData.skills.includes(6) == true) ){
      return 'Syndiqué FO';
    }
    else {
      switch (userData.profil) {
        case 'HRD':
          return "DRH";
          break;
        case 'HR':
          return "RRH";
          break;
        case 'EMPLOYEE':
          return "Salarié";
          break;
        case 'CS':
          return "Manager";
          break;
        default:
          return userData.profil;
          break;
      }
    }

  }


  getSkill(contact){
    var skillList = ''; 
    if (contact.skills.includes(1) == true){
      skillList += 'CGT ';
    } 
    if (contact.skills.includes(5) == true){
      skillList += 'CFDT ';
    }  
    if (contact.skills.includes(6) == true){
      skillList += 'FO ';
    }   
    if (contact.skills.includes(7) == true){
      skillList += 'CFE-CGC ';
    }   
    if (contact.skills.includes(8) == true){
      skillList += 'CFTC ';
    }   
    return skillList;
  }
  
  getStatus(key){
    switch (key) {
      case 'HRD':
        return "DRH";
        break;
      case 'HR':
        return "RRH";
        break;
      case 'EMPLOYEE':
        return "Salarié";
        break;
      case 'CS':
        return "Manager";
        break;
      default:
        return key;
        break;
    }
  }

}

@Component({
  selector: 'memberListe-dialog',
  templateUrl: './memberListe-dialog.html',
  styleUrls: ['./memberListe-dialog.scss']
})
export class DialogMemberListe {

  userID = JSON.parse(localStorage.getItem('currentUser'))['id'];
  userProfil = JSON.parse(localStorage.getItem('currentUser'))['profil'];
  userAdmin = false;
  projectUsers = [];
  projectData = null;

  constructor(
    public dialogRef: MatDialogRef<DialogMemberListe>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private authService: AuthService,
    private router: Router,
    public dialog: MatDialog) {


      this.authService.getTheProject(data.idProjet)
      .subscribe(res => {
        var tmpArrayUser = res['userProject'];
        this.projectData = res;
        this.projectUsers = [];
        var creatorProject = null;
        var arrayDHR = [];
        var arrayHR = [];
        var arrayCS = []
        var arrayEmploi = [];

        for (var i = 0; i < tmpArrayUser.length; i++) {

            if(tmpArrayUser[i].isCreator){
              creatorProject = tmpArrayUser[i];
            }else{

              if ((tmpArrayUser[i].profil && tmpArrayUser[i].profil == 'HRD') || (tmpArrayUser[i].user && tmpArrayUser[i].user.profil && tmpArrayUser[i].user.profil == 'HRD' ) ) {
                arrayDHR.push(tmpArrayUser[i]);
              }
              else if((tmpArrayUser[i].profil && tmpArrayUser[i].profil == 'HR') || (tmpArrayUser[i].user && tmpArrayUser[i].user.profil && tmpArrayUser[i].user.profil == 'HR' ) ){
                arrayHR.push(tmpArrayUser[i]);
              }
              else if((tmpArrayUser[i].profil && tmpArrayUser[i].profil == 'CS') || (tmpArrayUser[i].user && tmpArrayUser[i].user.profil && tmpArrayUser[i].user.profil == 'CS' ) ){
                arrayCS.push(tmpArrayUser[i]);
              }
              else{
                arrayEmploi.push(tmpArrayUser[i]);
              }

            }

        }

        this.projectUsers = this.projectUsers.concat(arrayDHR);
        if (creatorProject) {
          this.projectUsers.push(creatorProject);
        }
        this.projectUsers = this.projectUsers.concat(arrayHR);
        this.projectUsers = this.projectUsers.concat(arrayCS);
        this.projectUsers = this.projectUsers.concat(arrayEmploi);

        for (var i = 0; i < this.projectUsers.length; i++) {
          if((this.projectUsers[i].isAdmin == true) && this.projectUsers[i].user && (this.projectUsers[i].user.id == this.userID))
          {
            this.userAdmin = true;
          }
        }
          //console.log("fin requete =>"+this.projectUsers);

      });


  }

  getStatus(key){
    switch (key) {
      case 'HRD':
        return "DRH";
        break;
      case 'HR':
        return "RRH";
        break;
      case 'EMPLOYEE':
        return "Salarié";
        break;
      case 'CS':
        return "Manager";
        break;
      default:
        return key;
        break;
    }
  }


  getSkill(key, contact){
    if (contact.user && contact.user.skills && contact.user.skills.includes(key)) {
      return true;
    }
    else
    {
      return false;
    }
  }

  updateListUser(contact,values){
    this.authService.updateUserProject(contact, values)
    .subscribe(res => {
      for (var i = 0; i < this.projectUsers.length; i++) {
        if(res['id'] ===  this.projectUsers[i]['id'])
          this.projectUsers[i] = res;
      }
    });
  }


  updateUserProject(contact, key){
    let values = null;
    switch (key) {
      case 1:
        values = {isAdmin: false};
        this.updateListUser(contact, values);
        break;
      case 2:
        values = {isMember: false};
        this.updateListUser(contact, values);
        break;
      case 3:
        values = {isAdmin: true};
        this.updateListUser(contact, values);
        break;
      case 5:
        values = {isMember: true};
        this.updateListUser(contact, values);
        break;
      case 4:
        const fullname = contact.user ? contact.user.fullName : contact.email;
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
          minWidth: '350px',
          minHeight: '100px',
          position:{ top: '70px' },
          data: "Vous êtes sur le point de retirer "+fullname+" du projet ?"
        });
        dialogRef.afterClosed().subscribe(result => {
          if(result) {
            this.authService.deleteUserProject(contact)
            .subscribe(res => {
              for (var i = 0; i < this.projectUsers.length; i++) {
                if(contact['id'] ===  this.projectUsers[i]['id'])
                  this.projectUsers.splice(i, 1);
              }
            });
          }
        });
        break;
      default:
        break;
    }
  }

  getAvatarlUrl(avatarLink){
    return environment.server_url+avatarLink;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  showProfil(idUser): void{
    this.router.navigate(['/profile/'+idUser]);
    this.dialogRef.close();
  }

  fctgoAddMember(): void {
    this.dialogRef.close(1);
  }

}


/////////////////// add contact view dialog ///////////////////

/* Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'addMember-dialog',
  templateUrl: './addMember-dialog.html',
  styleUrls: ['./addMember-dialog.scss']
})
export class DialogAddMember {

  contacts = [];
  // userStatus : { name: string }[];
  projectUsers = [];
  userStatus = null;
  showNewContactInput = false;
  searchContact: any;
  projectIsSalary: any;

  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);
  matcher = new MyErrorStateMatcher();
  addingMember = false;

  constructor(
    public dialogRef: MatDialogRef<DialogAddMember>,
    @Inject(MAT_DIALOG_DATA) public data: any,
     private authService: AuthService,
     private messageService: MessageService) {

      /*this.authService.getContacts().subscribe( dataContact => {
        this.contacts = dataContact['hydra:member'];
      });*/

      for (var index = 0; index < this.data.projectUsers.length; index++) {
        if (this.data.projectUsers[index].profil && this.data.projectUsers[index].profil == 'EMPLOYEE'){
          this.projectIsSalary = this.data.projectUsers[index];
          break;
        }
      }


      this.contacts = [];

      this.loadContact();

      if(this.data.project != null){
        //console.log("this.data.project", this.data.project);
        this.authService.getTheProject(this.data.project.replace("/api/projects/", ""))
        .subscribe(res => {
          //console.log(res)
          var tmpArrayUser = res['userProject'];
          for (var i = 0; i < tmpArrayUser.length; i++) {
            if(tmpArrayUser[i].user){
              this.projectUsers.push(tmpArrayUser[i]);
            }
          }

          let userStatus = [];
          for (let index = 0; index < this.projectUsers.length; index++) {
            if(this.projectUsers[index]['user'])
            userStatus[this.projectUsers[index]['user']['id']] = this.projectUsers[index]['status'];
          }
          this.userStatus = userStatus;
          //console.log(this.userStatus)
        }, (error) => {
           //console.log(' getTheProject subscribe error '+error.statusText);
        });
      }

      //console.log(' this.data.projectUsers ==> '+JSON.stringify(this.data.projectUsers));

  }


  goBack(){
    this.dialogRef.close();
    this.messageService.setOpenListUser(true);
  }


  loadContact(url?: string){
    url = url ? url : undefined;
    this.authService.getContacts(url).subscribe(res => {
      let tempContactArray = res['hydra:member'];
      for (var i = 0; i < tempContactArray.length; i++) {
        if(tempContactArray[i].profil == 'EMPLOYEE' && this.projectIsSalary && (this.projectIsSalary.user.id != tempContactArray[i].id)){
           this.contacts.push(tempContactArray[i]);
        }
        else if (!this.projectIsSalary && tempContactArray[i].profil == 'EMPLOYEE')
        {
          this.contacts.push(tempContactArray[i]);
        }
      }
      if(res['hydra:view']['hydra:next']){
        this.loadContact(res['hydra:view']['hydra:next'].replace("/api", ""));
      }
    });
  }

  /*loadContact(url?: string){
    url = url ? url : undefined;
    if (this.data.profil === 1) {
      this.authService.getContactsRh(url).subscribe(res => {
        this.contacts = this.contacts.concat(res['hydra:member']);
        if(res['hydra:view']['hydra:next']){
          this.loadContact(res['hydra:view']['hydra:next'].replace("/api", ""));
        }
      });
    }else{
      this.authService.getContacts(url).subscribe(res => {
        let tempContactArray = res['hydra:member'];
        for (var i = 0; i < tempContactArray.length; i++) {
          if(tempContactArray[i].profil == 'EMPLOYEE' && this.projectIsSalary && (this.projectIsSalary.user.id != tempContactArray[i].id)){
             this.contacts.push(tempContactArray[i]);
          }
          else if (!this.projectIsSalary && tempContactArray[i].profil == 'EMPLOYEE')
          {
            this.contacts.push(tempContactArray[i]);
          }
        }
        if(res['hydra:view']['hydra:next']){
          this.loadContact(res['hydra:view']['hydra:next'].replace("/api", ""));
        }
      });
    }
  }*/


  newContact(){
    if (this.showNewContactInput == true)
      this.showNewContactInput = false;
    else
      this.showNewContactInput = true;
  }


  onSubmitForm(customerData) {
     // Process checkout data here
    //console.log('Your order has been submitted', customerData);

     // stop here if form is invalid
    if (this.emailFormControl.status == 'INVALID') {
      return;
    }

    this.authService.addUserProject(this.data.project, customerData, true, 2).subscribe(res => {
       //console.log('retour ajout membre mail => '+JSON.stringify(res));
      this.searchContact = "";
      alert('Invitation envoyé');

      if(res['user'] && res['user']['id']){
        var newUser = res;
        this.userStatus[res['user']['id']] = res['status'];
        this.data.projectUsers.push(newUser);
        this.messageService.needRefreshFiltre();
       }
    },
    (error) => {

      if(error.error && error.error.violations){
        alert(error.error.violations[0].message);
      }
      else
      {
        alert(error.statusText);
      }

    });

  }

  fctAdMember(userInfo){
    if(this.addingMember) return false;
    this.addingMember = true;
    this.authService.addUserProject(this.data.project, userInfo.id, null, 2).subscribe(res => {
       //console.log('retour ajout membre '+JSON.stringify(res['user']));
       if(res){
        var newUser = res;
        this.userStatus[res['user']['id']] = res['status'];
        this.data.projectUsers.push(newUser);
        this.searchContact = "";
       }
       this.addingMember = false;
    },
    (error) => {
      this.addingMember = false;
      if(error.error && error.error.violations){
        alert(error.error.violations[0].message);
      }
      else
      {
        alert(error.statusText);
      }

    });

  }

  getAvatarlUrl(avatarLink){
    return environment.server_url+avatarLink;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}


////////////////// dialog project info ////////////////////


@Component({
  selector: 'projectInfo-dialog',
  templateUrl: './projectInfo-dialog.html',
  styleUrls: ['./projectInfo-dialog.scss']
})
export class projectInfoDialog {

  imageFil = null;
  currentUser: any;

  isAdmin = false;
  noChangeImage = true;
  modeSupp = false;

  edit = false;
  fname = '';
  lname = '';
  dob = '';
  jobT = '';
  email = '';
  gender = 'male';
  description = '';
  profilUser = '';
  currentUserFullInfo;
  phone = '';
  img: any;
  idUser = null;
  contactInfo: any;
  mdpValues = '';
  isCurrentuser:boolean = true;

  constructor(
    public dialogRef: MatDialogRef<projectInfoDialog>,
    @Inject(MAT_DIALOG_DATA) public projectData: any,
    private authService: AuthService,
    private messageService: MessageService,
    private cd: ChangeDetectorRef,
    private _formBuilder: FormBuilder) {

      this.currentUser = JSON.parse(localStorage.getItem('currentUser'))['id'];
      this.currentUserFullInfo = JSON.parse(localStorage.getItem('currentUser'));

      /*if(this.projectData.creator.id == this.currentUser) {
        this.isAdmin = true;
      }*/

      for (var i = 0; i < this.projectData.userProject.length; i++) {
        if(this.projectData.userProject[i].profil == "EMPLOYEE"){
          if (this.projectData.userProject[i].user && this.projectData.userProject[i].user.id) {
            this.idUser = this.projectData.userProject[i].user.id;
            break;
          }
          else{
            this.email = this.projectData.userProject[i].email;
            this.profilUser = this.projectData.userProject[i].profil;
            this.jobT = this.projectData.userProject[i].jobTitle;
            this.img = '../../../../assets/img/default-avatar.png';
            break;
          }
        }
      }

      if (this.idUser) {
        if(((this.currentUserFullInfo.profil == 'HR') || (this.currentUserFullInfo.profil == 'HRD')) && this.currentUser != this.idUser) {
          this.isAdmin = true;
        }
        this.getCurrentUser();
      }
      else
      {
        if(((this.currentUserFullInfo.profil == 'HR') || (this.currentUserFullInfo.profil == 'HRD'))) {
          this.isAdmin = true;
        }
      }

  }

  getCurrentUser(){
   // console.log('getCurrentUser paramètre id ==> '+this.idUser);
    this.authService.getInfoContact(this.idUser)
      .subscribe( dataContact => {
        this.contactInfo = dataContact;
        //this.img = (this.contactInfo.imageUrl ? this.getAvatarlUrl(this.contactInfo.imageUrl) : '../../../../assets/img/default-avatar.jpg');

        if (this.contactInfo.imageUrl) {
          this.img = this.getAvatarlUrl(this.contactInfo.imageUrl)
        }
        else
        {
          this.img = '../../../../assets/img/default-avatar.png';
        }

        this.fname =  this.contactInfo.givenName;
        this.lname =  this.contactInfo.familyName;
        if(this.contactInfo.birthDate) {
          if (this.contactInfo.birthDate.date)
          {
            this.dob = this.contactInfo.birthDate.date;
          }
          else
          {
            this.dob = this.contactInfo.birthDate;
          }
        }
        //this.dob =  this.contactInfo.birthDate;
        this.jobT =  this.contactInfo.jobTitle;
        this.email =  this.contactInfo.email;
        this.gender =  this.contactInfo.gender;
        this.description =  this.contactInfo.description;
        this.phone =  this.contactInfo.telephone;
        this.profilUser = this.contactInfo.profil;

    }, (error) => {
        console.log('Uh-oh, an error occurred! : ' +JSON.stringify(error));
        alert('une erreur est survenue');
    });

  }

  getStatus(key){
    switch (key) {
      case 'HRD':
        return "DRH";
        break;
      case 'HR':
        return "RRH";
        break;
      case 'EMPLOYEE':
        return "Salarié";
        break;
      case 'CS':
        return "Manager";
        break;
      default:
        return key;
        break;
    }
  }

  saveProfile() {



    /*let latest_date = null;

    if (this.dob) {
      latest_date = this.datepipe.transform(this.dob, 'dd-MM-yyyy');
    }

    // console.log('getCurrentUser paramètre id ==> '+this.idUser);
    this.authService.udapteUser((this.fname ? this.fname : ''), (this.lname ? this.lname : ''), (latest_date ? latest_date : null), (this.jobT ? this.jobT : ''), (this.email ? this.email : '')
      ,(this.gender ? this.gender : ''), (this.description ? this.description : ''), (this.phone ? this.phone : '')).subscribe( data => {
       //console.log('retour saveProfile '+JSON.stringify(data));
       this.edit = false;
       this.getCurrentUser();
       alert('Modification(s) enregistré');
    }, (error) => {
        //console.log('Uh-oh, an error occurred! : ' +JSON.stringify(error));
        alert('une erreur est survenue');
    });


    if(this.imageFil) {
      this.authService.udapteUserImage(this.imageFil).subscribe( data => {
         //console.log('retour saveProfile '+JSON.stringify(data));
        this.getCurrentUser();
      }, (error) => {
          //console.log('Uh-oh, an error occurred! : ' +JSON.stringify(error));
          alert('une erreur est survenue');
      });

    }*/

  }

  ChoseImage(event): void{

    const reader = new FileReader();
    var files = event.files;

    // loop through files
    for (var i = 0; i < files.length; i++) {
      this.imageFil = files[i];
    }

    reader.readAsDataURL(this.imageFil);

    reader.onload = () => {
      // need to run CD since file load runs outside of zone
      this.cd.markForCheck();
      this.img = reader.result;
    };

  }


  getAvatarlUrl(avatarLink){
    return environment.server_url+avatarLink;
  }

  onClickValidate(): void{
   /* if(this.imageFil != null){
     this.authService.projectImage(this.projectData.id, this.imageFil)
     .subscribe(
      (success) => {
        //console.log('retour image success => '+JSON.stringify(success));
        alert('Modification enregistré');
      },
      (error) => {
        alert('Erreur ! : ' + error)
      });
    }
    else
    {
      alert('Veuillez choisir une image pour le projet')
    }*/
  }


  onClickdelete(): void {
    this.modeSupp = true;
  }

  onClickCancelSupp(): void{
    this.modeSupp = false;
  }

  onClickConfirmeDelete(): void{
    this.authService.deleteProject(this.projectData.id).subscribe(
      res => {
        this.modeSupp = false;
        this.dialogRef.close({deleteReturn: true});
        //this.stepper.selectedIndex = 1;
      },
      (error) => {
        alert('Erreur ! : ' + error)
      }
    );
  }

  onNoClick(): void {
    this.dialogRef.close();
  }


}

////////////////// dialog transfert ///////////////////

@Component({
  selector: 'dialogTransfert',
  templateUrl: 'transfertdialog.html',
})
export class transfertdialog {

  submitted = false;
  sendMessageForm: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<transfertdialog>,
    @Inject(MAT_DIALOG_DATA) public messageData: any,
    private formBuilder: FormBuilder,
     private authService: AuthService) {

      this.sendMessageForm = this.formBuilder.group({
        Destinataire: ['', Validators.required],
        EnCopie: '',
        message:  ''
     });
  }

  onSubmitForm(customerData) {

     // Process checkout data here
    // console.log(JSON.stringify(this.files));

    console.log('Your order has been submitted', customerData);

    this.submitted = true;
     // stop here if form is invalid
    if (this.sendMessageForm.invalid) {
      return;
    }

    this.sendTransfert(customerData);

    this.submitted = false;
    //this.sendMessageForm.reset();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  getErrorMessage() {
    return this.sendMessageForm.controls.Destinataire.hasError('required') ? 'Veuillez entrer une adresse email' :
        this.sendMessageForm.controls.Destinataire.hasError('email') ? 'Email non valide' : '';
  }

  sendTransfert(customerData){
    this.authService.transfertByMail(customerData, this.messageData).subscribe(
    (success) => {
      //console.log('retour image success => '+JSON.stringify(success));
      alert('Message transferé');
      this.dialogRef.close();
    },
    (error) => {
      alert('Erreur ! : ' + error)
    });
  }


}

////////////////// dialog change subject ///////////////////



@Component({
  selector: 'subjectInfo-dialog',
  templateUrl: './subjectInfo-dialog.html',
  styleUrls: ['./subjectInfo-dialog.scss']
})
export class subjectInfodialog {


  currentUser: any;
  projectTag = [];
  payeTag = [];
  socialeTag = [];
  informationTag = [];
  managerTag = [];
  tagInfoManager: any;
  renameSubjectManager = '';
  newSubjectManager = '';
  editeSubjectManager = false;
  addSubjectModeManager = false;
  userIsManager = false;
  irpTag = [];
  syndicatTag = [];
  tagCHO = [];
  isAdmin = false;
  tagInfo: any;
  projectTagWithNoMessage = [];
  idPaye = 0;
  idSocial = 0;
  idInfo = 0;
  idIrp = 0;


  constructor(
    public dialogRef: MatDialogRef<subjectInfodialog>,
    @Inject(MAT_DIALOG_DATA) public dataGet: any,
    private authService: AuthService,
    private messageService: MessageService,
    private cd: ChangeDetectorRef,
    private _formBuilder: FormBuilder) {

      this.currentUser = JSON.parse(localStorage.getItem('currentUser'))

      var projectMembers = this.dataGet.projectData['userProject'];

      for (var i = 0; i < projectMembers.length; i++) {
        if((projectMembers[i].isAdmin == true) && (projectMembers[i].user && (projectMembers[i].user.id == this.currentUser.id)))
        {
          this.isAdmin = true;
        }
      }


      if (this.dataGet.projectData.typeProject && (this.dataGet.projectData.typeProject == 'MANAGER')) {
        this.userIsManager = this.fctUserIsManager(this.dataGet.projectData);
      }

      if (this.dataGet.projectData && this.dataGet.projectData.typeProject && (this.dataGet.projectData.typeProject == 'PARTENAIRE' || this.dataGet.projectData.typeProject == 'SYNDICAT' || this.dataGet.projectData.typeProject == 'CGT' || this.dataGet.projectData.typeProject == 'CFDT' || this.dataGet.projectData.typeProject == 'FO' || this.dataGet.projectData.typeProject == 'CFE-CGC' || this.dataGet.projectData.typeProject == 'CFTC'
         || this.dataGet.projectData.typeProject == 'CHO')) {
          this.authService.getUserSkill(this.currentUser.id).subscribe(resSkill => {
            this.currentUser['skills'] = resSkill['skills'];
            this.projectTag = this.dataGet.projectData['tags'];
            //this.projectTag.sort(this.compare);
            this.iniTableSujet();
            this.getSujetWithNoMessage(this.dataGet.projectData.id);
          }, (error) => {
          });
      }
      else
      {
        this.projectTag = this.dataGet.projectData['tags'];
        //this.projectTag.sort(this.compare);
        this.iniTableSujet();
        this.getSujetWithNoMessage(this.dataGet.projectData.id);
      }

      /*if (this.dataGet.projectData && this.dataGet.projectData.typeProject && (this.dataGet.projectData.typeProject == 'PARTENAIRE' || this.dataGet.projectData.typeProject == 'SYNDICAT' || this.dataGet.projectData.typeProject == 'CGT' || this.dataGet.projectData.typeProject == 'CFDT' || this.dataGet.projectData.typeProject == 'FO' || this.dataGet.projectData.typeProject == 'CFE-CGC' || this.dataGet.projectData.typeProject == 'CFTC'
         || this.dataGet.projectData.typeProject == 'CHO')) {
          this.authService.getUserSkill(this.currentUser.id).subscribe(resSkill => {
            this.currentUser['skills'] = resSkill['skills'];
            this.projectTag = this.dataGet.projectData['tags'];
            this.projectTag.sort(this.compare);
            this.iniTableSujet();
            this.getSujetWithNoMessage(this.dataGet.projectData.id);
          }, (error) => {
          });
      }
      else
      {
        if (this.dataGet.projectData && this.dataGet.projectData.typeProject && this.dataGet.projectData.typeProject == 'MANAGER') {
          this.iniTableSujetManager();
        }
        else
        {
          this.projectTag = this.dataGet.projectData['tags'];
          this.projectTag.sort(this.compare);
          this.iniTableSujet();
          this.getSujetWithNoMessage(this.dataGet.projectData.id);
        }
      }*/


  }


  fctUserIsManager(project){
    if (project.manager && (project.manager.id == this.currentUser.id)){
      return true;
    }
    return false;
  }

  getSujetWithNoMessage(idProject){
    if(idProject != null)
    this.authService.getProjectSuject(idProject).subscribe(res => {
      this.projectTagWithNoMessage = res['hydra:member'];
    }, (error) => {
       //console.log(' getTheProject subscribe error '+error.statusText);
    });
  }


  compareTag(tag){
    for (var i = 0; i < this.projectTagWithNoMessage.length; i++) {
      if(this.projectTagWithNoMessage[i].id == tag.id){
        return true;
      }
    }
    return false;
  }

  iniTableSujet(){

      this.payeTag = [];
      this.socialeTag = [];
      this.informationTag = [];
      this.irpTag = [];
      this.syndicatTag = [];
      this.tagCHO = [];

      if(!this.dataGet.projectData.typeProject){
        for (var i = 0; i < this.projectTag.length; i++) {
          this.informationTag.push(this.projectTag[i]);
        }
        this.informationTag.sort(this.compare);
        return;
      }

      if(this.dataGet.projectData.typeProject == 'DRH' ){
        for (var i = 0; i < this.projectTag.length; i++) {
          this.socialeTag.push(this.projectTag[i]);
        }
        this.socialeTag.sort(this.compare);
        return;
      }


      if(this.dataGet.projectData.typeProject == 'CGT' || this.dataGet.projectData.typeProject == 'FO' || this.dataGet.projectData.typeProject == 'CFDT' || this.dataGet.projectData.typeProject == 'CFE-CGC' || this.dataGet.projectData.typeProject == 'CFTC'){
        for (var i = 0; i < this.projectTag.length; i++) {
          this.syndicatTag.push(this.projectTag[i]);
        }
        this.syndicatTag.sort(this.compare);
        return;
      }

      if(this.dataGet.projectData.typeProject == 'CHO' ){
        for (var i = 0; i < this.projectTag.length; i++) {
          this.tagCHO.push(this.projectTag[i]);
        }
        this.tagCHO.sort(this.compare);
        return;
      }


      if(this.dataGet.projectData.typeProject == 'PARTENAIRE' ){
        for (var i = 0; i < this.projectTag.length; i++) {
          this.irpTag.push(this.projectTag[i]);
        }
        this.irpTag.sort(this.compare);
        return;
      }

      if(this.dataGet.projectData.typeProject == 'MANAGER' ){
        for (var i = 0; i < this.projectTag.length; i++) {
          this.managerTag.push(this.projectTag[i]);
        }
         this.managerTag.sort(this.compare);
        return;
      }


   /*   if(this.dataGet.projectData.typeProject == 'CGT' || this.dataGet.projectData.typeProject == 'FO' || this.dataGet.projectData.typeProject == 'CFDT' || this.dataGet.projectData.typeProject == 'CFE-CGC' || this.dataGet.projectData.typeProject == 'CFTC'){
        for (var i = 0; i < this.projectTag.length; i++) {
          this.syndicatTag.push(this.projectTag[i]);
        }
        return;
      }

      if(this.dataGet.projectData.typeProject == 'CHO' ){
        for (var i = 0; i < this.projectTag.length; i++) {
          this.tagCHO.push(this.projectTag[i]);
        }
        return;
      }

      for (var i = 0; i < this.projectTag.length; i++) {

        if(this.projectTag[i].libelle == "Paye" && this.projectTag[i].tag == null)
        {
          this.idPaye = this.projectTag[i].id;
        }
        else if (this.projectTag[i].libelle == "Sociale" && this.projectTag[i].tag == null)
        {
          this.idSocial = this.projectTag[i].id;
        }
        else if (this.projectTag[i].libelle == "Information" && this.projectTag[i].tag == null)
        {
          this.idInfo = this.projectTag[i].id;
        }
        else if (this.projectTag[i].libelle == "IRP" && this.projectTag[i].tag == null)
        {
          this.idIrp = this.projectTag[i].id;
        }

      }

      //console.log('idPaye et autre '+ this.idPaye, this.idSocial, this.idInfo);

      if (this.idPaye != 0) {
        for (var i = 0; i < this.projectTag.length; i++) {
          if(this.projectTag[i].tag && this.projectTag[i].tag.id == this.idPaye)
          {
            this.payeTag.push(this.projectTag[i]);
          }
        }
      }

       if (this.idSocial != 0) {
        for (var i = 0; i < this.projectTag.length; i++) {
          if(this.projectTag[i].tag && this.projectTag[i].tag.id == this.idSocial && this.projectTag[i].libelle != 'CSSCT' && this.projectTag[i].libelle != "Comité d'entreprise"  && this.projectTag[i].libelle != "Rapport délégué du personnel" && this.projectTag[i].libelle != "Rapport comité d'entreprise" && this.projectTag[i].libelle != "Rapport syndical" )
          {
            this.socialeTag.push(this.projectTag[i]);
          }
        }
      }


      if (this.idInfo != 0) {
        for (var i = 0; i < this.projectTag.length; i++) {
          if(this.projectTag[i].tag && this.projectTag[i].tag.id == this.idInfo)
          {
            this.informationTag.push(this.projectTag[i]);
          }
        }
      }


    if (this.idIrp != 0) {
      for (var i = 0; i < this.projectTag.length; i++) {
        if(this.projectTag[i].tag && this.projectTag[i].tag.id == this.idIrp)
        {

          if (this.currentUser.skills.length > 0) {

            for (var index = 0; index < this.currentUser.skills.length; index++) {

              if(this.dataGet.projectData.typeProject == 'PARTENAIRE'){
                if (this.currentUser.skills[index] == 10 && this.projectTag[i].libelle == 'CSE') {
                  this.irpTag.push(this.projectTag[i]);
                }
              }

              if(this.dataGet.projectData.typeProject == 'SYNDICAT'){
                if (this.currentUser.skills[index] == 1 && this.projectTag[i].libelle == 'CGT') {
                  this.irpTag.push(this.projectTag[i]);
                }
                else if (this.currentUser.skills[index] == 5 && this.projectTag[i].libelle == 'CFDT') {
                  this.irpTag.push(this.projectTag[i]);
                }
                else if (this.currentUser.skills[index] == 6 && this.projectTag[i].libelle == 'FO') {
                  this.irpTag.push(this.projectTag[i]);
                }
                else if (this.currentUser.skills[index] == 7 && this.projectTag[i].libelle == 'CFE-CGC') {
                  this.irpTag.push(this.projectTag[i]);
                }
                else if (this.currentUser.skills[index] == 8 && this.projectTag[i].libelle == 'CFTC') {
                  this.irpTag.push(this.projectTag[i]);
                }
              }

            }
          }
          else
          {
            if(this.dataGet.projectData.typeProject == 'PARTENAIRE'){
              if (this.projectTag[i].libelle == 'CSE') {
                this.irpTag.push(this.projectTag[i]);
              }
            }
            else if(this.dataGet.projectData.typeProject == 'SYNDICAT'){
              if (this.projectTag[i].libelle == 'CGT') {
                this.irpTag.push(this.projectTag[i]);
              }
              else if (this.projectTag[i].libelle == 'CFDT') {
                this.irpTag.push(this.projectTag[i]);
              }
              else if (this.projectTag[i].libelle == 'FO') {
                this.irpTag.push(this.projectTag[i]);
              }
              else if (this.projectTag[i].libelle == 'CFE-CGC') {
                this.irpTag.push(this.projectTag[i]);
              }
              else if (this.projectTag[i].libelle == 'CFTC') {
                this.irpTag.push(this.projectTag[i]);
              }
            }
          }
        }
      }
    }*/
  }

  iniTableSujetManager(){

    this.managerTag = [];
    this.projectTag = [];
    this.newSubjectManager = '';
    this.tagInfoManager = null;
    this.renameSubjectManager = '';
    this.editeSubjectManager = false;
    this.addSubjectModeManager = false;

    this.authService.getTheProject(this.dataGet.projectData.id)
      .subscribe(res => {
        this.projectTag = res['tags'];
        //this.projectTag.sort(this.compare);
        for (var i = 0; i < this.projectTag.length; i++) {
          this.managerTag.push(this.projectTag[i]);
        }
        this.getSujetWithNoMessage(this.dataGet.projectData.id);
      }, (error) => {
         //console.log(' getTheProject subscribe error '+error.statusText);
    });

  }


  fctChangeSubjectInfo(element){
    //console.log('dans change subject => '+JSON.stringify(element));
    this.renameSubjectManager = '';
    this.tagInfoManager = element;
    this.editeSubjectManager = true;
  }

  doTextareaValueChangeManager(ev) {
    this.renameSubjectManager = ev.target.value;
  }

  fctaddSubjectManager(): void {
    this.addSubjectModeManager = true;
    this.newSubjectManager = '';
  }

  addSubjectCancelManager(): void {
    this.addSubjectModeManager = false;
    this.newSubjectManager = '';
  }

  writeNewSujetManager(newSubProjetName){
    //console.log('newSubProjetName => '+newSubProjetName);
    this.newSubjectManager = newSubProjetName;
  }

  validerModif(tag){
      if (this.renameSubjectManager.trim() != '') {
        this.authService.changeSubjectName(this.renameSubjectManager, tag.name, tag.id)
          .subscribe(res => {
            /*console.log(' retour validerModif => '+res);*/
            //this.envoyerObs.next();
            this.iniTableSujetManager();
          }, (error) => {
             //console.log(' getTheProject subscribe error '+error.statusText);
        });
      }
      else
      {
        this.tagInfoManager = null;
        this.editeSubjectManager = false;
        this.renameSubjectManager = '';
      }
  }

  addSubjectManager(): void {
    this.authService.addNewSubject(this.newSubjectManager, this.dataGet.projectData.id).subscribe(
      res => {
        /*this.managerTag.push(res);
        this.managerTag.sort(this.compare);
        this.newSubjectManager = '';
        this.addSubjectModeManager = false; */
        this.iniTableSujetManager();
      },
      (error) => {
      }
    );
  }

  compare( a, b ) {
    if ( a.libelle.trim().toLowerCase() < b.libelle.trim().toLowerCase() ){
      return -1;
    }
    if ( a.libelle.trim().toLowerCase() > b.libelle.trim().toLowerCase() ){
      return 1;
    }
    return 0;
  }

  fctChoseTag(tag): void {
    this.dialogRef.close(tag);
  }


  onNoClick(): void {
    this.dialogRef.close();
  }


}

////////////////// dialog info write message ///////////////////


@Component({
  selector: 'infoWriteMessageDialog',
  templateUrl: 'infoWriteMessageDialog.html',
   styleUrls: ['infoWriteMessageDialog.scss']
})
export class infoWriteMessageDialog implements OnInit{
  lang='fr';

  constructor(
    public dialogRef: MatDialogRef<infoWriteMessageDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private authService: AuthService,
    private messageService: MessageService,
    private _formBuilder: FormBuilder) {

  }

  ngOnInit() {
    this.messageService.getTranslate().subscribe((data) => {
      this.lang= data;
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
