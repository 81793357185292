import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth-service/auth.service';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-renew-confirmation',
  templateUrl: './renew-confirmation.component.html',
  styleUrls: ['./renew-confirmation.component.scss']
})
export class RenewConfirmationComponent implements OnInit {

  email = '';
  error = '';
  message = '';

  constructor(
    private authService: AuthService,
    private router: Router
    ) { }

  ngOnInit() {
    if (this.authService.logIn) {
      this.router.navigate(['/']);
    }
  }

  renewConf() {
    //console.log('recovering password for ' + this.email);
    this.authService.renewConf(this.email)
      .pipe(first())
      .subscribe(
        data => {
          this.message = "Demande reçue, un email vous a été envoyé a l'adresse indiquez. Cliquez sur le lien sur l'e-mail pour réinitialiser votre mot de passe.";
        },
        err => {
          const error = err.error;
          const errorS = error.message;
          this.error = error['hydra:description'];
        }
      );
  }

}
