import { Component, OnInit } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { MessageService } from 'src/app/services/message/message.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth-service/auth.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
  title = 'facewebapp';
  espaceProject:boolean = false;
  espaceDashbord:boolean = false;
  isProjectGlobal:boolean = false;
  showTool:boolean = false;
  showbtn = false;
  currentUser: any;

  constructor(
    location: Location,
    private translate: TranslateService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private messageService: MessageService,
    private authService: AuthService,
    private router: Router
  ) {

    translate.setDefaultLang('fr');

    this.matIconRegistry.addSvgIcon(
      "icon_calendar",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/calendar.svg")
    );

    router.events.subscribe((val) => {

      this.currentUser = JSON.parse(localStorage.getItem('currentUser'));

      // const projectGlobal = this.currentUser ? this.currentUser.projectGlobal : 'null';
      // console.log("ok", projectGlobal);
      //  if(location.path().includes('project/'+projectGlobal)){
      //    this.espaceProject = true;
      //    this.espaceDashbord = false;
      //    this.isProjectGlobal = true;
      //    if(this.currentUser.profil != 'EMPLOYEE'){
      //      this.showbtn = true;
      //    }
      // }else

      if(location.path().includes('project/')){
        this.espaceProject = true;
        this.espaceDashbord = false;
        this.showbtn = true;
        this.isProjectGlobal = false;
      }
      else if (location.path().includes('dashboard') && (this.currentUser && (this.currentUser.profil == "EMPLOYEE"))) {
        this.espaceDashbord = true;
        this.espaceProject = false;
        this.showbtn = true;
        this.isProjectGlobal = false;
      }
      else{
        this.espaceProject = false;
        this.espaceDashbord = false;
        this.showbtn = false;
        this.isProjectGlobal = false;
      }

    });

    this.showTool = false;

    this.messageService.getShowCreateProjectTutoIsFalse().subscribe(( ) =>{
        this.showTool = this.messageService.getShowProjectTuto();
    });

    this.messageService.setCloseWriteMessage().subscribe( (val) => {
        this.showbtn = true;
    });

    this.messageService.getHideMessage().subscribe( (val) => {
      if(val == true){
        this.showbtn = false;
      }else{
        this.showbtn = true;
      }

  });

    /*this.messageService.getWriteMessageFace().subscribe( (val) => {
      if(location.path().includes('project/')){
        this.fctEcrire();
      }
    });*/

    this.messageService.getMessageIsSend().subscribe(() => {
       this.showTool = false;
       this.messageService.setShowProjectTuto(false);
    });

    this.messageService.getAnswerOpen().subscribe( (val) => {
        this.showbtn = false;
    });


  }

  ngOnInit() {
    //console.log('valeur de this.show ==> '+this.showTool);
  }

  /*fctEcrire(){

    if(this.espaceDashbord == true)
    {
      this.authService.getProjects(undefined).subscribe(res => {
        // console.log(res)
        let projectLists = res['hydra:member'];
        if (projectLists.length > 0 ) {
          this.router.navigate(['/project/'+projectLists[0].id]);
          if (this.showTool == true) {
            this.messageService.setShowProjectTuto(false);
            this.showTool = false;
          }
          this.messageService.setWriteMessage(true);
          this.showbtn = false;
        }
      });
    }
    else
    {
      if (this.showTool == true) {
        this.messageService.setShowProjectTuto(false);
        this.showTool = false;
      }
      this.messageService.setWriteMessage(true);
      this.showbtn = false;
    }
  }*/


}
