import { Injectable } from '@angular/core';
import { Observable, Subject, ReplaySubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class MessageService {
    private subject = new Subject();
    private subjectbis = new Subject();
    private writeMessage = new Subject();
    private openWriteMessage = new Subject();
    private closeWriteMessage = new Subject();
    private translate = new ReplaySubject();
    private envoyerObs = new Subject();
    private guide = new Subject();
    private projectChosen = new Subject();
    private showCreateProjectTutoIsFalse = new Subject();
    private showProjectTutoIsFalse = new Subject();
    private messageIsSend = new Subject();
    private showCreateProjectTuto = true;
    private showProjectTuto = true;
    private showProjectTutobis = true;
    private showNotifTuto = true;
    private answerOpen = new Subject();
    private hideMsg = new Subject();
    private openListUser = new Subject();
    private reloadUserInfo = new Subject();
  

    nextReloadUserInfo() {
        this.reloadUserInfo.next();
    }

    obsreloadUserInfo() {
        return this.reloadUserInfo.asObservable();
    }

    setOpenListUser(id){
        this.openListUser.next(id);
    }

    getOpenListUser(){
        return this.openListUser.asObservable();
    }


    sendMessage(message: string) {
        this.subject.next();
    }

    messageUpdated() {
        this.subject.next();
    }

    needRefreshFiltre() {
        this.subjectbis.next();
    }

    messageRefreshConv() {
        this.envoyerObs.next();
    }

    getMessageRefreshConv(): Observable<any> {
        return this.envoyerObs.asObservable();
    }

    getMessage(): Observable<any> {
        return this.subject.asObservable();
    }

    getRefreshFiltre(): Observable<any> {
        return this.subjectbis.asObservable();
    }

    getWriteMessage(): Observable<any> {
        return this.writeMessage.asObservable();
    }

    setWriteMessage(id) {
        this.writeMessage.next(id);
    }

    setCloseWriteMessage(): Observable<any> {
        return this.closeWriteMessage.asObservable();
    }
 
    getWriteMessageFace(): Observable<any> {
        return this.openWriteMessage.asObservable();
    }

    openWriteMessageFace(openMode) {
       this.openWriteMessage.next(openMode);
    }

    setLoseWriteMessageFace(id){
        this.openWriteMessage.next(id);
    }

    getCloseWriteMessage(openMode){
       this.closeWriteMessage.next(openMode);
    }

    getHideMessage(): Observable<any> {
       return this.hideMsg.asObservable();
    }

    setHideMessage(id){
        this.hideMsg.next(id);
    }

    etCloseWriteMessage(): Observable<any> {
        return this.closeWriteMessage.asObservable();
     }

    setTranslate(id) {
        this.translate.next(id);
    }

    getTranslate(): Observable<any> {
        return this.translate.asObservable();
    }

    getGuide(): Observable<any> {
        return this.guide.asObservable();
    }

    setGuide(id) {
        this.guide.next(id);
    }

    setProjectChosen(id) {
        this.projectChosen.next(id);
    }

    getProjectChosen() {
        return this.projectChosen.asObservable();
    }

   setShowCreateProjectTuto(value) {
        this.showCreateProjectTuto = value;
    }

    getShowCreateProjectTuto() {
        return this.showCreateProjectTuto;
    }

    setShowProjectTuto(value) {
        this.showProjectTuto = value;
    }

    getShowProjectTuto() {
        return this.showProjectTuto;
    }

    setShowProjectTutobis(value) {
        this.showProjectTutobis = value;
    }

    getShowProjectTutobis() {
        return this.showProjectTutobis;
    }

    setShowNotifTuto(value) {
        this.showNotifTuto = value;
    }

    getShowNotifTuto() {
        return this.showNotifTuto;
    }

    getShowCreateProjectTutoIsFalse(): Observable<any> {
        return this.showCreateProjectTutoIsFalse.asObservable();
    }

    setShowCreateProjectTutoIsFalse() {
        this.showCreateProjectTutoIsFalse.next();
    }

    getShowProjectTutoIsFalse(): Observable<any> {
        return this.showProjectTutoIsFalse.asObservable();
    }

    setShowProjectTutoIsFalse() {
        this.showProjectTutoIsFalse.next();
    }

    setMessageIsSend() {
        this.messageIsSend.next();
    }

    getMessageIsSend(): Observable<any> {
        return this.messageIsSend.asObservable();
    }

    setAnswerOpen() {
        this.answerOpen.next();
    }

    getAnswerOpen(): Observable<any> {
        return this.answerOpen.asObservable();
    }


}