import { Component, HostListener, Input, ElementRef} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-app-file-upload',
  templateUrl: './app-file-upload.component.html',
  styleUrls: ['./app-file-upload.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: AppFileUploadComponent,
      multi: true
    }
  ]
})
export class AppFileUploadComponent implements ControlValueAccessor{  //ControlValueAccessor tells Angular how to access the control value,

  /*
   upon file change we set the control value to be the chosen file, 
   and that whenever we call control.patchValue(null) or control.reset() we’re clearing the file input.	
  */

  onChange: Function;
  private file: FileList | null = null;

  //Decorator that declares a DOM event to listen for, and provides a handler method to run when that event occurs.
  //We registered a change event listener that emits the files that the user uploads
  @HostListener('change', ['$event.target.files']) emitFiles( event: FileList ) {
    //console.log('item récupéré '+JSON.stringify(event));
    const file = event;
    this.onChange(file);//set the file to the value of FormControl
    this.file = file;
  }

  constructor(private host: ElementRef<HTMLInputElement> ) { }// get the input element in host


  //Write a new value to the element. Angular will call this method with the value in one of the following cases:
  //When you instantiate a new FormControl .
  //When you call this.control.patchValue/setValue(value)
  writeValue( value: null ) {
    // clear file input
    this.host.nativeElement.value = '';
    this.file = null;
  }

  //Set the function to be called when the control receives a change event. 
  //Angular provides you with a function and asks you to call it whenever there is a change in your component with the new value so that it can update the control.
  registerOnChange( fn: Function ) {
    this.onChange = fn;
  }

  //The registerOnTouched method is the same as registerOnChange except that you should call her when the control receives a touch event.
  registerOnTouched( fn: Function ) {
  }

}
