import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { AuthService } from 'src/app/services/auth-service/auth.service';
import { Router, ParamMap, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'src/app/services/message/message.service';
import { FormBuilder, FormGroup, Validators, FormControl, FormGroupDirective, NgForm} from '@angular/forms';
import {MatRadioModule} from '@angular/material/radio'; 
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Location } from '@angular/common';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  public lang:string = 'fr';
  sendMessageForm: FormGroup;
  sendFormPartenaire: FormGroup;
  homeContent = '';
  submitted = false;
  submittedPartenaire = false;
  indexPage = 0;
  @ViewChild('cursorLight') cursorLightElement: ElementRef;


  docsUrl = "";
  seletedMedia: any;
  currentIndex = 0;
  fileList: any;
  currentMedia: any;

  constructor(private locaton: Location, public dialog: MatDialog, private authService: AuthService, private messageService: MessageService, private router: Router,  private formBuilder: FormBuilder,  private route: ActivatedRoute) {
      
      this.fileList = [
        {
          file : '../../../assets/homerh/HomeRH-E1.jpg'
        },
        {
          file : '../../../assets/homerh/HomeRH-E2.jpg'
        },
        {
          file : '../../../assets/homerh/HomeRH-E3.jpg'
        },
        {
          file : '../../../assets/homerh/HomeRH-E4.jpg'
        },
        {
          file : '../../../assets/homerh/HomeRH-E5.jpg'
        },
        {
          file : '../../../assets/homerh/HomeRH-E6.jpg'
        },
        {
          file : '../../../assets/homerh/HomeRH-E7.jpg'
        },
        {
          file : '../../../assets/homerh/HomeRH-E8.jpg'
        },
        {
          file : '../../../assets/homerh/HomeRH-E9.jpg'
        },
        {
          file : '../../../assets/homerh/HomeRH-E10.jpg'
        },
        {
          file : '../../../assets/homerh/HomeRH-E11.jpg'
        },
        {
          file : '../../../assets/homerh/HomeRH-E12.jpg'
        },
        {
          file : '../../../assets/homerh/HomeRH-E13.jpg'
        }
        ,
        {
          file : '../../../assets/homerh/HomeRH-E14.jpg'
        }
        ,
        {
          file : '../../../assets/homerh/HomeRH-E15.jpg'
        }
      ];

      
      this.currentMedia = {
        file : '../../../assets/homerh/HomeRH-E15.jpg'
      };

      for (var i = 0; i < this.fileList.length; i++) {
        if(this.fileList[i] == this.currentMedia){
          this.currentIndex = i;
          // console.log("this.currentIndex", this.currentIndex)
        }
      }

    this.seletedMedia = this.fileList[this.currentIndex];

      router.events.subscribe((val) => {
        //console.log("out", location.href);
        if(location.href.includes('contact=true')){
          setTimeout(() => {
            window.scrollTo(0,document.body.scrollHeight);
          }, 2000);
        }else if(location.href.includes('openvideo=true')){
          this.openDialog();
        }
      });


      this.sendMessageForm = this.formBuilder.group({
        Nom: ['', Validators.required],
        Prenom: ['', Validators.required],
        Mail: ['', Validators.required],
        NomEntreprise: ['', Validators.required],
        telephone: [''],
        Fonction: [''],
        objet: ['', Validators.required],
        Message: ['']
      });

      this.sendFormPartenaire = this.formBuilder.group({
        Nom: ['', Validators.required],
        Prenom: ['', Validators.required],
        Mail: ['', Validators.required],
        NomEntreprise: ['', Validators.required],
        telephone: [''],
        Fonction: [''],
        objet: ['', Validators.required],
        Message: ['']
      });

  }

  onClickPrevious(){
    if ((this.currentIndex-1) >= 0) {
      this.currentIndex -= 1; 
      this.seletedMedia = this.fileList[this.currentIndex];
    }
  }

  onClickNext(){
    if ((this.currentIndex+1) < this.fileList.length) {
      this.currentIndex += 1; 
      this.seletedMedia = this.fileList[this.currentIndex];
    }
  }

  onClicReload(){
    this.currentIndex = 0; 
    this.seletedMedia = this.fileList[this.currentIndex];
  }



  scrollToPrevious(){
    if (this.indexPage > 0) {
      this.indexPage -= 1;
    }
  }

  scrollToNext(){
    if (this.indexPage < 5) {
      this.indexPage += 1;
    }
  }


  
  ngOnInit() {

    if (this.authService.logIn) {
      this.router.navigate(['/dashboard']);
    }
    
   this.messageService.getTranslate()
   .subscribe(
     (data) => {
       this.lang= data;
     }
   )

    this.route.paramMap.subscribe(params => {
 
    });
    
  }

  get f() { return this.sendMessageForm.controls; }

  get fbis() { return this.sendFormPartenaire.controls; }

  onSubmitMessage(){

    //console.log('dans le submitted');

    this.submitted = true;
     
    // stop here if form is invalid
    if (this.sendMessageForm.invalid) {
      return;
    }

    this.authService.contactMessage(
        this.sendMessageForm.value.Prenom,
        this.sendMessageForm.value.Nom,
        this.sendMessageForm.value.Mail,
        this.sendMessageForm.value.NomEntreprise,
        this.sendMessageForm.value.Fonction,
        this.sendMessageForm.value.Message,
        this.sendMessageForm.value.telephone,
        this.sendMessageForm.value.objet
      )
      .subscribe(res => {
      //console.log('reponse envoi message: ',res);
      this.sendMessageForm.reset();
      this.submitted = false;  
      alert("Message envoyé");
    },
    error => {
      alert("oups une erreur s'est produite!");
      this.submitted = false;  
    });

  }

  onSubmitMessagePartenaire(){

    //console.log('dans le submitted');

    this.submittedPartenaire = true;
     
    // stop here if form is invalid
    if (this.sendFormPartenaire.invalid) {
      return;
    }

    this.authService.contactMessagePartenaire(
        this.sendFormPartenaire.value.Prenom,
        this.sendFormPartenaire.value.Nom,
        this.sendFormPartenaire.value.Mail,
        this.sendFormPartenaire.value.NomEntreprise,
        this.sendFormPartenaire.value.Fonction,
        this.sendFormPartenaire.value.Message,
        this.sendFormPartenaire.value.telephone,
        this.sendFormPartenaire.value.objet
      )
      .subscribe(res => {
      //console.log('reponse envoi message: ',res);
      this.sendFormPartenaire.reset();
      this.submittedPartenaire = false;  
      alert("Message envoyé");
    },
    error => {
      alert("oups une erreur s'est produite!");
      this.submittedPartenaire = false;  
    });

  }

  gotoSubscribe(){
    this.router.navigate(['/register']);
  }

  gotoDiscover(){
    this.router.navigate(['/discoverview']);
  }

  scrollToElement($element): void {
    console.log($element);
    $element.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
  }
  
/*  gotoInscriptionEntreprise(){
    this.router.navigate(['/DrhComponent']); 
  }*/


  openDialog(): void {
    const dialogRef = this.dialog.open(DialogVideo, {
      width: '720px',
      height: '450px',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

 
  
}


@Component({
  selector: 'dialog-video',
  templateUrl: 'dialog-video.html',
})
export class DialogVideo {

  constructor(
    public dialogRef: MatDialogRef<DialogVideo>,
    @Inject(MAT_DIALOG_DATA) public data: null) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  contactUs() : void {
    this.dialogRef.close();
    setTimeout(() => {
      window.scrollTo(0,document.body.scrollHeight);
    }, 1000);
    
  }

}