import { Component, OnInit, Inject, ViewChild, ElementRef, Output, EventEmitter, OnDestroy, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { MatDialogConfig } from '@angular/material';
import { DashboardDialogComponent } from 'src/app/components/dashboard-menu/dashboard-menu.component';
import { MessageService } from 'src/app/services/message/message.service';
import { AuthService } from 'src/app/services/auth-service/auth.service';
import { Validators, FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { environment } from '../../../../environments/environment';
import { map, takeUntil } from 'rxjs/operators';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { MatStepper } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { Location } from '@angular/common';
import * as introJs from 'intro.js/intro.js';
import { CoreServiceService } from 'src/app/services/core/core-service.service';

import {
  AUTO_STYLE,
  animate,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';

const DEFAULT_DURATION = 300;


export interface Projects {
  name: string;
  date: string;
  groupName: string;
  url: string;
}

export interface DialogData {
  objectif: string;
  projetName: string;
}


@Component({
  selector: 'app-dashboardnav',
  templateUrl: './dashboardnav.component.html',
  styleUrls: ['./dashboardnav.component.scss'],
  animations: [
    trigger('collapse', [
      state('false', style({ height: AUTO_STYLE, visibility: AUTO_STYLE })),
      state('true', style({ height: '0', visibility: 'hidden' })),
      transition('false => true', animate(DEFAULT_DURATION + 'ms ease-in')),
      transition('true => false', animate(DEFAULT_DURATION + 'ms ease-out'))
    ])
  ]
})
export class DashboardnavComponent implements OnInit, AfterViewInit, OnDestroy {

  introJS = introJs();
  protected _onDestroy = new Subject<void>();

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.introJS.exit();
  }

  subscription: Subscription;
  projectLists: Array<any> = [];
  salaryLists: Array<any> = [];
  name: any;
  profile: any;
  commonRef: any;
  notificationload: any;
  // dialog: any;
  token: any;
  objectif: string;
  projetName: string;
  currentProjectId: string;
  searchProject: any;
  currentUser: any;
  lang = 'fr';
  displayedColumns: string[] = ['Columnsproject'];
  intro = null;
  stepProject = null;
  stepContact = null;
  stepNotif = null;
  stepInvit = null;
  stepBoard = null;
  skipLabel = null;
  doneLabel = null;
  prevLabel = null;
  nextLabel = null;
  showTool = 'false';
  route: string;
  img: any;
  imageFil = null;
  collapsed = true;

  @Output() projectChosen = new EventEmitter();

  constructor(
    public dialog: MatDialog,
    private authServices: AuthService,
    private router: Router,
    private routeAc: ActivatedRoute,
    private translate: TranslateService,
    location: Location,
    private cd: ChangeDetectorRef,
    private coreService: CoreServiceService,
    private messageService: MessageService) {


    this.loadProject();

    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));

    this.authServices.obRefreshProjet.subscribe(() => {
      this.projectLists = [];
      this.salaryLists = [];
      this.loadProject();
      // console.log(' obRefrehList me voila!!!');
    });

    this.subscription = this.messageService.getMessage().subscribe(() => {
      this.loadNotifProjet();
    });

    // console.log("translate", this.translate.get('guide.intro').subscribe);

    this.setVar();
    this.setTheGuide();
    this.messageService.getTranslate().subscribe((data) => {
      this.setVar();
      this.setTheGuide();
    });


    this.messageService.getGuide().subscribe(() => {
      this.introJS.start().oncomplete(function () {
        window.localStorage['guide'] = 1;
      });
    });

    /*if (this.messageService.getShowCreateProjectTuto() == true) {
      this.showTool = true;
    }*/

    this.loadUserImage();

    this.messageService.obsreloadUserInfo().subscribe(() => {
      this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
      this.authServices.getUserSkill(this.currentUser.id).subscribe(resSkill => {
        this.currentUser['skills'] = resSkill['skills'];
        localStorage.setItem('currentUser', JSON.stringify(this.currentUser));
        // console.log('Skills du user nav =>'+ this.currentUser['skills']);
      }, (error) => {
      });
      this.loadUserImage();
    });


  }


  toggle() {
    this.collapsed = !this.collapsed;
  }

  expand() {
    this.collapsed = false;
  }

  collapse() {
    this.collapsed = true;
  }


  ChoseImage(event): void {

    const reader = new FileReader();
    let files = event.files;

    // loop through files
    for (let i = 0; i < files.length; i++) {
      this.imageFil = files[i];
    }

    reader.readAsDataURL(this.imageFil);

    reader.onload = () => {
      // need to run CD since file load runs outside of zone
      this.cd.markForCheck();
      this.img = reader.result;
    };

    if (this.imageFil) {
      this.authServices.udapteUserImage(this.imageFil).subscribe(data => {
        // console.log('retour saveProfile '+JSON.stringify(data));
        this.messageService.nextReloadUserInfo();
        this.loadUserImage();
      }, (error) => {
        // console.log('Uh-oh, an error occurred! : ' +JSON.stringify(error));
        alert('une erreur est survenue');
      });

    }

  }

  loadUserImage() {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (this.currentUser.imageUrl) {
      this.img = this.getAvatarlUrl(this.currentUser.imageUrl);
    } else {
      this.img = '/assets/img/default-avatar.png';
    }
  }

  getStatus(key) {
    switch (key) {
      case 'HRD':
        return 'DRH';
        break;
      case 'HR':
        return 'RRH';
        break;
      case 'EMPLOYEE':
        return 'Salarié';
        break;
      case 'CS':
        return 'Salarié';
        break;
      default:
        return key;
        break;
    }
  }

  setVar() {
    this.translate.get('guide.intro').subscribe(value => {
      this.intro = value;
    });
    this.translate.get('guide.stepProject').subscribe(value => {
      this.stepProject = value;
    });
    this.translate.get('guide.stepContact').subscribe(value => {
      this.stepContact = value;
    });
    this.translate.get('guide.stepNotif').subscribe(value => {
      this.stepNotif = value;
    });
    this.translate.get('guide.stepInvit').subscribe(value => {
      this.stepInvit = value;
    });
    this.translate.get('guide.stepBoard').subscribe(value => {
      this.stepBoard = value;
    });
    this.translate.get('guide.skipLabel').subscribe(value => {
      this.skipLabel = value;
    });
    this.translate.get('guide.doneLabel').subscribe(value => {
      this.doneLabel = value;
    });
    this.translate.get('guide.prevLabel').subscribe(value => {
      this.prevLabel = value;
    });
    this.translate.get('guide.nextLabel').subscribe(value => {
      this.nextLabel = value;
    });
  }


  setTheGuide() {
    setTimeout(() => {
      this.introJS.setOptions({
        skipLabel: this.skipLabel,
        doneLabel: this.doneLabel,
        prevLabel: this.prevLabel,
        nextLabel: this.nextLabel,
        steps: [
           {
            intro: this.intro,
          },
          {
            element: '#stepProject',
            intro: this.stepProject,
            position: 'left'
          },
          {
            element: '#stepContact',
            intro: this.stepContact,
            position: 'bottom'
          },
          {
            element: '#stepNotif',
            intro: this.stepNotif,
            position: 'bottom'
          },
          {
            element: '#stepInvit',
            intro: this.stepInvit,
            position: 'bottom'
          },
          {
            element: '#stepBoard',
            intro: this.stepBoard,
            position: 'bottom'
          }
        ]
      });

    }, 2000);
  }


  startDemo() {
    if(this.router.url === "/dashboard"){
      this.introJS.setOptions({
          steps: [
            {
              intro: '<i> <B>Welcome!</B> </i> 👋'
            },
            {
              element: '#step1',
              intro: 'Cliquez ici pour consulter votre journal',
              position: 'auto'
            },
            {
              element: '#step2',
              intro: 'Voir Les droits des profils!',
              position: 'auto'
            },
            {
              element: '#step3',
              intro: 'Configurez ici vos sujets!',
              position: 'auto'
            },
            {
              element: '#step4',
              intro: 'Configurez ici votre profil!',
              position: 'auto'
            },
            {
              element: '#step5',
              intro: 'Cliquez ici pour consulter les notifications!',
              position: 'auto'
            },
            {
              element: '#step6',
              intro: 'Cliquez ici pour configurer votre plateforme!',
              position: 'auto'
            },
            {
              element: '#step9',
              intro: "Cliquez ici pour consulter vos différents espaces!",
              position: 'auto'
            }/* ,
            {
              element: '#step10',
              intro: "Cliquez ici pour consulter l'espace <B> CSE/Salarié</B>!",
              position: 'auto'
            },
            {
              element: '#step11',
              intro: "Cliquez ici pour consulter l'espace <B> CHO/Salarié</B>!",
              position: 'auto'
            },
            {
              element: '#step12',
              intro: "Cliquez ici pour consulter l'espace <B>Syndicats/Salarié</B>!",
              position: 'auto'
            } */
          ]
          ,
          showProgress: true,
          hideNext: true,
          hidePrev: true,
          showStepNumbers: true,
          keyboardNavigation: true,
          showButtons:  true,
          scrollToElement: true,
          showBullets:  true
        }
      ).start();
    }
  }

  ngOnInit() {

    this.routeAc.params.subscribe(paramsId => {
      this.currentProjectId = paramsId.ID;
    });

    //this.showTool = localStorage.getItem('popupCreateSalary');
    this.showTool = 'false';

    this.expand();

  }


  fctResponsable(project){
    
    if (project.userProject) {

      for (var i = 0; i < project.userProject.length; i++) {
        if(project.typeProject && project.typeProject == 'DRH'){
          if(project.userProject[i].user && project.userProject[i].user.profil == 'HRD'){
            return project.userProject[i].user.fullName;
          }
        }
        else if(project.typeProject && project.typeProject == 'PARTENAIRE'){
          if(project.userProject[i].user && project.userProject[i].user.skills.includes(10) == true ){
            return project.userProject[i].user.fullName;
          }
        }
        else if(project.typeProject && project.typeProject == 'CHO'){
          if(project.userProject[i].user && project.userProject[i].user.skills.includes(20) == true ){
            return project.userProject[i].user.fullName;
          }
        }   
        else if(project.typeProject && project.typeProject == 'MANAGER' && project.manager){
          if(project.userProject[i].user && (project.manager.id == project.userProject[i].user.id)){
            return project.userProject[i].user.fullName;
          }
        }
      }

    }

    return '';

  }

  ngAfterViewInit(): void {
    this.coreService.startDemo
    .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.startDemo();
      });
  }


  changeLang() {
    this.translate.use(this.lang);
    this.messageService.setTranslate(this.lang);
  }


  userInscritionStatus(project) {
    if (!project.typeProject) {
      var projectUser = project['userProject'];
       for (var i = 0; i < projectUser.length; i++) {
         if(projectUser[i].profil == "EMPLOYEE" && !projectUser[i].user){
            return "(non-inscrit)"
         }
       }
    }
    return '';
  }


  setetSalaries(list) {
    list = list.map(item => {
      item.salary = item.userProject.filter(item => item.profil === 'EMPLOYEE');
      return item;
    });
    return list;
  }

  loadProject(url?: string) {
    url = url ? url : undefined;
    this.authServices.getProjects(url).subscribe(res => {
      // console.log(res)
      let list = this.setetSalaries(res['hydra:member']);

      if (list.length > 0) {
        this.projectLists = this.projectLists.concat(list);
      }

      if (res['hydra:view']['hydra:next']) {
        this.loadProject(res['hydra:view']['hydra:next'].replace('/api', ''));
      } else if (res['hydra:totalItems'] === this.projectLists.length) {
        if (this.projectLists.length > 0) {

          let arrayDRH = [];
          let arraySyndicat = [];
          let arrayMyManager = [];
          let arrayManager = [];
          let arrayIrp = [];
          let arrayCHO = [];
          let arrayMyProject = [];
          let hasSyndicat = false;
          this.salaryLists = [];

          for (let i = 0; i < this.projectLists.length; i++) {

            if (this.projectLists[i].typeProject === 'DRH') {
              this.projectLists[i]['userIsDesigned'] = this.currentUser.profil === 'HRD' ? true : false;
              arrayDRH.push(this.projectLists[i]);
            } else if (this.projectLists[i].typeProject === 'PARTENAIRE') {
              this.projectLists[i]['userIsDesigned'] = this.currentUser.skills.includes(10) ? true : false;
              arrayIrp.push(this.projectLists[i]);
            } else if (this.projectLists[i].typeProject === 'MANAGER') {
              if(this.fctUserIsManager(this.projectLists[i]) == true){
                if (this.currentUser.isManager && this.currentUser.isManager == true ) {
                  this.projectLists[i]['userIsDesigned'] = true;
                  arrayMyManager.push(this.projectLists[i]);
                }
              }
              else 
              {
                arrayManager.push(this.projectLists[i]);
              }
            } else if (this.projectLists[i].typeProject === 'CHO') {
              this.projectLists[i]['userIsDesigned'] = this.currentUser.skills.includes(20) ? true : false;
              arrayCHO.push(this.projectLists[i]);
            } else if (this.itsMyProject(this.projectLists[i]) == true) {
              this.projectLists[i]['userIsDesigned'] = true;
              arrayMyProject.push(this.projectLists[i]);
            }
            else if (this.projectLists[i].espace && this.projectLists[i].espace == "SYNDICAT") {
              hasSyndicat = true;
            }
            else if (!this.projectLists[i].typeProject && (this.currentUser.profil === 'HR' || this.currentUser.profil === 'HRD'))
            {
              this.salaryLists.push(this.projectLists[i]);
            }

          }

          this.projectLists = [];
          let tmpArrayProjectsListe = [];
          let projectsListeMy = [];
          let projectsListeOther = [];
 
          tmpArrayProjectsListe = tmpArrayProjectsListe.concat(arrayMyProject);
          tmpArrayProjectsListe = tmpArrayProjectsListe.concat(arrayDRH);
          //tmpArrayProjectsListe = tmpArrayProjectsListe.concat(arrayMyManager);
          tmpArrayProjectsListe = tmpArrayProjectsListe.concat(arrayIrp);
         // tmpArrayProjectsListe = tmpArrayProjectsListe.concat(arrayCHO);

          if (hasSyndicat == true) {
            arraySyndicat.push({
              '@id': null,
              '@type': null,
              'id': '-2',
              'imageUrl': null,
              'libelle': 'Les syndicat',
              'description': null,
              'lastmsgextrait': null,
              'isDefaultChanel': false,
              'typeProject': 'allSyndicat',
              'deadline': null,
              'motionAt': null,
              'dateModified': null,
              'dateCreated': null,
              'userProject': null,
              'creator': null,
              'manager': null,
              'userIsDesigned': false
            });
            tmpArrayProjectsListe = tmpArrayProjectsListe.concat(arraySyndicat);
          }

          //tmpArrayProjectsListe = tmpArrayProjectsListe.concat(arrayManager);


          tmpArrayProjectsListe = tmpArrayProjectsListe.sort((n1, n2) => {
            if ((n1['userIsDesigned'] === true) && (n2['userIsDesigned'] === false)) {
              return -1; // n1 plus petit que n2
            }
            if ((n1['userIsDesigned'] === false) && (n2['userIsDesigned'] === true)) {
              return 1; // n1 plus grand que n2
            }
            return 0; // sont égaux
          });

          for (let i = 0; i < tmpArrayProjectsListe.length; i++) {
            tmpArrayProjectsListe[i]['userIsDesigned'] === true ? projectsListeMy.push(tmpArrayProjectsListe[i])
             : projectsListeOther.push(tmpArrayProjectsListe[i]);
          }


          this.projectLists = this.projectLists.concat(projectsListeMy);
          this.projectLists = this.projectLists.concat(projectsListeOther);


        }
        else
        {
          this.showTool ='true';
        }

      }
    });
  }

  array_move(arr, old_index, new_index) {
    if (new_index >= arr.length) {
      let k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
  }


  fctUserIsManager(project){
    if (project.manager && (project.manager.id == this.currentUser.id)){
      return true;
    }
    return false;
  }

  loadNotifProjet() {
    this.authServices.getNotification().subscribe(res => {
      this.notificationload = <any>res;
      for (let i = 0; i < this.projectLists.length; i++) {
        for (let index = 0; index < this.notificationload.length; index++) {
          if (this.notificationload[index].id === this.projectLists[i].id) {
            this.projectLists[i].notif = this.notificationload[index].total;
          }
        }
      }
    });
  }

  itsMyProject(project){
    if (!project.typeProject) {
      for (var i = 0; i < project.userProject.length; i++) {
        if ((project.userProject[i].profil == 'EMPLOYEE') && project.userProject[i].user && (project.userProject[i].user.id == this.currentUser.id)) {
          //console.log('mon project est ==>', project.libelle);
          return true;
        }
      }
    }
    return false;
  }

  haveTheRight(project){
    if ((project.typeProject == 'allSalary') && (this.currentUser.profil != 'HR' && this.currentUser.profil != 'HRD') ) {
       return 'false';
    }
    else{
      return 'true';
    }
  }

  openDialog(): void {

    this.showTool = 'false';
    //localStorage.setItem('popupCreateSalary', 'false');

    /*this.messageService.setShowCreateProjectTuto(false);
    this.messageService.setShowCreateProjectTutoIsFalse();*/

    const dialogRef = this.dialog.open(dashboarddialogCompo, {
      minWidth: '650px',
      position: { top: '50px' },
      data: this.projectLists
    });

    dialogRef.afterClosed().subscribe(result => {

      if (result) {
        /*this.projectLists.unshift(result);*/
        this.authServices.obRefreshProjet.next();
        this.currentProjectId = result.id;
        this.projectChosen.emit(result.id);
        this.router.navigate(['/project/' + result.id]);
      }

    });
  }

  whereTogo(project){
    if (this.haveTheRight(project) == 'true') {
      if (project.typeProject == 'allSalary') {
        this.currentProjectId = project.id;
        this.router.navigate(['/EmployeeJournal']);
      }
      else if (project.typeProject == 'allSyndicat' ) {
        this.currentProjectId = project.id;
        this.router.navigate(['/unionNewspaper']);
      }
      else{
        this.fctprojectChosen(project.id);
      }
    }
  }



  fctprojectChosen(projectId) {

    this.currentProjectId = projectId;
    this.projectChosen.emit(projectId);
    this.router.navigate(['/project/' + projectId]);

    /*if (this.currentUser.profil === 'EMPLOYEE') {
      this.showTool = 'false';
      localStorage.setItem('popupCreateSalary', 'false');
     
    }*/

    /*setTimeout(() => {
      const currentProj = this.projectLists.filter(item => item.id == projectId)[0];
      this.projectLists = this.projectLists.filter(item => item.id != projectId);
      this.projectLists.unshift(currentProj);
    }, 1000);*/

  }

  getAvatarlUrl(avatarLink) {
    return environment.server_url + avatarLink;
  }

  openDialogInfo() {
    const dialogRef = this.dialog.open(tutoCreateProjectdialog, {
      minWidth: '850px',
      position: { top: '50px' },
      data: {}
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  fctGetImageUser(userData) {
    if (userData.imageUrl) {
      return this.getAvatarlUrl(userData.imageUrl);
    } else {
      // return '/assets/img/default-avatar.png';
      // console.log('gender du user ==>'+ userData.gender);
      if (userData.gender === 'male') {
        return '../../../../assets/img/employee.png';
      } else if (userData.gender === 'female') {
        return '../../../../assets/img/businesswoman.png';
      } else {
        return '../../../../assets/img/employee.png';
      }
    }
  }

}


@Component({
  selector: 'dashboarddialog.component',
  templateUrl: 'dashboarddialog.component.html',
  styleUrls: ['dashboarddialog.component.scss']
})
export class dashboarddialogCompo implements OnInit {


  // @ViewChild('stepper') stepper: MatStepper;
  @ViewChild('addProjectForm') addProjectForm;
  @ViewChild('fileInput') fileInput: ElementRef;

  firstFormGroup: FormGroup;
  addingMember = [];
  hasMemberAdding = false;
  projectUsers = [];
  userStatus = [];
  projectId = null;
  projectDetails = null;
  contacts = [];
  searchContact: any;
  arrayEmailInvitation = [];
  arrayEmailInvitationWithId = [];
  arraySubject = [];
  newProjectSubject = '';
  lname = '';
  fname = '';
  gender = 'male';
  newProjectProf = '';
  newProjectEmail = '';
  currenteDate: any;
  showComfirmMessage = false;
  createProjectMode = 1;
  formCSV = false;
  ContactDRH = [];

  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);

  lemail = new FormControl('', [Validators.email]);

  constructor(
    public dialogRef: MatDialogRef<dashboarddialogCompo>,
    @Inject(MAT_DIALOG_DATA) public dataProjectList: any,
    private authService: AuthService,
    public dialog: MatDialog,
    private _formBuilder: FormBuilder) {

    this.contacts = [];
    this.loadContact();

  }


  getStatus(key) {
    switch (key) {
      case 'HRD':
        return 'D.R.H.';
        break;
      case 'HR':
        return 'R.H.';
        break;
      case 'EMPLOYEE':
        return 'Salarié';
        break;
      default:
        return key;
        break;
    }
  }


  ngOnInit() {
    this.arrayEmailInvitation = [];
    this.arrayEmailInvitationWithId = [];
    this.addingMember = [];
    this.createProjectMode = 1;
    this.currenteDate = new Date();
    this.firstFormGroup = this._formBuilder.group({
      projectDataArray: [[], Validators.required],
      clickCreate: ['', [Validators.required, Validators.requiredTrue]],
      objectif: [''],
      deadLine: [''],
      sujectList: [[]],
    });
    // this.initSubject();// ajout theme
    this.formCSV = false;
  }

  loadContact(url?: string) {
    url = url ? url : undefined;
    this.authService.getContactsRh(url).subscribe(res => {
      let arrayuser = res['hydra:member'];
      let newArrayNoDrh = [];
      for (let i = 0; i < arrayuser.length; i++) {
        if (arrayuser[i].profil === 'HRD') {
          this.ContactDRH.push(arrayuser[i]);
          this.fctAdMember(arrayuser[i]);
        } else {
          newArrayNoDrh.push(arrayuser[i]);
          this.fctAdMember(arrayuser[i]);
        }
      }
      this.contacts = this.contacts.concat(newArrayNoDrh);
      if (res['hydra:view']['hydra:next']) {
        this.loadContact(res['hydra:view']['hydra:next'].replace('/api', ''));
      }
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }


  onSubmitProject() {

    // console.log('firstFormGroup value => ' , this.firstFormGroup.value);

    if (this.firstFormGroup.get('projectDataArray').value.length === 0) {
      return;
    }

    let result = this.firstFormGroup.value;


    for (let i = 0; i < result.projectDataArray.length; i++) {

      let arrayUserProject = [];

      // ajout collaborateur au projet
      for (let index = 0; index < this.addingMember.length; index++) {
        if (this.addingMember[index].profil === 'HRD') {
          arrayUserProject.push({ 'user': '/api/users/' + this.addingMember[index].id, 'profil': 'HRD', 'isAdmin': true });
        } else {
          arrayUserProject.push({ 'user': '/api/users/' + this.addingMember[index].id, 'profil': 'HR', 'isAdmin': true });
        }
      }

      for (let indexbis = 0; indexbis < this.arrayEmailInvitation.length; indexbis++) {
        arrayUserProject.push({ 'email': this.arrayEmailInvitation[indexbis], 'profil': 'HR', 'isAdmin': true });
      }

      // ajout salarié au projet
      if (result.projectDataArray[i].idUser) {
        arrayUserProject.push({ 'user': '/api/users/' + result.projectDataArray[i].idUser, 'profil': 'EMPLOYEE' });
      } else {
        arrayUserProject.push({ 'profil': 'EMPLOYEE', 'email': result.projectDataArray[i].email,
         'jobTitle': result.projectDataArray[i].profession, 'givenName': result.projectDataArray[i].lname,
          'familyName': result.projectDataArray[i].fname, 'gender': result.projectDataArray[i].gender });
      }

      // console.log('arrayUserProject ==> '+ JSON.stringify(arrayUserProject));

      let lunchProject = false;

      this.authService.createProject(result.projectDataArray[i].name, result.objectif, result.deadLine,
         result.sujectList, arrayUserProject).subscribe(
        res => {
          let resultData = res;
          // this.addSousSujet(resultData); // ajout sous theme
          res['salary'] = res['userProject'].filter(item => item.profil === 'EMPLOYEE');
          //this.dataProjectList.unshift(res);
          if (lunchProject === false) {
            lunchProject = true;
            this.projectDetails = res;
            this.projectId = res['id'];
            this.dialogRef.close(this.projectDetails);
          }
        },
        (error) => {
          alert('Erreur ! : ' + error);
        }
      );

    }

  }


  /*addSousSujet(thisProjectData) {

    let listeTagPaie = ['Accident du Travail', 'Arrêt de maladie', 'Bulletins de paie', 'Congé de maternité',
     'Congé de payé', 'Mobilité', 'Heure Supplémentaires', 'Temps partiel', 'Transport'];
    let listeTagSociale = ['Informations générales', 'C.H.S.C.T', 'Comité d\'entreprise',
     'Compte rendu de réunion RH', 'Législation du travail', 'Médecine du travail',
      'Rapport comité d\'entreprise', 'Rapport délégué du personnel', 'Rapport syndical'];
    let listeTagInfo = ['Attestation de travail', 'Contrat et avenant d\'embauche', 'CV',
     'Déclaration unique d\'embauche', 'Entretien annuel', 'Entretien profession (2 ans)',
      'Entretien d\'embauche', 'Formation', 'Rupture de contrat ou de mission', 'Télétravail'];

    let idPaye = 0;
    let idInformation = 0;
    let idSociale = 0;

    if (thisProjectData['tags']['0'].libelle === 'Paye') {
      idPaye = thisProjectData['tags']['0'].id;
    } else if (thisProjectData['tags']['1'].libelle === 'Paye') {
      idPaye = thisProjectData['tags']['1'].id;
    } else if (thisProjectData['tags']['2'].libelle === 'Paye') {
      idPaye = thisProjectData['tags']['2'].id;
    }

    if (thisProjectData['tags']['0'].libelle === 'Information') {
      idInformation = thisProjectData['tags']['0'].id;
    } else if (thisProjectData['tags']['1'].libelle === 'Information') {
      idInformation = thisProjectData['tags']['1'].id;
    } else if (thisProjectData['tags']['2'].libelle === 'Information') {
      idInformation = thisProjectData['tags']['2'].id;
    }

    if (thisProjectData['tags']['0'].libelle === 'Sociale') {
      idSociale = thisProjectData['tags']['0'].id;
    } else if (thisProjectData['tags']['1'].libelle === 'Sociale') {
      idSociale = thisProjectData['tags']['1'].id;
    } else if (thisProjectData['tags']['2'].libelle === 'Sociale') {
      idSociale = thisProjectData['tags']['2'].id;
    }

    for (let i = 0; i < listeTagPaie.length; i++) {
      this.authService.ajoutSousTag(listeTagPaie[i], thisProjectData['id'], idPaye).subscribe(
        res => {
        },
        (error) => {
        }
      );
    }

    for (let i = 0; i < listeTagSociale.length; i++) {
      this.authService.ajoutSousTag(listeTagSociale[i], thisProjectData['id'], idSociale).subscribe(
        res => {
        },
        (error) => {
        }
      );
    }

    for (let i = 0; i < listeTagInfo.length; i++) {
      this.authService.ajoutSousTag(listeTagInfo[i], thisProjectData['id'], idInformation).subscribe(
        res => {
        },
        (error) => {
        }
      );
    }

  }*/

  validateClick(): void {
    this.addProjectForm.nativeElement.submit();
    // this.dialogRef.close(result);
  }


  fctAddcontactByMail() {

    // stop here if form is invalid
    if (this.emailFormControl.status === 'INVALID') {
      return;
    }

    let result = this.emailFormControl.value;


    this.authService.getUserInfo(result).subscribe(
      res => {

        let arrayUser = res['hydra:member'];

        if (arrayUser.length > 0) {
          let newUser = arrayUser[0];
          this.addingMember.push(newUser);
          this.userStatus[newUser['id']] = 'PENDING';
          this.hasMemberAdding = true;
          this.emailFormControl.reset();
          this.arrayEmailInvitationWithId.push(result);
        }
        else {
          this.arrayEmailInvitation.push(result);
          this.hasMemberAdding = true;
          this.emailFormControl.reset();
        }

        this.searchContact = '';

      },
      (error) => {
        alert('Erreur ! : ' + error);
      }
    );

  }


  clickConfirm() {
    // this.dialogRef.close(this.projectDetails);
    this.showComfirmMessage = false;
  }

  clickRefus() {
    this.showComfirmMessage = false;
  }

  gotoStepTwo() {
    // console.log("Project ID go : "+this.projectDetails);
    this.createProjectMode = 2;
  }

  goToAddMember() {
    if (this.formCSV == true) {
      let arrayProject = this.firstFormGroup.value.projectDataArray;
      if (arrayProject.length > 0) {
        if ((this.lname.trim().length === 0) && (this.fname.trim().length == 0)
         && (this.newProjectProf.trim().length === 0) && (this.newProjectEmail.trim().length === 0) && (this.gender.trim().length === 0)) {
          // this.createProjectMode = 3;
          this.onSubmitProject();
        } else {
          this.addProject(true);
        }
      } else {
        this.addProject(true);
      }
    } else {
      this.addProject(true);
    }
  }

  addProject(goToNexTStep) {

    if (this.lemail.invalid) {
      return;
    }

    this.authService.getUserInfo(this.newProjectEmail).subscribe(
      res => {

        let arrayUser = res['hydra:member'];
        let newProject = this.lname + ' ' + this.fname;
        let fnameProject = this.fname;
        let lnameProject = this.lname;
        let genderProject = this.gender;
        let newProjectProf = this.newProjectProf;
        let newProjectEmail = this.newProjectEmail;

        /*console.log('USER est trouvé =>'+ JSON.stringify(arrayUser));*/

        if ((newProject.trim().length > 0) && (newProjectProf.trim().length > 0) && (newProjectEmail.trim().length > 0)) {

          let arrayProject = this.firstFormGroup.value.projectDataArray;

          if (arrayUser.length > 0) {
            let newUser = arrayUser[0];
            arrayProject.push({ 'fname': fnameProject, 'lname': lnameProject, 'name': newProject,
             'gender': genderProject, 'profession': newProjectProf, 'email': newProjectEmail, 'idUser': newUser['id'] });
          } else {
            arrayProject.push({ 'fname': fnameProject, 'lname': lnameProject, 'name': newProject,
             'gender': genderProject, 'profession': newProjectProf, 'email': newProjectEmail, 'idUser': null });
          }

          this.firstFormGroup.patchValue({
            projectDataArray: arrayProject
          });

        }

        console.log('firstFormGroup projectDataArray value => ', this.firstFormGroup.value);

        this.fname = '';
        this.lname = '';
        this.gender = 'male';
        this.newProjectProf = '';
        this.newProjectEmail = '';

        if (goToNexTStep == true) {
          // this.createProjectMode = 3;
          this.onSubmitProject();
        }

      },
      (error) => {
        alert('Erreur ! : ' + error);
      }
    );

  }


  addProjectUserFormCsv(userCsvInfo) {


    this.authService.getUserInfo(userCsvInfo.email).subscribe(
      res => {

        let arrayUser = res['hydra:member'];

        /*console.log('USER est trouvé =>'+ JSON.stringify(arrayUser));*/

        let arrayProject = this.firstFormGroup.value.projectDataArray;

        if (arrayUser.length > 0) {
          let newUser = arrayUser[0];
          arrayProject.push({ 'fname': userCsvInfo.nom, 'lname': userCsvInfo.prenom,
           'name': userCsvInfo.prenom + ' ' + userCsvInfo.nom, 'gender': userCsvInfo.gender,
            'profession': userCsvInfo.profession, 'email': userCsvInfo.email, 'idUser': newUser['id'] });
        } else {
          arrayProject.push({ 'fname': userCsvInfo.nom, 'lname': userCsvInfo.prenom,
           'name': userCsvInfo.prenom + ' ' + userCsvInfo.nom, 'gender': userCsvInfo.gender,
            'profession': userCsvInfo.profession, 'email': userCsvInfo.email, 'idUser': null });
        }

        this.firstFormGroup.patchValue({
          projectDataArray: arrayProject
        });

        //console.log('firstFormGroup projectDataArray value => ', this.firstFormGroup.value.projectDataArray.length);

      },
      (error) => {
        alert('Erreur ! : ' + error);
      }
    );

  }


  addSujet() {
    if (this.newProjectSubject.trim().length > 0) {
      let newTheme = this.newProjectSubject;
      this.arraySubject.push({ 'libelle': newTheme });
      this.firstFormGroup.patchValue({
        sujectList: this.arraySubject
      });
      this.newProjectSubject = '';
    }
    // console.log('firstFormGroup value => ' , this.firstFormGroup.value);
  }


  initSubject() {

    this.arraySubject.push({ 'libelle': 'Paye' });
    this.arraySubject.push({ 'libelle': 'Sociale' });
    this.arraySubject.push({ 'libelle': 'Information' });

    this.firstFormGroup.patchValue({
      sujectList: this.arraySubject
    });
    this.newProjectSubject = '';
  }

  deleteSuject(sujectItem) {

    const index = this.arraySubject.indexOf(sujectItem, 0);
    if (index > -1) {
      this.arraySubject.splice(index, 1);
    }

    this.firstFormGroup.patchValue({
      sujectList: this.arraySubject
    });

    // console.log('deleteSuject firstFormGroup value => ' , this.firstFormGroup.value);

  }

  deleteProject(projectItem) {
    let arrayProject = this.firstFormGroup.value.projectDataArray;
    const index = arrayProject.indexOf(projectItem, 0);
    if (index > -1) {
      arrayProject.splice(index, 1);
    }

    this.firstFormGroup.patchValue({
      projectDataArray: arrayProject
    });

  }


  fctAdMember(userInfo) {
    this.addingMember.push(userInfo);
    this.userStatus[userInfo['id']] = 'PENDING';
    this.hasMemberAdding = true;
    this.searchContact = '';
  }

  getAvatarlUrl(avatarLink) {
    return environment.server_url + avatarLink;
  }

  openDialogInfo() {
    // console.log(' dans la fonction openDialogInfo');
    const dialogRef = this.dialog.open(tutoCreateProjectdialog, {
      minWidth: '850px',
      position: { top: '50px' },
      data: {}
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  uploadFile(file) {

    this.authService.addMultipEmploye(file.item(0)).subscribe(
      res => {
        let arrayuser = <any>res;
        for (let i = 0; i < arrayuser.length; i++) {
          this.addProjectUserFormCsv(arrayuser[i]);
        }
        this.createProjectMode = 2;
        this.formCSV = true;
      },
      (error) => {
        alert('Erreur ! : ' + error);
      }
    );

    this.fileInput.nativeElement.value = '';

  }


  activateNextStep() {
    //console.log('Gender', this.gender);
    if (((this.firstFormGroup.value.projectDataArray.length > 0) && (this.lname.trim().length === 0)
     && (this.fname.trim().length === 0) && (this.newProjectProf.trim().length === 0) && (this.newProjectEmail.trim().length === 0))
       || ((this.fname.trim().length > 0) && (this.lname.trim().length > 0) &&
            (this.gender.trim().length > 0) && (this.newProjectProf.trim().length > 0) && (this.newProjectEmail.trim().length > 0))) {
      return true;
    } else {
      return false;
    }
  }



}


@Component({
  selector: 'tutoCreateProjectdialog',
  templateUrl: 'tutoCreateProjectdialog.html',
  styleUrls: ['tutoCreateProjectdialog.scss']
})
export class tutoCreateProjectdialog implements OnInit {
  lang = 'fr';

  constructor(
    public dialogRef: MatDialogRef<tutoCreateProjectdialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private authService: AuthService,
    private messageService: MessageService,
    private _formBuilder: FormBuilder) {

  }

  ngOnInit() {
    this.messageService.getTranslate().subscribe((data) => {
      this.lang = data;
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}

