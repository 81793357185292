import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth-service/auth.service';
import { Router } from '@angular/router';
import { first } from 'rxjs/internal/operators/first';

@Component({
  selector: 'app-inscription-drh',
  templateUrl: './inscription-drh.component.html',
  styleUrls: ['./inscription-drh.component.scss']
})
export class InscriptionDRHComponent implements OnInit {
 
  step = 1;	

  // variable step 1
  RaisonSociale = '';
  Objetsocial = '';
  StatutSocial = '';
  CapitalSocial = '';
  Regime = '';
  AdresseSiegeSocial = '';
  CodePostalSiege = '';
  VilleSiegeSocial = '';

  // variable step 2 
  fname = '';
  lname = '';
  jobT = '';
  email = '';
  passwd = '';
  passwd_confirm = '';
  gender = 'male';
  loading = false;
  error = '';
  message = '';
  pconerror = '';
  values = '';
  hide = true;
  mdpValues = '';


  constructor(
    private authService: AuthService,
    private router: Router) { }

  ngOnInit() {
    if (this.authService.logIn) {
      this.router.navigate(['/']);
    }
  }

  onKey(event: any) { // without type info
    this.mdpValues += event.target.value + ' | ';
  } 

  fctback(){
  	this.step = 1;
  	this.fname = '';
  	this.lname = '';
  	this.jobT = '';
  	this.email = '';
  	this.passwd = '';
  	this.passwd_confirm = '';
  	this.gender = 'male';
  	this.loading = false;
  	this.error = '';
  	this.message = '';
  	this.pconerror = '';
  	this.values = '';
  	this.hide = true;
  	this.mdpValues = '';
  }

  registerNu() {
    this.step = 2;
  }


  registerEntreprise() {
  	
    if (this.passwd.trim() != this.passwd_confirm.trim() ) {
      alert("Votre mot de passe est différent de celui entré dans l'espace de confirmation");
      return;
    }

    this.loading = true;
  
    this.authService.registerCompagny({  
       "title": this.RaisonSociale,
       "hrFullName": this.fname+' '+this.lname,
       "siren": '',
       "phone": '',
       "websiteUrl": '',
       "typeCompany": this.StatutSocial,
       "description": '',
       "activityArea": this.Objetsocial,
       "drh": {
       "email": this.email,
       "givenName": this.lname,
       "familyName": this.fname,
       "jobTitle": this.jobT,
       "gender": this.gender,
       "plainPassword": this.passwd
       }
    })
      .pipe(first())
      .subscribe(
        data => {
          // this.router.navigate(['/']);
          this.message = "Votre entreprise a été créé avec succès. Un email vous a été envoyé. Merci de cliquer dessus pour valider votre compte.";
        },
        err => {
          const error = err.error;
          const errorS = error.message;
          const errorD = error.detail;
          const errorH = error['hydra:description'];
          if (errorS === 'Bad credentials') {
            this.error = 'email / mot de passe incorrect';
          } else if (errorD === "L'utilisateur n'est pas encore activé. pour l'activer, vous devez cliquer sur le lien de confirmation du mail que vous avez reçu") {
            this.error = errorD;
          } else if (errorH) {
            this.error = errorH;
          }
          this.loading = false;
        }
      );
  }

}
