import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth-service/auth.service';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
// import { LoadingScreenComponent } from "../../components/loading-screen/loading-screen.component";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  email = '';
  password = '';

  confirmError = false;

  submitted = false;
  hide = true;
  error = '';
  
  constructor(
    private authService: AuthService,
    private router: Router
    ) { }

  Login() {
    
    this.authService.login( this.email, this.password)
    .pipe( first() )
    .subscribe(
      data => {
        this.router.navigate(['/']);  
      },
      err => {
        const error = err.error;
        const errorS = error.message;
        const errorD = error.detail;
        if (!errorD ) {
          this.error = 'email / mot de passe incorrect';
        } else if (!errorS) {
          this.error = errorD;
          this.confirmError = true;
        }
      
      }
    );
  }


  ngOnInit() {
    if (this.authService.logIn) {
      this.router.navigate(['/']);
    }
  }

}
