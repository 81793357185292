import { Component, ViewEncapsulation, Input, AfterViewInit, ElementRef, OnInit } from "@angular/core";


@Component({
  selector: 'app-read-more',
  templateUrl: './read-more.component.html',
  styleUrls: ['./read-more.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ReadMoreComponent implements OnInit{

  @Input() dataLength: number;
  @Input() text: string;
  formatedText = null;

  public isCollapsed: boolean = true;
  public isCollapsable: boolean = false;
  constructor(private elementRef: ElementRef) {
  }

  ngOnInit(){
    this.formatedText = this.truncate(this.text, this.dataLength);
  }


  truncate(value: string, limit = 25, completeWords = false, ellipsis = '...') {
    if (completeWords) {
      limit = value.substr(0, limit).lastIndexOf(' ');
    }
    let val =  value.length > limit ? value.substr(0, limit) + ellipsis : value;
    return val.replace(/(http?s:\/\/[^\s]+)/g, "<a href='$1' target='__blank'>$1</a>");
  }


}
