import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/services/auth-service/auth.service';
import { CoreServiceService } from 'src/app/services/core/core-service.service';
import { MessageService } from 'src/app/services/message/message.service';
/** @title Responsive sidenav */
@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit{
   
    hideNav=0;
    lang='fr';
    currentUser: any;

    constructor(private translate: TranslateService, 
        private messageService: MessageService,
        public authService: AuthService, 
        private coreService: CoreServiceService) {
    }

    ngOnInit(){
        this.messageService.getTranslate()
        .subscribe(
            (data) => {
                this.lang= data;
            }
        )

        
    }

    useLanguage(language: string) {
        this.translate.use(language);
        this.messageService.setTranslate(language);
    }

    logout() {
        //console.log('logging out');
        this.authService.logout();
    }

    public openSidebar() {
        this.hideNav = this.hideNav == 0 ? 1 : 0;
        this.coreService.toggleSidebar.emit(this.hideNav);
    }

}
