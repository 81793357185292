import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from 'src/app/services/auth-service/auth.service';
import { MessageService } from 'src/app/services/message/message.service';
import { environment } from 'src/environments/environment';
import { FormBuilder, FormGroup, Validators, FormControl, FormGroupDirective, NgForm} from '@angular/forms';
import {ErrorStateMatcher} from '@angular/material/core';
import { DatePipe } from '@angular/common'
import * as introJs from 'intro.js';
import { Subject, Subscription } from 'rxjs';
import { CoreServiceService } from '../services/core/core-service.service';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-subjects-config',
  templateUrl: './subjects-config.component.html',
  styleUrls: ['./subjects-config.component.scss']
})
export class SubjectsConfigComponent implements OnInit, OnDestroy {

  protected _onDestroy = new Subject<void>();

  introJS = introJs();



   currentUser: any;
  isAdmin = false;
  idProjectAll = null;
  projectTagWithNoMessage = [];
  projectAllSalary: any;

  projectTag = [];
  payeTag = [];
  socialeTag = [];
  informationTag = [];
  choTag = [];
  managerTag = [];
  cseTag = [];
  syndicatsTag = [];


  renameSubjectPaie = '';
  renameSubjectSociale = '';
  renameSubjectInfo = '';
  renameSubjectCHO = '';
  renameSubjectManager = '';
  renameSubjectCse = '';
  renameSubjectSyndicat = '';

  newSubjectInfo = '';
  newSubjectPaye = '';
  newSubjectSocial = '';
  newSubjectCHO = '';
  newSubjectManager = '';
  newSubjectCse = '';
  newSubjectSyndicat = '';

  editeSubjectModePaie = false;
  editeSubjectModeInfo = false;
  editeSubjectModeSociale = false;
  editeSubjectModeCHO = false;
  editeSubjectModeManager = false;
  editeSubjectModeCse = false;
  editeSubjectModeSyndicat = false;

  addSubjectModeInfo = false;
  addSubjectModePaye = false;
  addSubjectModeSociale = false;
  addSubjectModeCHO = false;
  addSubjectModeManager = false;
  addSubjectModeCse = false;
  addSubjectModeSyndicat = false;

  tagInfoPaie: any;
  tagInfoSociale: any;
  tagInfoInformation: any;
  tagInfoCHO: any;
  tagInfoManager: any;
  tagInfoCse: any;
  tagInfoSyndicat: any;


  modeDeletePaye = false;
  modeDeleteSociale = false;
  modeDeleteInfo = false;
  modeDeleteCho = false;
  modeDeleteManager = false;
  modeDeleteCse = false;
  modeDeleteSyndicat = false;

  constructor(private authService: AuthService,
     private messageService: MessageService,
      public datepipe: DatePipe,
      private router: Router,
      private coreService: CoreServiceService,
      private formBuilder: FormBuilder) {

  }
  ngOnDestroy(): void {
    this.introJS.exit();
  }


   startDemo() {
    this.introJS.setOptions({
        steps: [
          {
            intro: '<i> <B>Welcome!</B> </i> 👋'
          },
          {
            element: '#step400',
            intro: "Configurez ici vos canaux!",
            position: 'auto'
          },
          {
            element: '#step401',
            intro: "Cliquez ici pour modifier un canal!",
            position: 'auto'
          },
          {
            element: '#step402',
            intro: "Cliquez ici pour ajouter un canal!",
            position: 'auto'
          }
        ],
        showProgress: true,
        hideNext: true,
        hidePrev: true,
        showStepNumbers: true,
        keyboardNavigation: true,
        showButtons:  true,
        scrollToElement: true,
        showBullets:  true
      }
    ).start();
  }




  ngOnInit() {

    this.coreService.startDemo
    .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.startDemo();
      });

    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    
    if(this.currentUser){
      if(this.currentUser.profil == 'HRD' || this.currentUser.profil == 'HR')
      {
        this.isAdmin = true;
      }
    }
    

    this.iniTableSujet();

    /*setTimeout(() => {
     console.log("console", this.informationTag);
    }, 10000);*/

  }


  setetSalaries(list){
    list = list.map(item => {
      item.salary = item.userProject.filter(item => item.profil === 'EMPLOYEE');
      return item;
    })
    return list;
  }


  compareTag(tag){
    for (var i = 0; i < this.projectTagWithNoMessage.length; i++) {
      if(this.projectTagWithNoMessage[i].id == tag.id){
        return true;
      }
    }
    return false;
  }

  iniTableSujet(){

    this.editeSubjectModeSociale = false;
    this.editeSubjectModePaie = false;
    this.editeSubjectModeInfo = false;
    this.editeSubjectModeCHO = false;
    this.editeSubjectModeManager = false
    this.editeSubjectModeCse = false;
    this.editeSubjectModeSyndicat = false;

    this.addSubjectModeInfo = false;
    this.addSubjectModePaye = false;
    this.addSubjectModeSociale = false;
    this.addSubjectModeCHO = false;
    this.addSubjectModeManager = false;
    this.addSubjectModeCse = false;
    this.addSubjectModeSyndicat = false;

    this.modeDeletePaye = false;
    this.modeDeleteSociale = false;
    this.modeDeleteInfo = false;
    this.modeDeleteCse = false;
    this.modeDeleteCho = false;
    this.modeDeleteManager = false;
    this.modeDeleteSyndicat = false;

    //this.payeTag = []; plus utilisé

    this.socialeTag = [];
    this.informationTag = [];
    this.choTag = [];
    this.managerTag = [];
    this.cseTag = [];
    this.syndicatsTag = [];

    this.loadAllTopics();

  }


  loadAllTopics(url?: string){
    url = url ? url : undefined;
    let connected = this.currentUser ? true : false;
    this.authService.getPublicTopics(connected, url).subscribe(res => {
      let tempTopicsArray = res['hydra:member'];
      for (var i = 0; i < tempTopicsArray.length; i++) {
         if (tempTopicsArray[i].code == "EMPLOYEE"){
          this.informationTag.push(tempTopicsArray[i]);
          this.informationTag.sort(this.compare);
         }
         else if (tempTopicsArray[i].code == "DRH") {
          this.socialeTag.push(tempTopicsArray[i]);
          this.socialeTag.sort(this.compare);
         }
         else if (tempTopicsArray[i].code == "CHO") {
          this.choTag.push(tempTopicsArray[i]);
          this.choTag.sort(this.compare);
         }
         else if (tempTopicsArray[i].code == "CSE") {
          this.cseTag.push(tempTopicsArray[i]);
          this.cseTag.sort(this.compare);
         }
        else if (tempTopicsArray[i].code == "MANAGER") {
          this.managerTag.push(tempTopicsArray[i]);
          this.managerTag.sort(this.compare);
        }
        else if (tempTopicsArray[i].code == "SYNDICAT") {
          this.syndicatsTag.push(tempTopicsArray[i]);
          this.syndicatsTag.sort(this.compare);
        }
      }
      if(res['hydra:view']['hydra:next']){
        this.loadAllTopics(res['hydra:view']['hydra:next'].replace("/api", ""));
      }
    });
  }

  compare( a, b ) {
    if ( a.name.trim().toLowerCase() < b.name.trim().toLowerCase() ){
      return -1;
    }
    if ( a.name.trim().toLowerCase() > b.name.trim().toLowerCase() ){
      return 1;
    }
    return 0;
  }

  fctaddSubjectCse(): void {
    this.addSubjectModeCse = true;
    this.modeDeleteCse = false;
    this.newSubjectCse = '';
  }

  addSubjectCancelCse(): void {
    this.addSubjectModeCse = false;
    this.modeDeleteCse = false;
    this.newSubjectCse = '';
  }

  fctDeleteSubjectCse(){
    this.tagInfoCse = null;
    this.editeSubjectModeCse = false;
    this.renameSubjectCse = '';
  }

  deleteThisSubjectCse(tag){
   /*
    this.authService.deleteSousTag(tag.id).subscribe(
      res => {
        const index: number = this.projectTag.indexOf(tag);
        if (index !== -1) {
          this.fctDeleteSubjectInfo();
          this.projectTag.splice(index, 1);
          this.iniTableSujet();
        }
      },
      (error) => {
      }
    ); */
  }

  addSubjectCse(): void {
    /*
    this.authService.ajoutSousTag(this.newSubjectInfo, this.projectAllSalary.id, this.idInfo).subscribe(
      res => {
        this.projectTag.push(res);
        this.projectTag.sort(this.compare);
        this.iniTableSujet();
        this.messageService.needRefreshFiltre();
        this.newSubjectInfo = '';
        this.addSubjectModeInfo = false;
      },
      (error) => {
      }
    );*/

    this.authService.ajoutSousTag(this.newSubjectCse, 'CSE').subscribe(res => {
        this.iniTableSujet();
        this.messageService.needRefreshFiltre();
        this.newSubjectCse = '';
        this.addSubjectModeCse = false;
      },
      (error) => {
      }
    );

  }

  fctaddSubjectSyndicat(): void {
    this.addSubjectModeSyndicat = true;
    this.modeDeleteSyndicat = false;
    this.newSubjectSyndicat = '';
  }

  addSubjectCancelSyndicat(): void {
    this.addSubjectModeSyndicat = false;
    this.modeDeleteSyndicat = false;
    this.newSubjectSyndicat= '';
  }

  fctDeleteSubjectSyndicat(){
    this.tagInfoSyndicat = null;
    this.editeSubjectModeSyndicat = false;
    this.renameSubjectSyndicat = '';
  }

  deleteThisSubjectSyndicat(tag){
   /*
    this.authService.deleteSousTag(tag.id).subscribe(
      res => {
        const index: number = this.projectTag.indexOf(tag);
        if (index !== -1) {
          this.fctDeleteSubjectInfo();
          this.projectTag.splice(index, 1);
          this.iniTableSujet();
        }
      },
      (error) => {
      }
    ); */
  }

  addSubjectSyndicat(): void {
    /*
    this.authService.ajoutSousTag(this.newSubjectInfo, this.projectAllSalary.id, this.idInfo).subscribe(
      res => {
        this.projectTag.push(res);
        this.projectTag.sort(this.compare);
        this.iniTableSujet();
        this.messageService.needRefreshFiltre();
        this.newSubjectInfo = '';
        this.addSubjectModeInfo = false;
      },
      (error) => {
      }
    );*/

    this.authService.ajoutSousTag(this.newSubjectSyndicat, 'SYNDICAT').subscribe(res => {
        this.iniTableSujet();
        this.messageService.needRefreshFiltre();
        this.newSubjectSyndicat = '';
        this.addSubjectModeSyndicat = false;
      },
      (error) => {
      }
    );


  }

  fctaddSubjectCHO(): void {
    this.addSubjectModeCHO = true;
    this.modeDeleteCho = false;
    this.newSubjectCHO = '';
  }

  addSubjectCancelCHO(): void {
    this.addSubjectModeCHO = false;
    this.modeDeleteCho = false;
    this.newSubjectCHO = '';
  }

  fctDeleteSubjectCHO(){
    this.tagInfoCHO = null;
    this.editeSubjectModeCHO = false;
    this.renameSubjectCHO = '';
  }

  deleteThisSubjectCHO(tag){
   /*
    this.authService.deleteSousTag(tag.id).subscribe(
      res => {
        const index: number = this.projectTag.indexOf(tag);
        if (index !== -1) {
          this.fctDeleteSubjectInfo();
          this.projectTag.splice(index, 1);
          this.iniTableSujet();
        }
      },
      (error) => {
      }
    ); */
  }

  addSubjectCHO(): void {
    /*
    this.authService.ajoutSousTag(this.newSubjectInfo, this.projectAllSalary.id, this.idInfo).subscribe(
      res => {
        this.projectTag.push(res);
        this.projectTag.sort(this.compare);
        this.iniTableSujet();
        this.messageService.needRefreshFiltre();
        this.newSubjectInfo = '';
        this.addSubjectModeInfo = false;
      },
      (error) => {
      }
    );*/

    this.authService.ajoutSousTag(this.newSubjectCHO, 'CHO').subscribe(res => {
        this.iniTableSujet();
        this.messageService.needRefreshFiltre();
        this.newSubjectCHO = '';
        this.addSubjectModeCHO = false;
      },
      (error) => {
      }
    );

  }

  fctaddSubjectManager(): void {
    this.addSubjectModeManager = true;
    this.modeDeleteManager = false;
    this.newSubjectManager = '';
  }

  addSubjectCancelManager(): void {
    this.addSubjectModeManager = false;
    this.modeDeleteManager = false;
    this.newSubjectManager = '';
  }

  fctDeleteSubjectManager(){
    this.tagInfoManager = null;
    this.editeSubjectModeManager = false;
    this.renameSubjectManager = '';
  }

  deleteThisSubjectManager(tag){
   /*
    this.authService.deleteSousTag(tag.id).subscribe(
      res => {
        const index: number = this.projectTag.indexOf(tag);
        if (index !== -1) {
          this.fctDeleteSubjectInfo();
          this.projectTag.splice(index, 1);
          this.iniTableSujet();
        }
      },
      (error) => {
      }
    ); */
  }

  addSubjectManager(): void {
    /*
    this.authService.ajoutSousTag(this.newSubjectInfo, this.projectAllSalary.id, this.idInfo).subscribe(
      res => {
        this.projectTag.push(res);
        this.projectTag.sort(this.compare);
        this.iniTableSujet();
        this.messageService.needRefreshFiltre();
        this.newSubjectInfo = '';
        this.addSubjectModeInfo = false;
      },
      (error) => {
      }
    );*/


    this.authService.ajoutSousTag(this.newSubjectManager, 'MANAGER').subscribe(res => {
        this.iniTableSujet();
        this.messageService.needRefreshFiltre();
        this.newSubjectManager = '';
        this.addSubjectModeManager = false;
      },
      (error) => {
      }
    );

  }

  fctaddSubjectInfo(): void {
    this.addSubjectModeInfo = true;
    this.modeDeleteInfo = false;
    this.newSubjectInfo = '';
  }

  addSubjectCancelInfo(): void {
    this.addSubjectModeInfo = false;
    this.modeDeleteInfo = false;
    this.newSubjectInfo = '';
  }

  fctDeleteSubjectInfo(){
    this.tagInfoInformation = null;
    this.editeSubjectModeInfo = false;
    this.renameSubjectInfo = '';
  }

  deleteThisSubjectInfo(tag){
    //console.log('go delete info ==> '+tag);
    /*this.authService.deleteSousTag(tag.id).subscribe(
      res => {
        const index: number = this.projectTag.indexOf(tag);
        if (index !== -1) {
          this.fctDeleteSubjectInfo();
          this.projectTag.splice(index, 1);
          this.iniTableSujet();
        }
      },
      (error) => {
      }
    );*/
  }

  addSubjectInfo(): void {

    this.authService.ajoutSousTag(this.newSubjectInfo, 'EMPLOYEE').subscribe(res => {
        this.iniTableSujet();
        this.messageService.needRefreshFiltre();
        this.newSubjectInfo = '';
        this.addSubjectModeInfo = false;
      },
      (error) => {
      }
    );

   /* this.authService.ajoutSousTag(this.newSubjectInfo, this.projectAllSalary.id, this.idInfo).subscribe(
      res => {
        this.projectTag.push(res);
        this.projectTag.sort(this.compare);
        this.iniTableSujet();
        this.messageService.needRefreshFiltre();
        this.newSubjectInfo = '';
        this.addSubjectModeInfo = false;
      },
      (error) => {
      }
    ); */

  }

  fctaddSubjectPaye(): void {
    this.addSubjectModePaye = true;
    this.modeDeletePaye = false;
    this.newSubjectPaye = '';
  }

  addSubjectCancelPaye(): void {
    this.addSubjectModePaye = false;
    this.modeDeletePaye = false;
    this.newSubjectPaye = '';
  }

  fctDeleteSubjectPaye(){
    this.tagInfoPaie = null;
    this.editeSubjectModePaie = false;
    this.renameSubjectPaie = '';
  }

  deleteThisSubjectPaye(tag){
    //console.log('go delete paye ==> '+tag);
    /* this.authService.deleteSousTag(tag.id).subscribe(
      res => {
        const index: number = this.projectTag.indexOf(tag);
        if (index !== -1) {
          this.fctDeleteSubjectPaye();
          this.projectTag.splice(index, 1);
          this.iniTableSujet();
        }
      },
      (error) => {
      }
    ); */
  }

  addSubjectPaye(): void {

/*    this.authService.ajoutSousTag(this.newSubjectPaye, this.projectAllSalary.id, this.idPaye).subscribe(
      res => {
        this.projectTag.push(res);
        this.projectTag.sort(this.compare);
        this.iniTableSujet();
        this.messageService.needRefreshFiltre();
        this.newSubjectPaye = '';
        this.addSubjectModePaye = false;
      },
      (error) => {
      }
    ); */

  }

  fctaddSubjectSocial(): void {
    this.addSubjectModeSociale = true;
    this.modeDeleteSociale = false;
    this.newSubjectSocial = '';
  }

  addSubjectCancelSocial(): void {
    this.addSubjectModeSociale = false;
    this.modeDeleteSociale = false;
    this.newSubjectSocial = '';
  }

  fctDeleteSubjectSocial(){
    this.tagInfoSociale = null;
    this.editeSubjectModeSociale = false;
    this.renameSubjectSociale = '';
  }

  deleteThisSubjectSociale(tag){
    //console.log('go delete sociale ==> '+tag);
    /*this.authService.deleteSousTag(tag.id).subscribe(
      res => {
        const index: number = this.projectTag.indexOf(tag);
        if (index !== -1) {
          this.fctDeleteSubjectSocial();
          this.projectTag.splice(index, 1);
          this.iniTableSujet();
        }
      },
      (error) => {
      }
    ); */
  }

  addSubjectSociale(): void {

   /* this.authService.ajoutSousTag(this.newSubjectSocial, this.projectAllSalary.id, this.idSocial).subscribe(
      res => {
        this.projectTag.push(res);
        this.projectTag.sort(this.compare);
        this.iniTableSujet();
        this.messageService.needRefreshFiltre();
        this.newSubjectSocial = '';
        this.addSubjectModeSociale = false;
      },
      (error) => {
      }
    ); */

      this.authService.ajoutSousTag(this.newSubjectSocial, 'DRH').subscribe(res => {
        this.iniTableSujet();
        this.messageService.needRefreshFiltre();
        this.newSubjectSocial = '';
        this.addSubjectModeSociale = false;
      },
      (error) => {
      }
    );


  }

  writeNewSujetInfo(newSubProjetName){
    //console.log('newSubProjetName => '+newSubProjetName);
    this.newSubjectInfo = newSubProjetName;
  }

  writeNewSujetPaye(newSubProjetName){
    //console.log('newSubProjetName => '+newSubProjetName);
    this.newSubjectPaye = newSubProjetName;
  }

  writeNewSujetSociale(newSubProjetName){
    //console.log('newSubProjetName => '+newSubProjetName);
    this.newSubjectSocial = newSubProjetName;
  }

  writeNewSujetCHO(newSubProjetName){
    //console.log('newSubProjetName => '+newSubProjetName);
    this.newSubjectCHO = newSubProjetName;
  }

  writeNewSujetManager(newSubProjetName){
    //console.log('newSubProjetName => '+newSubProjetName);
    this.newSubjectManager = newSubProjetName;
  }

  writeNewSujetCse(newSubProjetName){
    //console.log('newSubProjetName => '+newSubProjetName);
    this.newSubjectCse = newSubProjetName;
  }

  writeNewSujetSyndicat(newSubProjetName){
    //console.log('newSubProjetName => '+newSubProjetName);
    this.newSubjectSyndicat = newSubProjetName;
  }

  doTextareaValueChangeInfo(ev) {
    this.renameSubjectInfo = ev.target.value;
  }

  doTextareaValueChangePaie(ev) {
    this.renameSubjectPaie = ev.target.value;
  }

  doTextareaValueChangeSociale(ev) {
    this.renameSubjectSociale = ev.target.value;
  }

  doTextareaValueChangeCHO(ev) {
    this.renameSubjectCHO = ev.target.value;
  }

  doTextareaValueChangeManager(ev) {
    this.renameSubjectManager = ev.target.value;
  }

  doTextareaValueChangeCse(ev) {
    this.renameSubjectCse = ev.target.value;
  }

  doTextareaValueChangeSyndicat(ev) {
    this.renameSubjectSyndicat = ev.target.value;
  }


  fctChangeSubjectPaie(element){
    this.renameSubjectPaie = '';
    this.tagInfoPaie = element;
    this.editeSubjectModePaie = true;
  }

  fctChangeSubjectInfo(element){
    //console.log('dans change subject => '+JSON.stringify(element));
    this.renameSubjectInfo = '';
    this.tagInfoInformation = element;
    this.editeSubjectModeInfo = true;
  }

  fctChangeSubjectSociale(element){
    this.renameSubjectSociale = '';
    this.tagInfoSociale = element;
    this.editeSubjectModeSociale = true;
  }

  fctChangeSubjectCHO(element){
    this.renameSubjectCHO = '';
    this.tagInfoCHO = element;
    this.editeSubjectModeCHO = true;
  }

  fctChangeSubjectManager(element){
    this.renameSubjectManager = '';
    this.tagInfoManager = element;
    this.editeSubjectModeManager = true;
  }

  fctChangeSubjectCse(element){
    this.renameSubjectCse = '';
    this.tagInfoCse = element;
    this.editeSubjectModeCse = true;
  }

  fctChangeSubjectSyndicat(element){
    this.renameSubjectSyndicat = '';
    this.tagInfoSyndicat = element;
    this.editeSubjectModeSyndicat = true;
  }

  validerModif(tag, type){

    if (type === 0) {
      if (this.renameSubjectPaie.trim() != '') {
        this.authService.changeSubjectName(this.renameSubjectPaie, tag.name, tag.id)
          .subscribe(res => {

            this.tagInfoPaie = null;
            this.editeSubjectModePaie = false;
            this.renameSubjectPaie = '';
            //this.envoyerObs.next();
            //this.initDataTableTsujet(this.idProjectAll);
            this.iniTableSujet();
          }, (error) => {
             //console.log(' getTheProject subscribe error '+error.statusText);
        });
      }
      else
      {
        this.tagInfoPaie = null;
        this.editeSubjectModePaie = false;
        this.renameSubjectPaie = '';
      }
   }
   else if (type === 1) {
     if (this.renameSubjectSociale.trim() != '') {
        this.authService.changeSubjectName(this.renameSubjectSociale, tag.name, tag.id)
          .subscribe(res => {
            /*console.log(' retour validerModif => '+res);*/

            this.tagInfoSociale= null;
            this.editeSubjectModeSociale = false;
            this.renameSubjectSociale= '';
            //this.envoyerObs.next();
            //this.initDataTableTsujet(this.idProjectAll);
             this.iniTableSujet();
          }, (error) => {
             //console.log(' getTheProject subscribe error '+error.statusText);
        });
      }
      else
      {
        this.tagInfoSociale = null;
        this.editeSubjectModeSociale = false;
        this.renameSubjectSociale = '';
      }
   }else if (type === 2){
     if (this.renameSubjectInfo.trim() != '') {
        this.authService.changeSubjectName(this.renameSubjectInfo, tag.name, tag.id)
          .subscribe(res => {
            /*console.log(' retour validerModif => '+res);*/

            this.tagInfoInformation= null;
            this.editeSubjectModeInfo = false;
            this.renameSubjectInfo = '';
            //this.envoyerObs.next();
            //this.initDataTableTsujet(this.idProjectAll);
            this.iniTableSujet();
          }, (error) => {
             //console.log(' getTheProject subscribe error '+error.statusText);
        });
      }
      else
      {
        this.tagInfoInformation = null;
        this.editeSubjectModeInfo = false;
        this.renameSubjectInfo = '';
      }
   }else if (type === 3){
      if (this.renameSubjectCHO.trim() != '') {
        this.authService.changeSubjectName(this.renameSubjectCHO, tag.name, tag.id)
          .subscribe(res => {
            this.tagInfoCHO = null;
            this.editeSubjectModeCHO = false;
            this.renameSubjectCHO = '';
            this.iniTableSujet();
          }, (error) => {
             //console.log(' getTheProject subscribe error '+error.statusText);
        });
      }
      else{
        this.tagInfoCHO = null;
        this.editeSubjectModeCHO = false;
        this.renameSubjectCHO = '';
      }
   }else if (type === 4){
      if (this.renameSubjectManager.trim() != '') {
        this.authService.changeSubjectName(this.renameSubjectManager, tag.name, tag.id)
          .subscribe(res => {
            this.tagInfoManager= null;
            this.editeSubjectModeManager = false;
            this.renameSubjectManager = '';
            this.iniTableSujet();
          }, (error) => {
             //console.log(' getTheProject subscribe error '+error.statusText);
        });
      }
      else{
        this.tagInfoManager= null;
        this.editeSubjectModeManager = false;
        this.renameSubjectManager = '';
      }
   }else if (type === 5){
    if (this.renameSubjectCse.trim() != '') {
      this.authService.changeSubjectName(this.renameSubjectCse, tag.name, tag.id)
        .subscribe(res => {
          this.tagInfoCse = null;
          this.editeSubjectModeCse = false;
          this.renameSubjectCse = '';
          this.iniTableSujet();
        }, (error) => {
           //console.log(' getTheProject subscribe error '+error.statusText);
      });
    }
    else{
      this.tagInfoCse = null;
      this.editeSubjectModeCse = false;
      this.renameSubjectCse = '';
    }
   }else if (type === 6){
    if (this.renameSubjectSyndicat.trim() != '') {
      this.authService.changeSubjectName(this.renameSubjectSyndicat, tag.name, tag.id)
        .subscribe(res => {
           this.tagInfoSyndicat = null;
          this.editeSubjectModeSyndicat = false;
          this.renameSubjectSyndicat = '';
          this.iniTableSujet();
        }, (error) => {
           //console.log(' getTheProject subscribe error '+error.statusText);
      });
    }
    else{
      this.tagInfoSyndicat = null;
      this.editeSubjectModeSyndicat = false;
      this.renameSubjectSyndicat = '';
    }
   }
 }


}
